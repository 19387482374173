import { AbstractColumns } from '../../../../../utils/Components/AbstractClass/AbstractClassTypes';

export const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: 'title',
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

import store from '../../../store/store';
import { AbstractClassPageHeaderExtraButtonItem } from '../../Components/AbstractClass/AbstractClassTypes';
import { PopupList } from '../../Popup/PopupTypes';
import intersection from 'lodash/intersection';

export const menuPermissionSetter = (
  menu: Array<any>,
  permissionList: Array<any>
) => {
  if (!permissionList.length) return menu;
  const permArr = permissionList.map((perm) =>
    perm.permissionData.subject.split('.').pop()
  );
  const getFilterElements = (filterItem) => {
    if (!filterItem) return;
    for (let i = 0; i < filterItem.length; i++) {
      const menuPermission = filterItem[i];
      if (permArr.includes(menuPermission)) return true;
    }
  };

  const menuFilter = (menuItem) => {
    if (!menuItem?.permission) return true;
    if (menuItem.children) {
      menuItem.children.forEach((item) => menuFilter(item));
      menuItem.children = menuItem.children.filter((el) =>
        getFilterElements(el.permission)
      );
      if (!menuItem.children.length) return false;
    }

    return getFilterElements(menuItem.permission);
  };

  return menu.filter(menuFilter);
};

export const routerPermissionSetter = (
  routerPermission,
  permissionList,
  profile
) => {
  if (permissionList === null) return true;
  if (routerPermission && permissionList) {
    if (routerPermission.includes('superAdmin')) {
      return profile?.admin;
    }
    if (!routerPermission.length || !permissionList.length) return true;
    const permArr = permissionList.map((perm) =>
      perm.permissionData.subject.split('.').pop()
    );
    return intersection(permArr, routerPermission).length;
  }
};

export const clearNonPermissionTabs = (permissionList, tabs) => {
  if (!permissionList?.length) return tabs;
  const permArr = permissionList?.map((perm) =>
    perm.permissionData.subject.split('.').pop()
  );
  if (permArr) {
    return tabs.filter((el) => {
      if (!el.permission?.length) return true;
      return intersection(permArr, el.permission).length;
    });
  }
};

export const isPermissionAction = (typeName: string) => {
  const permissionList = store.getState().authReducer.permission ?? [];

  if (permissionList?.length === 0) return true;

  const currentPermissions: string[] =
    store.getState().workplaceTabsReducer.currentTab?.permission ?? [];
  const currentPermName = currentPermissions.find(
    (perm: string) => perm.slice(0, typeName.length) === typeName
  );
  if (!currentPermName) return true;
  return !!permissionList.find(
    (perm) => perm.permissionData.subject === currentPermName
  );
};

export const getAbstractPermissions = (
  buttons: AbstractClassPageHeaderExtraButtonItem[]
) => {
  if (!buttons) return [];
  const permissionList = store.getState().authReducer.permission;
  const currentPermissions: string[] =
    store.getState().workplaceTabsReducer.currentTab?.permission ?? [];
  if (!permissionList?.length || !currentPermissions.length) return buttons;
  return buttons?.filter((button) => {
    if (button.onClick.func === 'setIsAdd') {
      return isPermissionAction('create');
    } else return true;
  });
};

// declare type PopupList = ReturnType<typeof getPopupList>;

export const getPermissionPopup = (popup: PopupList) => {
  return popup.filter((pop) => {
    if (pop.type === 'edit' || pop.type === 'massEdit') {
      return isPermissionAction('update');
    } else if (pop.type === 'checkout') {
      return isPermissionAction('get');
    } else if (pop.type === 'delete' || pop.type === 'massDelete') {
      return isPermissionAction('delete');
    } else return true;
  });
};

import { ResponseData } from '../../utils/API/APITypes';
import { WorkingAmount } from '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingAmount/types';

const SET_DATA_WORKING_AMOUNT = 'SET_DATA_WORKING_AMOUNT';
const SET_CONTENT_WORKING_AMOUNT = 'SET_CONTENT_WORKING_AMOUNT';
const SET_STATISTIC_WORKING_AMOUNT = 'SET_STATISTIC_WORKING_AMOUNT';

export declare type DataWorkingAmount = {
  success: boolean;
  DataList: ResponseData<WorkingAmount>;
};

export declare type WorkingAmountReducer = {
  data: DataWorkingAmount | null;
  content: Array<WorkingAmount> | null;
  statistic: any;
};

/**
 * @param {DataWorkingAmount} data
 * @return {any}
 */
export function setDataWorkingAmount(data) {
  return {
    type: SET_DATA_WORKING_AMOUNT,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkingAmount>} data
 * @return {any}
 */
export function setContentWorkingAmount(data) {
  return {
    type: SET_CONTENT_WORKING_AMOUNT,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkingAmount>} data
 * @return {any}
 */
export function setStatisticWorkingAmount(data) {
  return {
    type: SET_STATISTIC_WORKING_AMOUNT,
    payload: data,
  };
}

const initialState: WorkingAmountReducer = {
  data: null,
  content: null,
  statistic: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workingAmountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORKING_AMOUNT:
      return { ...state, data: action.payload, statistic: null };
    case SET_CONTENT_WORKING_AMOUNT:
      return { ...state, content: action.payload, statistic: null };
    case SET_STATISTIC_WORKING_AMOUNT:
      return { ...state, statistic: action.payload };
    default:
      return state;
  }
}

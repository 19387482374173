import React from 'react';
import { ExplorerProps, ExplorerState } from './ExplorerType';
import ExplorerView from './ExplorerView';
import { Drawer, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { getPath } from './helpers/getPath';
import API from '../../API/API';
import PDFTron from '../../PDFViewer/PDFTron';
import { KeycloakApp } from '../../Keycloak/Keycloak';
import API_FORM from '../../API/ApiForm';

/**
 * Инициализация класса Explorer
 * @class
 * @extends React.Component
 */
class Explorer extends React.Component<ExplorerProps, ExplorerState> {
  /**
   * @param {ExplorerProps} props
   */
  constructor(props: ExplorerProps) {
    super(props);
    this.state = {
      documentData: { name: props.rootName },
      data: null,
      filesInFolder: [],
      isFetchingData: false,
      directoryPath: props.initDirectoryPath ?? null,
      isPDFView: false,
      filePath: '',
      scrollHistory: {},
    };
  }

  /**
   * Получение данных
   */
  componentDidMount() {
    this.getData();
  }

  /**
   * Обновление данных после формирования
   * @param {ExplorerProps} prevProps
   */
  componentDidUpdate(prevProps: ExplorerProps, prevState: ExplorerState) {
    const { isFormed } = this.props;
    if (prevProps.isFormed !== isFormed && isFormed) {
      this.getData();
    }
    const directoryPath = this.state.directoryPath;
    if (prevState.data !== this.state.data) {
      window.scrollTo(0, this.state.scrollHistory[directoryPath!] ?? 0);
    }
  }

  getData = async () => {
    const { directoryPath } = this.state;
    const { idName, dataListName } = this.props;
    this.setState({ isFetchingData: true });
    API.get(this.props.api, {
      params: {
        [idName]: this.props.documentId,
        directoryPath: directoryPath
          ? directoryPath.replaceAll('null', '').replaceAll('?', '')
          : null,
      },
    })
      .then((response) => {
        const directoryName =
          dataListName ?? `${idName.replace('Id', '')}DirectoryListingData`;
        const rootName = idName ? response.data[directoryName] : response.data;
        const testPackDirectoryMetadata =
          rootName.directoryListingData.directoryMetadata;
        const filesInFolder = [
          ...testPackDirectoryMetadata.childDirectories,
          ...testPackDirectoryMetadata.files,
        ];
        testPackDirectoryMetadata.currentDirectory.path = `${directoryPath}/${testPackDirectoryMetadata.currentDirectory.name}`;
        this.setState({
          data: testPackDirectoryMetadata,
          filesInFolder: filesInFolder,
          isFetchingData: false,
        });
      })
      .catch(() => {
        this.setState({ isFetchingData: false });
      });
  };
  /**
   * Изменить текущую папку
   * @param {any} value
   */
  changeCurrentFolder = (value: any) => {
    if (typeof value !== 'string' && value !== null) {
      value = `${this.state.directoryPath}/${value.name}`;
    }

    this.setState(
      (prev) => ({
        directoryPath: value,
        scrollHistory: {
          ...prev.scrollHistory,
          [prev.directoryPath!]: window.scrollY,
        },
      }),
      () => this.getData()
    );
  };
  /**
   * Открытие pdf
   * @param {string} newDirectoryPath
   * @param {boolean} isOpen
   */
  pdfViewOpener = (newDirectoryPath, isOpen) => {
    if (isOpen) {
      this.setState({ isPDFView: true, filePath: newDirectoryPath });
    } else {
      this.setState({ isPDFView: false, filePath: null });
    }
  };
  /**
   * Получение данных
   * @param {any} record
   * @return {any}
   */
  openFile = (record) => {
    const { directoryPath } = this.state;
    let newDirectoryPath;
    if (directoryPath !== 'null' && directoryPath !== '?') {
      newDirectoryPath = `${directoryPath}/${record.path}`;
      // @ts-ignore
      newDirectoryPath = newDirectoryPath.replaceAll('null/', '');
    } else {
      newDirectoryPath = '/' + record.path;
    }
    switch (record.contentType) {
      case '-':
        return this.changeCurrentFolder(record);
      case 'application/pdf':
        return this.pdfViewOpener(newDirectoryPath, true);
      case 'application/x-zip-compressed':
        return this.downloadFile(record.name);
      default:
        return this.downloadFile(record.name);
    }
  };

  /**
   * Переформирование файла
   * @param {any} record
   */
  reformDoc = (record) => {
    const { directoryPath } = this.state;
    const { reformApi, idName, documentId, t } = this.props;
    this.setState({ isFetchingData: true });

    API_FORM.post(
      reformApi!,
      {
        [idName]: documentId,
        fullPath:
          directoryPath?.replaceAll('null/', '').replaceAll('+', '%2B') ?? '',
        fileName: record.name.replaceAll('+', '%2B'),
      },
      { headers: { Authorization: `Bearer ${KeycloakApp.token}` } }
    )
      .then((res) => {
        if (res.data.httpStatus < 200) {
          notification.error({ message: res.data.message });
        } else notification.success({ message: res.data.message });
      })
      .catch(() => notification.error({ message: t('Error') }))
      .finally(() => this.setState({ isFetchingData: false }));
  };

  /**
   * Скачивание файла
   * @param {string} name
   */
  downloadFile = (name) => {
    const { apiDownload } = this.props;
    const { directoryPath } = this.state;
    const path = getPath(name, directoryPath);
    const url = `${apiDownload}=${this.props.documentId ?? ''}&filePath=${path
      .replaceAll('undefined', '')
      .replaceAll('+', '%2B')}`;
    API.get(url, { responseType: 'arraybuffer' }).then((response) => {
      this.saveData(response.data, name);
    });
  };
  /**
   * Удаление файла или директории
   * @param {string} name
   * @param {string} type
   */
  deletePath = (name, type) => {
    const { idName, documentId, api } = this.props;
    const { directoryPath } = this.state;
    const apiPath = api.replace('/directory', '');
    const path = getPath(name, directoryPath);
    const typeDelete = type === 'directory' ? 'directory' : 'file';
    const url = `${apiPath}/${typeDelete}?${idName}=${documentId}&${typeDelete}Path=${path}`;
    API.delete(url).then(() => this.getData());
  };

  /**
   * Загрузка файла
   * @param {string} uploadRequestName
   * @return {{body: any, url: string}}
   */
  uploadFile = (uploadRequestName) => {
    const { idName, documentId, api } = this.props;
    const { directoryPath } = this.state;
    const apiPath = api.replace('/directory', '');
    const url = `${apiPath}/file`;
    const body = {
      [uploadRequestName]: {
        [idName]: documentId,
        directoryPath: directoryPath ? directoryPath?.replace('null/', '') : '',
      },
    };
    return { body, url };
  };

  /**
   * Изменить текущую папку
   * @param {any} buffer
   * @param {string} fileName
   */
  saveData = (buffer, fileName) => {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a['style'] = 'display: none';
    const blob = new Blob([buffer], {
      type: 'application/x-zip-compressed',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  handlerCreateFolder = () => {
    this.setState((prevState) => {
      return {
        filesInFolder: [
          {
            name: 'Папка',
            size: '',
            path: 'Папка',
            changeTime: '',
            contentType: 'new folder',
          },
          ...prevState.filesInFolder,
        ],
      };
    });
  };

  onFinishCreateFolder = (folderName: string) => {
    const { api, idName, documentId } = this.props;
    const { directoryPath } = this.state;
    const newDirectoryPath = directoryPath
      ? `${directoryPath?.replace('null', '')}/`
      : '';
    API.post(api, {
      newPath: `${newDirectoryPath}${folderName.trim()}`,
      [idName]: documentId,
    }).then(() => {
      this.getData();
    });
  };

  /**
   * Изменить путь файла или папки
   * @param value
   * @param type
   */
  changeDirectory = (
    value: { oldPath: string; newPath: string },
    type: string
  ) => {
    const { api, idName, documentId } = this.props;
    const typeApi =
      type === 'directory' ? api : api.replace('directory', 'file');
    API.put(
      typeApi,
      { ...value, [idName]: documentId },
      { notNotification: true }
    ).then(() => this.getData());
  };

  rename = (renamePath: string) => {
    this.setState((prevState) => {
      const copyFilesInFolder = Array.from(prevState.filesInFolder);
      const editFile = copyFilesInFolder.find(
        (file) => file.path === renamePath
      );
      editFile.isEdit = true;

      return { filesInFolder: copyFilesInFolder };
    });
  };

  /**
   * Вызов стандартного метода для отрисовки компонента Explorer
   * @return {JSX}
   */
  render() {
    const {
      filesInFolder,
      documentData,
      directoryPath,
      isPDFView,
      filePath,
      isFetchingData,
    } = this.state;
    const { documentId, t, apiDownload, uploadName, reformApi } = this.props;
    const path =
      localStorage['project'] === 'agcc'
        ? process.env.REACT_APP_PRODUCTION_URL_AGCC
        : process.env.REACT_APP_PRODUCTION_URL;
    // eslint-disable-next-line max-len
    const api = `${path}/${apiDownload}=${documentId}&filePath=${filePath}`;
    return (
      <>
        <ExplorerView
          changeDirectory={this.changeDirectory}
          filesInFolder={filesInFolder}
          openFile={this.openFile}
          documentData={documentData}
          directoryPath={directoryPath}
          changeCurrentFolder={this.changeCurrentFolder}
          isPDFView={isPDFView}
          downloadFile={this.downloadFile}
          isFetchingData={isFetchingData}
          uploadName={uploadName}
          deletePath={this.deletePath}
          uploadFile={this.uploadFile}
          getData={this.getData}
          onFinishCreateFolder={this.onFinishCreateFolder}
          handlerCreateFolder={this.handlerCreateFolder}
          reform={!!reformApi}
          rename={this.rename}
          reformDoc={this.reformDoc}
        />
        {isPDFView && (
          <Drawer
            open={isPDFView}
            width={'100vw'}
            title={t('View file')}
            onClose={() => this.setState({ isPDFView: false })}
          >
            <PDFTron fullName={'Изометрия'} api={api} />
          </Drawer>
        )}
      </>
    );
  }
}

export default withTranslation()(Explorer);

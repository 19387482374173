import isEqual from 'lodash/isEqual';

export const checkAndSetValue = (
  oldValue: object,
  newValue: object,
  skipValue?: string[]
) => {
  const checkValues = {};
  // eslint-disable-next-line guard-for-in
  for (const key in newValue) {
    const value = newValue[key];
    if (skipValue?.includes(key)) {
      checkValues[key] = value;
      continue;
    }
    if (Array.isArray(value) && oldValue) {
      if (!isEqual(value, oldValue[key])) {
        checkValues[key] = value;
      }
      continue;
    }
    if (oldValue?.[key] !== value && value !== null) {
      checkValues[key] = value;
    }
  }
  return checkValues;
};

import React from 'react';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FileZipOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  FolderTwoTone,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Space, Spin, Table, Tooltip } from 'antd';
import { t } from 'i18next';
import { ExplorerViewProps, ExplorerViewState } from './ExplorerType';
import { ColumnsType } from 'antd/es/table/interface';
import ExcelUploadAbstractClass from '../ExcelUpload/ExcelUploadAbstractClass';
import Popup from '../../Popup/Popup';
import { renderDate } from '../../Base/helpers/renderDate';
import FormDirectory from './components/FormDirectory';

/**
 * Инициализация класса ExplorerView
 * @class
 * @extends React.Component
 */
export default class ExplorerView extends React.Component<
  ExplorerViewProps,
  ExplorerViewState
> {
  /**
   * @param {any} props
   */
  constructor(props: ExplorerViewProps) {
    super(props);
    this.state = {
      popup: { visible: false, pageX: 0, pageY: 0, list: [] },
      visibleUploader: false,
    };
  }

  /**
   * Вызов стандартного метода для отрисовки компонента ExplorerView
   * @return {JSX}
   */
  render() {
    const {
      filesInFolder,
      openFile,
      downloadFile,
      changeCurrentFolder,
      isFetchingData,
      deletePath,
      uploadFile,
      reformDoc,
      getData,
      uploadName,
      reform,
      directoryPath,
      documentData,
      handlerCreateFolder,
      onFinishCreateFolder,
      rename,
      changeDirectory,
    } = this.props;
    const { popup, visibleUploader } = this.state;

    const columns: ColumnsType<any> = [
      {
        title: 'Тип',
        dataIndex: 'contentType',
        key: 'title',
        align: 'center',
        width: 100,
        render: (item) => {
          return item === 'application/pdf' ? (
            <FilePdfOutlined style={{ fontSize: '20px' }} />
          ) : item === 'application/x-zip-compressed' ||
            item === 'application/zip' ? (
            <FileZipOutlined style={{ fontSize: '20px' }} />
          ) : item === '-' || item === 'new folder' ? (
            <FolderTwoTone style={{ fontSize: '20px' }} />
          ) : (
            <PaperClipOutlined style={{ fontSize: '20px' }} />
          );
        },
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        render: (item, record) =>
          record.contentType === 'new folder' || record.isEdit ? (
            <FormDirectory
              record={record}
              directoryPath={directoryPath}
              changeDirectory={changeDirectory}
              onFinishCreateFolder={onFinishCreateFolder}
            />
          ) : (
            item
          ),
      },
      {
        title: 'Последнее изменение',
        dataIndex: 'changeTime',
        key: 'changeTime',
        align: 'center',
        render: renderDate,
      },
      {
        title: 'Размер',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
      },
    ];

    const pathArray = directoryPath
      ?.replaceAll('null', documentData.name)
      .split('/') ?? [documentData.name];

    const breadCrumbs = pathArray.map((el, index, array) => ({
      title: el,
      path:
        index + 1 < array.length ? array.slice(0, index).join('/') : undefined,
      onClick: (e) => {
        e.preventDefault();
        if (index !== array.length - 1) {
          changeCurrentFolder(
            index > 0 ? ['null', ...array.slice(1, index + 1)].join('/') : null
          );
        }
      },
    }));

    const { body, url } = uploadFile(uploadName);
    const popupList = (record) => {
      if (record.contentType === '-') {
        return [
          {
            title: t('Open'),
            icon: <FolderOpenOutlined />,
            type: 'open',
            onClick: () => changeCurrentFolder(record),
          },
          {
            title: t('Rename'),
            icon: <EditOutlined />,
            type: 'rename',
            onClick: () => rename(record.path),
          },
          {
            title: t('Delete'),
            icon: <DeleteOutlined />,
            type: 'delete',
            onClick: () => deletePath(record.name, 'directory'),
          },
        ];
      } else if (record.contentType === 'application/pdf') {
        const popupItems = [
          {
            title: t('View'),
            icon: <FileTextOutlined />,
            type: 'open',
            onClick: () => openFile(record),
          },
          {
            title: t('Download'),
            icon: <DownloadOutlined />,
            type: 'download',
            onClick: () => downloadFile(record.name),
          },
          {
            title: t('Delete'),
            icon: <DeleteOutlined />,
            type: 'delete',
            onClick: () => deletePath(record.name),
          },
        ];
        if (reform && !record.name.includes('Реестр')) {
          popupItems.push({
            title: t('Reform'),
            icon: <FileSyncOutlined />,
            type: 'download',
            onClick: () => reformDoc(record),
          });
        }
        return popupItems;
      } else {
        return [
          {
            title: t('Download'),
            icon: <DownloadOutlined />,
            type: 'download',
            onClick: () => downloadFile(record.name),
          },
          {
            title: t('Delete'),
            icon: <DeleteOutlined />,
            type: 'delete',
            onClick: () => deletePath(record.name, 'file'),
          },
        ];
      }
    };

    return (
      <div>
        <Spin spinning={isFetchingData}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <Breadcrumb items={breadCrumbs} />
            <Space>
              {uploadName && (
                <Tooltip title={t('Upload') as string}>
                  <Button
                    icon={<UploadOutlined />}
                    name={'upload'}
                    onClick={() => this.setState({ visibleUploader: true })}
                  />
                </Tooltip>
              )}
              <Tooltip title={t('Create folder') as string}>
                <Button
                  icon={<FolderAddOutlined />}
                  name={'createFolder'}
                  disabled={filesInFolder[0]?.contentType === 'new folder'}
                  onClick={handlerCreateFolder}
                />
              </Tooltip>
            </Space>
          </div>
          <Table
            columns={columns}
            dataSource={filesInFolder}
            pagination={false}
            onRow={(record) => {
              return {
                onDoubleClick: () => openFile(record),
                onContextMenu: (event) => {
                  event.preventDefault();
                  if (!popup.visible) {
                    document.addEventListener(`click`, () =>
                      this.setState({
                        popup: Object.assign(popup, { visible: false }),
                      })
                    );
                  }
                  this.setState({
                    popup: {
                      visible: true,
                      pageX: event.clientX,
                      pageY: event.clientY,
                      list: popupList(record),
                    },
                  });
                },
              };
            }}
          />
          {visibleUploader && (
            <ExcelUploadAbstractClass
              isVisible={visibleUploader}
              setIsVisible={(e) => this.setState({ visibleUploader: e })}
              uri={url}
              fileName="file"
              extraFormData={body}
              customRequest={getData}
            />
          )}
        </Spin>
        <Popup {...popup} />
      </div>
    );
  }
}

import { io, Socket } from 'socket.io-client';
import store from '../../store/store';

const project = localStorage.getItem('project');

const url =
  project === 'agcc'
    ? `${process.env.REACT_APP_SOCKET_SERVER_URL_AGCC}`
    : `${process.env.REACT_APP_SOCKET_SERVER_URL}`;

export let socket: Socket;

export const initSocket = () => {
  socket = io(url, {
    query: {
      token: store.getState().authReducer.profile?.personFullName,
    },
  });
};

import { FileExcelOutlined } from '@ant-design/icons';
import React from 'react';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { SearchFieldsType } from '../../../../utils/SearchPanel/types/SearchPanelTypes';

export const getInitialColumnsInspectors = (t): AbstractColumns => [
  {
    title: t('Name'),
    key: 'name',
    dataIndex: 'name',
    align: 'center',
    visible: true,
    render: (item, record) => {
      let name = `${record.surname} ${item}`;
      if (record.patronymic) name += ' ' + record.patronymic;
      return name;
    },
  },
];

export const getSearchFieldsInspectors = (t): SearchFieldsType[] => [
  {
    component: 'Input',
    label: t('Surname'),
    name: 'surname',
    placeholder: '',
    required: false,
    span: 8,
  },
  {
    component: 'Input',
    label: t('Name'),
    name: 'name',
    placeholder: '',
    required: false,
    span: 8,
  },
  {
    component: 'Input',
    label: t('Patronymic'),
    name: 'patronymic',
    placeholder: '',
    required: false,
    span: 8,
  },
];

export const getPageHeaderExtraQc = (
  t
): AbstractClassPageHeaderExtraButtonItem[] => [
  {
    type: 'primary',
    icon: <FileExcelOutlined />,
    loading: true,
    title: t('Download'),
    onClick: {
      type: 'innerFunction',
      func: 'downloadExcelFile',
      extra: 'inspector-qc-service',
    },
  },
];

import React, { useState } from 'react';
import { Button, Form, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { TFunction, withTranslation } from 'react-i18next';
import API from '../../../../../utils/API/API';
import { useAppSelector } from '../../../../../store/rootReducer';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { getFileImage } from '../../../../../utils/Base/helpers/getFileImage';
import {
  updateAvatar,
  updateBackground,
  updateState,
} from '../../../../../store/reducers/AuthReducer';
import { useDispatch } from 'react-redux';
import { KeycloakApp } from '../../../../../utils/Keycloak/Keycloak';
import cloneDeep from 'lodash/cloneDeep';
import { TIMEOUT_TOKEN } from '../../../../../utils/Base/constants/TIMEOUT_TOKEN';

const EditDesign = React.memo(
  ({ t, onCancel }: { t: TFunction; onCancel: Function }) => {
    const { profile } = useAppSelector((state) => state.authReducer);
    const { accountProfileData } = profile!;
    const [userAvatarId, setAvatarId] = useState<number | null>(
      accountProfileData?.userAvatarId ?? null
    );
    const [userBackgroundId, setBackgroundId] = useState<number | null>(
      accountProfileData?.userBackgroundId ?? null
    );
    const { backgroundUrl, avatarUrl } = useAppSelector(
      (state) => state.authReducer
    );
    const dispatch = useDispatch();
    const baseUrl =
      localStorage.getItem('project') === 'agcc'
        ? process.env.REACT_APP_PRODUCTION_URL_AGCC
        : process.env.REACT_APP_PRODUCTION_URL;
    const upload = `${baseUrl}/api/file/upload`;
    const sendFile = (value: UploadChangeParam, setState) => {
      if (value.file.status === 'done') {
        setState(value.file.response.fileData.fileId);
      }
      if (value.file.status === 'removed') {
        setState(null);
      }
    };

    const onFinish = () => {
      const data = {
        accountProfileId: accountProfileData.accountProfileId,
        userAvatarId: userAvatarId,
        userBackgroundId: userBackgroundId,
      };
      API.put('api/account/account-profile', data).then((res) => {
        checkNewImage(userBackgroundId, 'userBackgroundId', updateBackground);
        checkNewImage(userAvatarId, 'userAvatarId', updateAvatar);
        const rawProfile = cloneDeep(profile);
        if (rawProfile.accountProfileData) {
          rawProfile.accountProfileData.userBackgroundId = userBackgroundId;
          rawProfile.accountProfileData.userAvatarId = userAvatarId;
        }
        dispatch(updateState(rawProfile));
        onCancel();
      });
    };

    const checkNewImage = (value, propName, update) => {
      if (value !== accountProfileData[propName]) {
        value ? getFileImage(value, update) : dispatch(update(null));
      }
    };

    const uploadOptions = {
      headers: {
        Authorization: `Bearer ${KeycloakApp.token}`,
      },
      accept: 'image/png, image/jpeg',
      action: upload,
      maxCount: 1,
      beforeUpload,
    };

    /**
     * @param {RcFile} file
     * @return {any}
     */
    async function beforeUpload(file: RcFile): Promise<any> {
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (KeycloakApp?.isTokenExpired(TIMEOUT_TOKEN)) {
        await KeycloakApp.updateToken(TIMEOUT_TOKEN).then(() => {
          uploadOptions.headers.Authorization = `Bearer ${KeycloakApp.token}`;
        });
      }

      if (!isLt2M) {
        notification['error']({
          message: `${t('Image must smaller than')} 1MB!`,
        });
      }
      return isLt2M || Upload.LIST_IGNORE;
    }

    return (
      <Form onFinish={onFinish} layout={'vertical'}>
        <Form.Item name="1" label={t('Avatar')}>
          <Upload
            {...uploadOptions}
            defaultFileList={
              avatarUrl
                ? [{ uid: '1', name: 'avatar', status: 'done', url: avatarUrl }]
                : undefined
            }
            onChange={(file) => sendFile(file, setAvatarId)}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="2" label={t('Background')}>
          <Upload
            {...uploadOptions}
            defaultFileList={
              backgroundUrl
                ? [
                    {
                      uid: '1',
                      name: 'background',
                      status: 'done',
                      url: backgroundUrl,
                    },
                  ]
                : undefined
            }
            onChange={(file) => sendFile(file, setBackgroundId)}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item key="button">
          <Button type="primary" htmlType="submit">
            {t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
EditDesign.displayName = 'EditDesign';
export default withTranslation()(EditDesign);

import React from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { NewFormProps } from '../../../../../../AppTypes';
import { ConstructionZone } from '../type';

const ConstructionZonesForm = React.memo(
  ({
    type,
    isFetching,
    onFinishAdd,
    onFinishEdit,
    currentRow,
  }: NewFormProps<ConstructionZone>) => {
    const { t } = useTranslation();
    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!([values])
        : onFinishEdit!([{ ...values, id: currentRow?.id }]);
    };
    return (
      <Form layout={'vertical'} initialValues={currentRow} onFinish={onFinish}>
        <Form.Item
          name={'title'}
          label={t('Name_1')}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'number'}
          label={t('Number')}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
ConstructionZonesForm.displayName = 'ConstructionZonesForm';
export default ConstructionZonesForm;

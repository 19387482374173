import React, { useEffect, useState } from 'react';
import { ElementProps } from './AbstractElementTypes';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Result, Skeleton, Spin } from 'antd';
import AbstractElementViewer from './Components/AbstractElementViewer';
import {
  useGetAbstractDataQuery,
  useUpdateAbstractDataMutation,
} from '../../../store/api/abstract.api';
import { setCurrentTabName } from '../../../store/reducers/WorkplaceTabs';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useChangeTab } from '../../../components/Layouts/components/helpers/useChangeTab';
import { useRouterProps } from '../AbstractPage/helpers/useRouterProps';

const AbstractElementF = ({
  apiMethod,
  dataListName,
  pageHeaderList,
  avatar,
  editMode,
  editModalWidth,
  editComponent: EditComponent,
  pageExtra,
  getRecord,
  destroyInactiveTabPane,
  descriptions,
  tabs,
  alert,
}: ElementProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const path = apiMethod.replace('api/', '');
  const {
    isLoading: loading,
    data,
    isFetching,
    error,
  } = useGetAbstractDataQuery(`${path}${id ? '/' + id : ''}`);
  const [update, { isLoading: editLoading }] = useUpdateAbstractDataMutation();
  const content = get(data, dataListName);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const changeTab = useChangeTab();
  const routerProps = useRouterProps();

  useEffect(() => {
    if (content) {
      const tabName = Array.isArray(pageHeaderList.pass)
        ? pageHeaderList.pass.reduce(
            (prev, curr) => prev + ' ' + get(content, curr),
            ''
          )
        : typeof pageHeaderList.pass === 'function'
        ? pageHeaderList.pass(content)
        : get(content, pageHeaderList.pass);
      dispatch(setCurrentTabName([location.pathname, tabName]));
    }
  }, [content]);

  const onFinishEdit = async (value: any) => {
    return update({ path, body: value, id: id! }).then((res) => {
      if (!('error' in res)) {
        setIsEdit(false);
      }
      return res;
    });
  };

  const editComponent = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        currentRow: content,
        onFinishEdit: onFinishEdit,
        onFinish: (values: Record<string, any>) =>
          onFinishEdit({ ...values, id }),
        routerProps: routerProps,
        isFetching: editLoading,
      });
    }
  };

  return (
    <Skeleton loading={loading}>
      <Spin spinning={isFetching}>
        {error ? (
          <Result
            status="404"
            title="404"
            subTitle={t!('Sorry, the page you visited does not exist')}
            extra={
              <Button type="primary" onClick={() => changeTab('/')}>
                {t('Back Home')}
              </Button>
            }
          />
        ) : (
          <AbstractElementViewer
            content={content}
            isEdit={isEdit}
            openEdit={(item) => {
              setIsEdit(item);
            }}
            avatar={avatar}
            getData={() => 5}
            router={routerProps}
            editMode={editMode}
            apiMethod={apiMethod}
            editComponent={editComponent(EditComponent)}
            descriptions={descriptions}
            tabs={tabs ?? null}
            pageHeaderList={pageHeaderList}
            editModalWidth={editModalWidth}
            pageExtra={pageExtra}
            getRecord={getRecord}
            alert={alert}
            destroyInactiveTabPane={destroyInactiveTabPane}
          />
        )}
      </Spin>
    </Skeleton>
  );
};
AbstractElementF.displayName = 'AbstractElementF';
export default AbstractElementF;

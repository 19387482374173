import React from 'react';
import {
  FormPWHTOperatorProps,
  FormPWHTOperatorState,
} from '../DictionariesTypes';
import { Button, Form, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { checkAndSetValue } from '../../../../utils/Base/helpers/checkAndSetValue';

/**
 * Добавление инспектора
 */
class FormPWHTOperator extends React.Component<
  FormPWHTOperatorProps,
  FormPWHTOperatorState
> {
  private dataListName: string = 'pwhtOperatorCreationDataList';

  /**
   * @param {FormPWHTOperatorProps} props
   */
  constructor(props) {
    super(props);

    this.state = {
      fullName: null,
      description: null,
      stamp: null,
      oldValue: {},
    };
  }

  /**
   * Установка старых данных
   */
  componentDidMount() {
    if (this.props.type === 'edit') {
      const row = this.props.currentRow;
      this.setState({
        fullName: row['full_name'] ? row['full_name'] : null,
        description: row.description ? row.description : null,
        stamp: row.stamp ? row.stamp : null,
        oldValue: this.state,
      });
    }
  }

  /**
   * @return {JSX}
   */
  render() {
    const { t, type, currentRow, isFetching } = this.props;
    const { fullName, stamp, description, oldValue } = this.state;

    return (
      <Form
        layout="vertical"
        onFinish={
          type === 'add'
            ? (values) =>
                this.props.onFinishAdd!({
                  [this.dataListName]: [values],
                })
            : (values) => {
                values['pwhtOperatorId'] = currentRow.pwhtOperatorId;
                this.props.onFinishEdit!(
                  checkAndSetValue(oldValue, values, [
                    'pwhtOperatorId',
                    'stamp',
                    'full_name',
                  ])
                );
              }
        }
        fields={[
          { name: 'full_name', value: fullName },
          { name: 'stamp', value: stamp },
          { name: 'description', value: description },
        ]}
      >
        <Form.Item
          key="full_name"
          label={t('Name')}
          name="full_name"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            onChange={(e) => this.setState({ fullName: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          key="stamp"
          label={t('Stamp')}
          name="stamp"
          rules={[{ required: true, message: '' }]}
        >
          <Input onChange={(e) => this.setState({ stamp: e.target.value })} />
        </Form.Item>
        <Form.Item
          key="description"
          label={t('Description')}
          name="description"
        >
          <Input
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </Form.Item>
        <Form.Item key="button">
          <Button type="primary" htmlType="submit" disabled={isFetching}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(FormPWHTOperator);

import { getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';
import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

export const getInitialColumns = (t, name = 'title'): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: name,
    key: 'name',
    align: 'center',
    visible: true,
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    visible: true,
  },
];

export const factoryNumbersData = {
  apiMethod: 'api/reference-data/factory-number',
  dataListName: 'factoryNumberDataList',
  idName: 'factoryNumberId',
  pageHeaderTitle: 'Factory numbers',
  tabNameField: 'title',
  columnsName: 'FactoryNumberTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const factoryNumbersProps = {
  idName: 'factoryNumberId',
  apiMethod: 'api/reference-data/factory-number',
  dataListName: 'factoryNumberData',
  name: 'title',
};

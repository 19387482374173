import React from 'react';
import { Dictionary, NewFormProps } from '../../../../../../AppTypes';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { ewFolderStatuses } from '../../../../../Admin/Dictionaries/Extra/DictionariesData';
import { useDictionarySelectOptions } from '../../../../../../utils/Base/hooks/useDictionarySelectOptions';
import { statusStatusFolderData } from '../../../../../Admin/Dictionaries/Extra/StatusStatusFolder';

const FoldersStatusForm = React.memo(
  ({
    currentRow,
    type,
    isFetching,
    onFinishAdd,
    onFinishEdit,
  }: NewFormProps<Dictionary & { statusOfFolderStatuses: Dictionary }>) => {
    const { t } = useTranslation();

    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!(values)
        : onFinishEdit!({
            ...values,
            [ewFolderStatuses.idName]: currentRow?.[ewFolderStatuses.idName],
          });
    };

    const statusesOptions = useDictionarySelectOptions(
      statusStatusFolderData
    ).options;

    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{
          ...currentRow,
          statusOfFolderStatusesId: currentRow?.statusOfFolderStatuses.id,
        }}
      >
        <Form.Item
          name={'title'}
          rules={[{ required: true, message: '' }]}
          label={t('Title_1')}
        >
          <Input />
        </Form.Item>
        <Form.Item name={'description'} label={t('Description')}>
          <TextArea />
        </Form.Item>
        <Form.Item
          name={'processTime'}
          rules={[{ required: true, message: '' }]}
          label={t('Status duration')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={'statusOfFolderStatusesId'}
          rules={[{ required: true, message: '' }]}
          label={t('Status')}
        >
          <Select options={statusesOptions} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
FoldersStatusForm.displayName = 'FoldersStatusForm';
export default FoldersStatusForm;

import {
  EditOutlined,
  FileTextOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import React from 'react';
import { PopupList } from './PopupTypes';

export const getPopupList = <T,>(t): PopupList<T> => {
  return [
    {
      title: t('More details'),
      icon: <FileTextOutlined />,
      type: 'checkout',
      onClick: () => {
        console.log(t('More details'));
      },
    },
    {
      title: t('Edit'),
      icon: <EditOutlined />,
      type: 'edit',
      onClick: () => {
        console.log(t('Edit'));
      },
    },
    {
      title: t('Delete'),
      icon: <MinusOutlined />,
      type: 'delete',
      onClick: () => {
        console.log(t('Delete'));
      },
    },
  ];
};

export const getPopupListDeleteId = (t): PopupList => {
  return [
    {
      title: t('More details'),
      icon: <FileTextOutlined />,
      type: 'checkout',
      onClick: () => {
        console.log(t('More details'));
      },
    },
    {
      title: t('Edit'),
      icon: <EditOutlined />,
      type: 'edit',
      onClick: () => {
        console.log(t('Edit'));
      },
    },
    {
      title: t('Delete'),
      icon: <MinusOutlined />,
      type: 'deleteId',
      onClick: () => {
        console.log(t('Delete'));
      },
    },
  ];
};

import React, { startTransition, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getUserData } from './utils/Base/helpers/getUserData';
import { setIsFetchAccount } from './store/reducers/AuthReducer';
import ForbiddenPage from './utils/Components/ForbiddenPage/ForbiddenPage';
import { initSocket } from './utils/Socket/Socket';
import { AxiosError } from 'axios';
import { SpinPage } from './utils/Components/SpinPage/SpinPage';
import { KeycloakApp } from './utils/Keycloak/Keycloak';

const App = () => {
  const [isReady, setIsReady] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const init = async () => {
    const locationProject = pathname.split('/')[1];
    if (locationProject === 'agpp' || locationProject === 'agcc') {
      localStorage.setItem('project', locationProject);
    }
    await verifyUserByExpDate();
    if (localStorage.getItem('project') === 'agpp') {
      initSocketWithDispatchEvents();
    }
  };

  useEffect(() => {
    init();
  }, []);

  const initSocketWithDispatchEvents = () => {
    initSocket();
  };

  const handleErrorOnUserDataFetch = (error: any) => {
    dispatch(setIsFetchAccount(false));
    if (error instanceof AxiosError) {
      if (
        error.response?.data?.message ===
        'Пользователь по запрошенному идентификатору не найден!'
      ) {
        navigate(`/registry`);
      }
      if (error.response?.request.status === 403) {
        setIsForbidden(true);
      }
    }
  };

  const fetchUserData = async () => {
    try {
      if (KeycloakApp.subject) await getUserData(KeycloakApp.subject);
    } catch (error) {
      handleErrorOnUserDataFetch(error);
    }
  };

  const authenticateUser = async (): Promise<boolean> => {
    const authenticated = await KeycloakApp.init({
      onLoad: 'login-required',
      responseMode: 'query',
    });

    if (!localStorage.getItem('project')) {
      navigate(`/enter`);
    } else if (authenticated && KeycloakApp.subject) {
      await fetchUserData();
    }

    return authenticated;
  };

  const verifyUserByExpDate = async () => {
    try {
      const isAuthenticated = await authenticateUser();
      if (isAuthenticated) {
        startTransition(() => {
          setIsReady(true);
        });
      }
    } catch (error: any) {
      console.error('Failed to initialize adapter:', error);
    }
  };

  if (isForbidden) return <ForbiddenPage />;
  if (!isReady) return <SpinPage />;

  return (
    <div className="App">
      <Outlet />
    </div>
  );
};

export default App;

import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_KQ = 'SET_DATA_KQ';
const SET_CONTENT_KQ = 'SET_CONTENT_KQ';

export declare type DataKq = {
  success: boolean;
  DataList: ResponseData<Kq>;
};
export declare type Kq = {
  title: string;
  ewKqId: number;
  description: string;
};

export declare type KqReducer = {
  data: DataKq | null;
  content: Array<Kq> | null;
};

/**
 * @param {DataKq} data
 * @return {any}
 */
export function setDataKq(data) {
  return {
    type: SET_DATA_KQ,
    payload: data,
  };
}

/**
 * @param {Array<DataKq>} data
 * @return {any}
 */
export function setContentKq(data) {
  return {
    type: SET_CONTENT_KQ,
    payload: data,
  };
}

const initialState: KqReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function kqReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_KQ:
      return { ...state, data: action.payload };
    case SET_CONTENT_KQ:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { AutoComplete, Form } from 'antd';
import { SearchFieldsType } from './types/SearchPanelTypes';
import debounce from 'lodash/debounce';
import API from '../API/API';

const AutocompleteSearchPanel = React.memo(
  ({
    settings,
    name,
    label,
  }: {
    settings: SearchFieldsType['autoCompleteSettings'];
    label: string;
    name: string;
  }) => {
    const [options, setOptions] = useState();
    const onSearch = debounce((text) => {
      if (text.trim() && settings) {
        API.get(settings.url, {
          params: { size: 50, [settings.search]: text },
        }).then((res) => {
          const content = res.data[settings.dataListName!].content;
          setOptions(content.map((el) => ({ value: el[settings.name] })));
        });
      }
    }, 300);

    return (
      <Form.Item label={label} name={name}>
        <AutoComplete options={options} onSearch={onSearch} />
      </Form.Item>
    );
  }
);
AutocompleteSearchPanel.displayName = 'AutocompleteSearchPanel';
export default AutocompleteSearchPanel;

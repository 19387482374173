import {
  fetchAccountTabs,
  setRemoveKey,
} from '../../../../store/reducers/WorkplaceTabs';
import { useAppDispatch, useAppSelector } from '../../../../store/rootReducer';
import { useChangeTab } from './useChangeTab';
import { TabType } from '../../LayoutTypes';

export const useTabActions = () => {
  const { tabs, activeKey } = useAppSelector(
    (state) => state.workplaceTabsReducer
  );
  const { profile } = useAppSelector((state) => state.authReducer);
  const accountProfileId = profile?.accountProfileData?.accountProfileId;

  const changeTab = useChangeTab();
  const dispatch = useAppDispatch();

  const dispatchTabs = (rawTabs: TabType[]) => {
    accountProfileId &&
      dispatch(fetchAccountTabs({ tabs: rawTabs, accountProfileId }));
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.code.includes('Digit')) {
      e.preventDefault();
      const number = e.code.replace('Digit', '');
      if (+number <= tabs.length) {
        changeTab(tabs[number - 1].key);
      }
    }
  };

  const remove = (targetKey) => {
    const idx = tabs.findIndex((item) => item.key === targetKey);
    if (tabs[idx].key === activeKey) {
      changeTab(tabs[idx + 1]?.key || tabs[idx - 1].key);
    }
    const rawTabs = tabs.filter((tab) => tab.key !== targetKey);
    dispatch(setRemoveKey(targetKey));
    dispatchTabs(rawTabs);
  };

  const tabChange = (item: string) => {
    const currentTab = tabs.find((tab) => tab.key === item);
    const { params } = currentTab!;
    changeTab(item + (params ? `?tab=${params}` : ''));
  };

  const handleMouseDown = (e, targetKey) => {
    if (e.button === 1 && targetKey !== '/') {
      remove(targetKey);
    }
  };

  const closeAllTabs = () => {
    const notClosableTabs = tabs.filter(
      (tab) => !tab.closable || activeKey === tab.key
    );
    dispatchTabs(notClosableTabs);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const rawTabs = Array.from(tabs);
    const [removed] = rawTabs.splice(result.source.index, 1);
    rawTabs.splice(result.destination.index, 0, removed);
    dispatchTabs(rawTabs);
  };

  return {
    remove,
    tabChange,
    closeAllTabs,
    onDragEnd,
    handleMouseDown,
    handleKeyDown,
  };
};

import { DictionaryData } from '../../DictionariesTypes';
import { getInitialColumns } from './extra';

const idName = 'id';
const api = 'api/group-technical-devices/transportation-equipment';
const name = 'title';

export const transportationEquipmentData: DictionaryData = {
  apiMethod: api,
  dataListName: 'transportationEquipmentDataList',
  idName: idName,
  pageHeaderTitle: 'Equipments for the transportation of dangerous goods',
  tabNameField: name,
  columnsName: 'transportationEquipmentTable',
  columns: getInitialColumns,
  name: name,
  addComponent: false,
  notGotRecord: true,
};

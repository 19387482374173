import React, { useState } from 'react';
import {
  DownOutlined,
  FileExcelOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../../../utils/Components/AbstractClass/AbstractClassTypes';
import { SearchFieldsType } from '../../../../../utils/SearchPanel/types/SearchPanelTypes';
import { ewRfiStatusData } from '../../../../Admin/Dictionaries/Extra/EwRfiStatusData';
import { Button, Tag } from 'antd';
import { renderDate } from '../../../../../utils/Base/helpers/renderDate';

export const getSearchFields = (t): SearchFieldsType[] => [
  {
    component: 'Input',
    label: t('Title-mark'),
    name: 'titleMarkNumber',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: t('Inspection number'),
    name: 'number',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Select',
    label: t('Inspection status'),
    name: 'ewRfiStatus',
    placeholder: '',
    required: false,
    span: 4,
    dictionaryData: ewRfiStatusData,
    selectItem: [],
  },
  {
    component: 'rangepicker',
    label: t('Inspection date'),
    name: 'date',
    placeholder: '',
    required: false,
    span: 4,
  },
  {
    component: 'Input',
    label: t('Comment'),
    name: 'comment',
    placeholder: '',
    required: false,
    span: 4,
  },
];

const getColor = (id: number) => {
  switch (id) {
    case 1:
      return 'green';
    case 2:
      return 'blue';
    case 3:
      return 'red';
    case 4:
      return 'orange';
  }
};

export const rfiStatusRender = (rfiStatusId: number, title: string) => {
  return <Tag color={getColor(rfiStatusId)}>{title}</Tag>;
};

export const CollapsibleText = ({
  item,
  propName = 'title',
}: {
  item: { title: string }[] | undefined;
  propName?: string;
}) => {
  const MAX_ROWS = 2;
  const [collapsed, setCollapsed] = useState(false);

  const items = collapsed ? item : item?.slice(0, MAX_ROWS);

  return items ? (
    <>
      {items.map((el, index) => (
        <div
          style={{
            position: 'relative',
          }}
          key={el[propName]}
        >
          <span>{el[propName]}</span>
          {item!.length > MAX_ROWS && index === items.length - 1 && (
            <Button
              icon={
                collapsed ? (
                  <UpOutlined style={{ fontSize: '0.75em' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '0.75em' }} />
                )
              }
              style={{ position: 'absolute' }}
              size={'small'}
              type={'text'}
              onClick={() => setCollapsed((prevState) => !prevState)}
            />
          )}
        </div>
      ))}
    </>
  ) : (
    <></>
  );
};

export const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Title-mark'),
    key: 'title',
    dataIndex: ['ewTitleMarksDataList'],
    align: 'center',
    visible: true,
    render: (item) =>
      item ? (
        <CollapsibleText item={item.map((el) => ({ title: el.number }))} />
      ) : (
        ''
      ),
    width: 200,
  },
  {
    title: t('Inspection number'),
    key: 'number',
    dataIndex: 'number',
    align: 'center',
    sorter: true,
    visible: true,
    width: 200,
  },
  {
    title: t('Inspection date'),
    key: 'rfiDate',
    dataIndex: 'rfiDate',
    align: 'center',
    visible: true,
    sorter: true,
    render: renderDate,
    width: 200,
  },
  {
    title: t('Inspection status'),
    key: 'status',
    dataIndex: ['ewRfiStatusData'],
    align: 'center',
    visible: true,
    render: (item) =>
      item ? rfiStatusRender(item.rfiStatusId, item.title) : '',
    width: 200,
  },
  {
    title: t('Comment'),
    key: 'ewRfiCommentDataList',
    dataIndex: 'ewRfiCommentDataList',
    align: 'center',
    width: 500,
    visible: true,
    render: (item) => <CollapsibleText item={item} />,
  },
];

export const getPageHeaderExtra = (
  t
): AbstractClassPageHeaderExtraButtonItem[] => [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    title: t('Add'),
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
  {
    type: 'primary',
    icon: <FileExcelOutlined />,
    loading: true,
    title: t('Unload inspections'),
    onClick: {
      type: 'innerFunction',
      func: 'downloadExcelFile',
      extra: 'ew-base-phase-service/rfi',
    },
  },
];

export const getInitialColumnsCommentRfiStatus = (t): AbstractColumns => [
  {
    title: t('Comment'),
    dataIndex: 'title',
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Inspection status'),
    dataIndex: 'ewRfiStatusData',
    key: 'ewRfiStatusData',
    align: 'center',
    visible: true,
    render: (item) =>
      item ? rfiStatusRender(item.rfiStatusId, item.title) : '',
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

import { ResponseData } from '../../utils/API/APITypes';
import { WDBType } from '../../components/Workplace/AGPP/WDB/WDBData';

const SET_DATA_WDB = 'SET_DATA_WDB';
const SET_CONTENT_WDB = 'SET_CONTENT_WDB';

export declare type dataWdb = {
  success: boolean;
  wdbDataList: ResponseData<WDBType>;
};

export declare type WdbReducer = {
  data: dataWdb | null;
  content: Array<WDBType> | null;
};

/**
 * @param {dataWdb} data
 * @return {any}
 */
export function setDataWdb(data) {
  return {
    type: SET_DATA_WDB,
    payload: data,
  };
}

/**
 * @param {Array<WDBType>} data
 * @return {any}
 */
export function setContentWdb(data) {
  return {
    type: SET_CONTENT_WDB,
    payload: data,
  };
}

const initialState: WdbReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function wdbReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WDB:
      return { ...state, data: action.payload };
    case SET_CONTENT_WDB:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KeycloakApp } from '../utils/Keycloak/Keycloak';

const uri = process.env.REACT_APP_SSAS_FORMATION_URL_AGCC;

// Define a service using a base URL and expected endpoints
export const formationApi = createApi({
  reducerPath: 'formationApi',
  tagTypes: ['formation'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${uri}`,
    prepareHeaders: async (headers) => {
      const keycloak = KeycloakApp;
      if (keycloak?.isTokenExpired?.(5)) {
        await keycloak.updateToken(5);
      }
      headers.set('Authorization', 'Bearer ' + keycloak.token);
    },
  }),
  endpoints: (builder) => ({
    getFormationData: builder.query({
      query: (path: string) => path,
      providesTags: (result, error) => [{ type: 'formation' }],
    }),
  }),
  keepUnusedDataFor: 300,
});

export const { useGetFormationDataQuery } = formationApi;

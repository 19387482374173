import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WELDING_POSITION = 'SET_DATA_WELDING_POSITION';
const SET_CONTENT_WELDING_POSITION = 'SET_CONTENT_WELDING_POSITION';

export declare type DataWeldingPosition = {
  success: boolean;
  DataList: ResponseData<WeldingPosition>;
};
export declare type WeldingPosition = {
  weldingPositionId: number;
  name: string;
  description: string;
  id: number;
};
export declare type WeldingPositionReducer = {
  data: DataWeldingPosition | null;
  content: Array<WeldingPosition> | null;
};

/**
 * @param {Array<DataWeldingPosition>} data
 * @return {any}
 */
export function setContentWeldingPosition(data) {
  return {
    type: SET_CONTENT_WELDING_POSITION,
    payload: data,
  };
}

const initialState: WeldingPositionReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {WeldingPositionReducer}
 */
export function weldingPositionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDING_POSITION:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDING_POSITION:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { AccountType } from '../../components/Admin/Accounts/AccountsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_ACCOUNTS = 'SET_DATA_ACCOUNTS';
const SET_CONTENT_ACCOUNTS = 'SET_CONTENT_ACCOUNTS';

export declare type dataAccounts = {
  success: boolean;
  accountDataList: ResponseData<AccountType>;
};

export declare type AccountsReducer = {
  data: dataAccounts | null;
  content: Array<AccountType> | null;
};

/**
 * @param {dataAccounts} data
 * @return {any}
 */
export function setDataAccounts(data) {
  return {
    type: SET_DATA_ACCOUNTS,
    payload: data,
  };
}

/**
 * @param {Array<AccountType>} data
 * @return {any}
 */
export function setContentAccounts(data) {
  return {
    type: SET_CONTENT_ACCOUNTS,
    payload: data,
  };
}

const initialState: AccountsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ACCOUNTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_ACCOUNTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

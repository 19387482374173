import { ResponseData } from '../../utils/API/APITypes';
import { ProductReportElementType } from '../../components/Workplace/AGPP/ProductReportElement/ProductReportElementData';

const SET_DATA_PRODUCTREPORTELEMENT = 'SET_DATA_PRODUCTREPORTELEMENT';
const SET_CONTENT_PRODUCTREPORTELEMENT = 'SET_CONTENT_PRODUCTREPORTELEMENT';

export declare type dataProductReportElement = {
  success: boolean;
  productReportElementDataList: ResponseData<ProductReportElementType>;
};

export declare type ProductReportElementReducer = {
  data: dataProductReportElement | null;
  content: Array<ProductReportElementType> | null;
};

/**
 * @param {dataProductReportElement} data
 * @return {any}
 */
export function setDataProductReportElement(data) {
  return {
    type: SET_DATA_PRODUCTREPORTELEMENT,
    payload: data,
  };
}

/**
 * @param {Array<ProductReportElementType>} data
 * @return {any}
 */
export function setContentProductReportElement(data) {
  return {
    type: SET_CONTENT_PRODUCTREPORTELEMENT,
    payload: data,
  };
}

const initialState: ProductReportElementReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function productreportelementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_PRODUCTREPORTELEMENT:
      return { ...state, data: action.payload };
    case SET_CONTENT_PRODUCTREPORTELEMENT:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

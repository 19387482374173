import { DictionaryData } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/pipeline-books-statuses';
const name = 'title';

export const statusesBookData: DictionaryData = {
  apiMethod: api,
  dataListName: 'pipelineBooksStatusesList',
  idName: idName,
  pageHeaderTitle: 'Statuses book',
  tabNameField: name,
  columnsName: 'BookStatusPipelineTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const statusesBookProps = {
  idName,
  apiMethod: api,
  dataListName: 'pipelineBooksStatuses',
  name,
};

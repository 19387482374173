import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useAppDispatch, useAppSelector } from '../../../store/rootReducer';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { yambookApi } from '../../../store/yambookApi';
import { TIMEOUT_TOKEN } from '../../../utils/Base/constants/TIMEOUT_TOKEN';

const ErrorAction = React.memo(({ onFinishEnd }: { onFinishEnd: Function }) => {
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const url = `${process.env.REACT_APP_YAMBOOK}/api`;

  const token = KeycloakApp.token;
  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      fileList: values.fileList?.fileList?.map((file) => file.response.data.id),
      memberIdList: [1, 4, 7],
      description: `Ссылка
-----------
${window.location.href}

Сообщение
-----------
${values.description ?? 'Отсутствует'}

Автор
-----------

${profile?.personFullName}

Браузер
-----------
${navigator.userAgent}`,
      labelList: values.labelList ? [values.labelList] : undefined,
    };

    if (KeycloakApp?.isTokenExpired(TIMEOUT_TOKEN)) {
      await KeycloakApp.updateToken(TIMEOUT_TOKEN);
    }

    axios
      .post(`${url}/tasks`, data, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      })
      .then(() => {
        notification.success({ message: t('Message sent successfully') });
        dispatch(
          yambookApi.util?.invalidateTags([
            { type: 'Yambook', id: 'getSupportTask' },
          ])
        );
        onFinishEnd();
      })
      .catch(() => {
        notification.error({ message: t('Error') });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const options = [
    { value: 162, label: t('Error on the site') },
    { value: 163, label: t('Proposal') },
    { value: 166, label: t('Question') },
    { value: 161, label: t('Other') },
  ];

  const labelOptions = [
    { value: 70, label: t('Very high') + ' 🔥' },
    { value: 71, label: t('Medium') },
    { value: 72, label: t('Low') },
  ];

  const uploadProps: UploadProps = {
    name: 'file',
    action: `https://backtaskboard.weldnet.ru/api/filesystem`,
    headers: {
      authorization: 'Bearer ' + (token ?? ''),
    },
    defaultFileList: [],
    multiple: true,
    onRemove: (file) => {
      axios
        .delete(`${url}/filesystem/${file.response.data.id}`, {
          headers: {
            authorization: 'Bearer ' + token,
          },
        })
        .catch(() => notification.error({ message: 'Ошибка удаления файла' }));
    },
  };

  return (
    <Form layout={'vertical'} onFinish={onFinish}>
      <Form.Item
        label={t('Title_1')}
        name={'title'}
        rules={[{ required: true, message: '' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('Category')}
        name={'columnId'}
        rules={[{ required: true, message: '' }]}
      >
        <Select options={options} />
      </Form.Item>
      <Form.Item label={t('Priority')} name={'labelList'}>
        <Select options={labelOptions} />
      </Form.Item>
      <Form.Item label={t('Description')} name={'description'}>
        <TextArea autoSize={{ minRows: 4 }} />
      </Form.Item>
      <Form.Item label={t('Files')} name={'fileList'}>
        <Upload {...uploadProps} data-testid={'upload'}>
          <Button icon={<UploadOutlined />}>{t('Upload')}</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          key="submit"
          type="primary"
          loading={loading}
          htmlType={'submit'}
        >
          {t('Submit')}
        </Button>
      </Form.Item>
    </Form>
  );
});
ErrorAction.displayName = 'ErrorAction';
export default ErrorAction;

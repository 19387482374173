import { WeldingLocationsType } from '../../components/Admin/Dictionaries/WeldingLocationsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WELDINGLOCATIONS = 'SET_DATA_WELDINGLOCATIONS';
const SET_CONTENT_WELDINGLOCATIONS = 'SET_CONTENT_WELDINGLOCATIONS';

export declare type dataWeldingLocations = {
  success: boolean;
  joinWeldingLocationDataList: ResponseData<WeldingLocationsType>;
};

export declare type WeldingLocationsReducer = {
  data: dataWeldingLocations | null;
  content: Array<WeldingLocationsType> | null;
};

/**
 * @param {Array<WeldingLocationsType>} data
 * @return {any}
 */
export function setContentWeldingLocations(data) {
  return {
    type: SET_CONTENT_WELDINGLOCATIONS,
    payload: data,
  };
}

const initialState: WeldingLocationsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function weldinglocationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDINGLOCATIONS:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDINGLOCATIONS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

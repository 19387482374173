import { PositionsTypesType } from '../Positions/PositionsData';
import { NewPageData } from '../../../AppTypes';

export declare type AccountType = {
  accountId: number;
  creationTime: string;
  email: string;
  enabled: boolean;
  language: string;
  lastTimeActive: string;
  login: string;
  organizationPositionData: PositionsTypesType;
  organizationServiceNumber: string | null;
  personFullName: string | null;
  personName: string | null;
  personPatronymic: string | null;
  personSurname: string | null;
  updateTime: string;
  accountProfileData: AccountProfileData;
  admin: boolean;
  keyCloakId: string | null;
};

export declare type AccountProfileData = {
  accountProfileId: number;
  tabsSettings: string;
  tablesSettings: string;
  userAvatarId: number | null;
  userBackgroundId: number | null;
};

export const AccountsData: NewPageData = {
  apiMethod: 'api/account',
  dataListName: 'accountDataList',
  idName: 'accountId',
  columnsName: 'AccountsTable',
  pageHeaderTitle: 'Accounts',
  tabNameField: 'personFullName',
};

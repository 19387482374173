import React from 'react';
import { Button, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import { TemperatureFormProps, TemperatureFormState } from '../TemperatureData';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

/**
 * Создание записи
 */
class TemperatureForm extends React.Component<
  TemperatureFormProps,
  TemperatureFormState
> {
  /**
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      nightTemperature: null,
      dayTemperature: null,
      dailyTemperature: null,
      oldValues: null,
    };
  }

  /**
   * Заполнение формы
   */
  componentDidMount() {
    if (this.props.type === 'edit') {
      const { currentRow } = this.props;
      this.setState(
        {
          date: currentRow!.date ? currentRow!.date : null,
          nightTemperature: currentRow!.nightTemperature,
          dayTemperature: currentRow!.dayTemperature,
          dailyTemperature: currentRow!.dailyTemperature,
        },
        () => this.setState({ oldValues: this.state })
      );
    }
  }

  /**
   * @return {JSX}
   */
  render() {
    const { date, nightTemperature, dayTemperature, dailyTemperature } =
      this.state;
    const { t, type, isFetching, routerProps } = this.props;
    const fields = [
      { name: 'date', value: date ? dayjs(date) : null },
      { name: 'nightTemperature', value: nightTemperature },
      { name: 'dayTemperature', value: dayTemperature },
      { name: 'dailyTemperature', value: dailyTemperature },
    ];
    return (
      <Form
        layout={'vertical'}
        fields={fields}
        // @ts-ignore
        onFinish={(values) => {
          const body = { ...values, date: dayjs(date).format('YYYY-MM-DD') };
          if (type === 'edit') {
            body.temperatureId = this.props.currentRow!.temperatureId;
            this.props.onFinishEdit!(body);
          } else {
            const data = routerProps?.location.pathname.includes('agcc')
              ? { temperatureCreationDataList: [body] }
              : body;
            this.props.onFinishAdd!(data);
          }
        }}
      >
        <Row gutter={12}>
          <Col span={5}>
            <Form.Item
              label={t('Date')}
              name={'date'}
              rules={[{ required: true, message: '' }]}
            >
              <DatePicker
                format={'DD.MM.YYYY'}
                style={{ width: '100%' }}
                onChange={(e) => this.setState({ date: e })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('Night temperature')}
              name={'nightTemperature'}
              rules={[{ required: true, message: '' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.setState({ nightTemperature: +e! });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t('Day temperature')}
              name={'dayTemperature'}
              rules={[{ required: true, message: '' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.setState({ dayTemperature: +e! });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={t('Daily temperature')}
              name={'dailyTemperature'}
              rules={[{ required: true, message: '' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.setState({ dailyTemperature: +e! });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item key="button">
          <Button type="primary" htmlType="submit" disabled={isFetching}>
            {t('Save')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(TemperatureForm);

const SET_CONTENT_NDT = 'SET_CONTENT_NDT';

export declare type NDTReducer = {
  content: Array<any> | null;
};
/**
 * @param {Array<JointTypesType>} data
 * @return {any}
 */
export function setContentNDT(data) {
  return {
    type: SET_CONTENT_NDT,
    payload: data,
  };
}

const initialState: NDTReducer = {
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function ndtReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONTENT_NDT:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

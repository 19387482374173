import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/welding-card/steel-type';
const name = 'title';

export const steelTypeData: DictionaryData = {
  apiMethod: api,
  dataListName: 'steelTypeDataList',
  idName: idName,
  pageHeaderTitle: 'Steel types',
  tabNameField: name,
  columnsName: 'SteelTypeTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
};

export const onceSteelTypeProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'steelTypeData',
  name: name,
};

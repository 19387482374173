import React from 'react';
import { Button, Flex, Menu, Modal } from 'antd';
import { WorkplaceMenuProps } from '../LayoutTypes';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { menuPermissionSetter } from '../../../utils/Base/helpers/permissionHelpers';
import API from '../../../utils/API/API';
import { menuItems } from './helpers/menuItems';
import { menuItemsAGCC } from './helpers/menuItemsAGCC';
import { isDesktop } from 'react-device-detect';
import FeedbackForm from './FeedbackForm';
import { CustomerServiceOutlined } from '@ant-design/icons';

/**
 * @class
 */
class WorkplaceMenu extends React.Component<WorkplaceMenuProps, any> {
  /**
   * @param {WorkplaceMenuProps} props
   */
  constructor(props: WorkplaceMenuProps) {
    super(props);
    this.state = {
      updateHistoryContent: [],
      isNew: false,
      isFeedbackForm: false,
    };
  }
  /**
   * Получение данных об обновлениях
   */
  componentDidMount() {
    const lastUpdateId = localStorage.getItem('lastUpdateId') ?? 0;
    const project = localStorage.getItem('project');
    if (project === 'agpp') {
      API.get('api/update-history?size=5&sort=createDate%2Cdesc').then(
        (res) => {
          this.setState({
            updateHistoryContent: res.data.updateHistoryDataList.content,
            isNew:
              +lastUpdateId <
              (res.data.updateHistoryDataList.content[0]?.updateHistoryId ??
                -1),
          });
        }
      );
    }
  }

  /**
   * Отправить информацию в локальное хранилище при просмотре обновлений
   */
  updateHistoryView() {
    const { updateHistoryContent } = this.state;
    localStorage.setItem(
      'lastUpdateId',
      updateHistoryContent[0]?.updateHistoryId.toString() ?? '0'
    );
    this.setState({ isNew: false });
  }

  /**
   * Метод render наследуемый из React.Component
   * @return {JSX}
   */
  render() {
    const { router, permission, t } = this.props;
    const { isFeedbackForm } = this.state;
    const activeMenuItem = [`/${router.location.pathname.split('/')[2]}`];
    const project = localStorage.getItem('project');
    const menu =
      project === 'agcc'
        ? menuItemsAGCC(this.props)
        : menuItems(this.props, this.state);

    const filteredMenu: typeof menu =
      menu && permission ? menuPermissionSetter(menu, permission) : [];

    const MenuComponent = (
      <Menu
        theme="dark"
        mode={'horizontal'}
        items={filteredMenu}
        selectedKeys={activeMenuItem}
        onOpenChange={() => this.updateHistoryView()}
      />
    );

    return (
      <>
        {isDesktop ? (
          <Flex gap={8} align={'center'} justify="space-between">
            <div style={{ color: '#48aad0', fontSize: '2em', fontWeight: 700 }}>
              {project === 'agcc' ? 'АГХK ПЕ' : 'АГПЗ'}
            </div>
            <div style={{ width: 'calc(100% - 218px)' }}>{MenuComponent}</div>
            <Button
              type={'primary'}
              onClick={() => this.setState({ isFeedbackForm: true })}
              icon={<CustomerServiceOutlined />}
            >
              {t('Support')}
            </Button>
          </Flex>
        ) : (
          MenuComponent
        )}
        <Modal
          open={isFeedbackForm}
          onCancel={() => this.setState({ isFeedbackForm: false })}
          destroyOnClose
          footer={false}
          title={t('Contact us')}
        >
          <FeedbackForm
            onFinishEnd={() => this.setState({ isFeedbackForm: false })}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.authReducer.profile,
  permission: state.authReducer.permission,
});

export default connect(mapStateToProps)(withTranslation()(WorkplaceMenu));

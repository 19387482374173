import { TabType } from '../../LayoutTypes';
import { clearNonPermissionTabs } from '../../../../utils/Base/helpers/permissionHelpers';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../store/rootReducer';
import { useTranslation } from 'react-i18next';
import { MAIN_TAB } from './mainTab';
import { useDispatch } from 'react-redux';
import { setInitialTab } from '../../../../store/reducers/WorkplaceTabs';

export const useSetInitialTabs = (
  name: string,
  permission: any,
  permissionList: any
) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const initialTabs: Array<TabType> = [MAIN_TAB];

  return () => {
    const currentLocationPathname = location.pathname;
    const { accountProfileData } = profile;
    let tabsContainer: Array<TabType>;
    if (!accountProfileData?.tabsSettings) {
      tabsContainer = initialTabs;
      if (currentLocationPathname !== '/') {
        tabsContainer.push({
          title: t(name),
          key: currentLocationPathname,
          closable: true,
          permission: permission,
        });
      }
    } else {
      tabsContainer = JSON.parse(accountProfileData.tabsSettings);
      if (currentLocationPathname !== '/') {
        if (!tabsContainer.find((tab) => tab.key === currentLocationPathname)) {
          tabsContainer.push({
            title: t(name),
            key: currentLocationPathname,
            closable: currentLocationPathname !== '/',
            permission: permission,
          });
        }
      }
      tabsContainer = clearNonPermissionTabs(permissionList, tabsContainer);
    }
    if (tabsContainer) {
      dispatch(setInitialTab(tabsContainer));
    }
  };
};

import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_TEST_PACKET_ACCOUNT_STATUS =
  'SET_DATA_TEST_PACKET_ACCOUNT_STATUS';
const SET_CONTENT_TEST_PACKET_ACCOUNT_STATUS =
  'SET_CONTENT_TEST_PACKET_ACCOUNT_STATUS';

export declare type TestPacketAccountStatusType = {
  testPacketAccountStatusId: number;
  title: string;
  description: null | string;
};

export declare type DataTestPacketAccountStatus = {
  success: boolean;
  testPacketAccountStatusesDataList: ResponseData<TestPacketAccountStatusType>;
};

export declare type TestPacketAccountStatusReducer = {
  data: DataTestPacketAccountStatus | null;
  content: Array<TestPacketAccountStatusType> | null;
};

/**
 * @param {Array<DataTestPacketStatus>} data
 * @return {any}
 */
export function setContentTestPacketAccountStatus(data) {
  return {
    type: SET_CONTENT_TEST_PACKET_ACCOUNT_STATUS,
    payload: data,
  };
}

const initialState: TestPacketAccountStatusReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function testPacketAccountStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TEST_PACKET_ACCOUNT_STATUS:
      return { ...state, data: action.payload };
    case SET_CONTENT_TEST_PACKET_ACCOUNT_STATUS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

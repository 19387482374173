import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { commonApi } from './commonApi';
import { yambookApi } from './yambookApi';
import { formationApi } from './formationApi';
import { setupListeners } from '@reduxjs/toolkit/query';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      commonApi.middleware,
      yambookApi.middleware,
      formationApi.middleware
    ),
});

setupListeners(store.dispatch);

export default store;

import { DictionaryData } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'rfiStatusId';
const api = 'api/reference-data/ewrfi-status';
const name = 'title';

export const ewRfiStatusData: DictionaryData = {
  apiMethod: api,
  dataListName: 'ewRfiStatusDataList',
  idName: idName,
  pageHeaderTitle: 'EwRfiStatus',
  tabNameField: name,
  columnsName: 'EwRfiStatusTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

import { useState } from 'react';
import API from '../../API/API';

export const useEditData = (api: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const onFinishEdit = async (values) => {
    setIsLoading(true);
    try {
      await API.put(api, {
        ...values,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, onFinishEdit };
};

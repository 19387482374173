import React from 'react';
import { NewFormProps } from '../../../../AppTypes';
import { PipingInstrumentationDiagram } from '../../../Workplace/AGCC/Pipeline/TP/Lines/types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

const PipingInstrumentationDiagramForm = React.memo(
  ({
    currentRow,
    isFetching,
    onFinishEdit,
    onFinishAdd,
    type,
  }: NewFormProps<PipingInstrumentationDiagram>) => {
    const { t } = useTranslation();
    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!([values])
        : onFinishEdit!([{ ...values, id: currentRow?.id }]);
    };
    const rules = [{ required: true, message: '' }];
    return (
      <Form layout={'vertical'} onFinish={onFinish} initialValues={currentRow}>
        <Form.Item label={t('Number')} name={'number'} rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
PipingInstrumentationDiagramForm.displayName =
  'PipingInstrumentationDiagramForm';
export default PipingInstrumentationDiagramForm;

import { organizationDataType } from '../../components/Admin/Organizations/OrganizationsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_ORGANIZATIONS = 'SET_DATA_ORGANIZATIONS';
const SET_CONTENT_ORGANIZATIONS = 'SET_CONTENT_ORGANIZATIONS';

export declare type dataOrganizations = {
  success: boolean;
  organizationDataList: ResponseData<organizationDataType>;
};

export declare type OrganizationsReducer = {
  data: dataOrganizations | null;
  content: Array<organizationDataType> | null;
};

/**
 * @param {dataOrganizations} data
 * @return {any}
 */
export function setDataOrganizations(data) {
  return {
    type: SET_DATA_ORGANIZATIONS,
    payload: data,
  };
}

/**
 * @param {Array<organizationDataType>} data
 * @return {any}
 */
export function setContentOrganizations(data) {
  return {
    type: SET_CONTENT_ORGANIZATIONS,
    payload: data,
  };
}

const initialState: OrganizationsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ORGANIZATIONS:
      return { ...state, data: action.payload };
    case SET_CONTENT_ORGANIZATIONS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

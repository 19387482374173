import { ResponseData } from '../../utils/API/APITypes';
import { BasePhaseRFI } from '../../components/Workplace/AGPP/Electrican/BasePhase/EwBasePhaseTypes';

const SET_DATA_RFI = 'SET_DATA_RFI';
const SET_CONTENT_RFI = 'SET_CONTENT_RFI';

export declare type DataRfi = {
  success: boolean;
  DataList: ResponseData<BasePhaseRFI>;
};

export declare type RfiReducer = {
  data: DataRfi | null;
  content: Array<BasePhaseRFI> | null;
};

/**
 * @param {DataRfi} data
 * @return {any}
 */
export function setDataRfi(data) {
  return {
    type: SET_DATA_RFI,
    payload: data,
  };
}

/**
 * @param {Array<DataRfi>} data
 * @return {any}
 */
export function setContentRfi(data) {
  return {
    type: SET_CONTENT_RFI,
    payload: data,
  };
}

const initialState: RfiReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function rfiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_RFI:
      return { ...state, data: action.payload };
    case SET_CONTENT_RFI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';
import React from 'react';
import CommentRfiForm from '../../../Workplace/AGPP/Electrican/RFI/components/CommentRfiForm';
import {
  getInitialColumnsCommentRfiStatus,
  rfiStatusRender,
} from '../../../Workplace/AGPP/Electrican/RFI/RfiExtra';
import CommentRfiFormAgcc from '../../../Workplace/AGCC/Electrician/RFI/components/CommentRfiFormAgcc';

const idName = 'ewRfiCommentId';
const idNameAGCC = 'ewRfiCommentId';
const api = 'api/reference-data/ew-rfi-comment';
const apiAGCC = 'api/ele-rfi-log-comments';
const name = 'title';

export const commentRfiStatusData: DictionaryData = {
  apiMethod: api,
  dataListName: 'ewRfiCommentDataList',
  idName: idName,
  pageHeaderTitle: 'Comments on inspections',
  tabNameField: name,
  columnsName: 'CommentsRfiStatusTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsCommentRfiStatus,
  name: name,
  width: '400px',
  editComponent: <CommentRfiForm type={'edit'} />,
  addComponent: <CommentRfiForm type={'add'} />,
  popupList: getPopupList,
};

export const commentRfiStatusDataAGCC: DictionaryData = {
  apiMethod: apiAGCC,
  dataListName: 'eleRfiLogCommentList',
  idName: idNameAGCC,
  pageHeaderTitle: 'Comments on inspections',
  tabNameField: name,
  columnsName: 'CommentsRfiStatusTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsCommentRfiStatus,
  name: name,
  width: '400px',
  editComponent: <CommentRfiFormAgcc type={'edit'} />,
  addComponent: <CommentRfiFormAgcc type={'add'} />,
  popupList: getPopupList,
};

export const onceCommentRfiStatusProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'ewRfiCommentData',
  editComponent: <CommentRfiForm type={'edit'} />,
  descriptions: [
    {
      label: 'Comment',
      fieldName: 'title',
      span: 8,
    },
    {
      label: 'Inspection status',
      fieldName: 'ewRfiStatusData',
      span: 4,
      render: (item) =>
        item ? rfiStatusRender(item.rfiStatusId, item.title) : '',
    },
    {
      label: 'Description',
      fieldName: 'description',
      span: 12,
    },
  ],
  name: name,
};

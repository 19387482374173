import React from 'react';
import { Badge } from 'antd';
import { useGetNotificationCount } from '../../Admin/Accounts/AccountInfo/useGetNotificationCount';

const NotificationLabel = React.memo(() => {
  const notificationCount = useGetNotificationCount();
  return (
    <Badge count={notificationCount} size={'small'}>
      <div
        style={{ color: 'transparent', display: 'inline-block', width: 1 }}
      />
    </Badge>
  );
});
NotificationLabel.displayName = 'NotificationLabel';
export default NotificationLabel;

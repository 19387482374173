import { PipelineMaterialsType } from '../../components/Admin/Dictionaries/PipelineMaterialsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_PIPELINEMATERIALS = 'SET_DATA_PIPELINEMATERIALS';
const SET_CONTENT_PIPELINEMATERIALS = 'SET_CONTENT_PIPELINEMATERIALS';

export declare type dataPipelineMaterials = {
  success: boolean;
  pipelineMaterialDataList: ResponseData<PipelineMaterialsType>;
};

export declare type PipelineMaterialsReducer = {
  data: dataPipelineMaterials | null;
  content: Array<PipelineMaterialsType> | null;
};

/**
 * @param {Array<PipelineMaterialsType>} data
 * @return {any}
 */
export function setContentPipelineMaterials(data) {
  return {
    type: SET_CONTENT_PIPELINEMATERIALS,
    payload: data,
  };
}

const initialState: PipelineMaterialsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function pipelinematerialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_PIPELINEMATERIALS:
      return { ...state, data: action.payload };
    case SET_CONTENT_PIPELINEMATERIALS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { WeldlogType } from '../../components/Workplace/AGPP/Weldlog/WeldlogData';

const SET_DATA_WELDLOG = 'SET_DATA_WELDLOG';
const SET_CONTENT_WELDLOG = 'SET_CONTENT_WELDLOG';

export declare type dataWeldlog = {
  success: boolean;
  weldlogDataList: ResponseData<WeldlogType>;
};

export declare type WeldlogReducer = {
  data: dataWeldlog | null;
  content: Array<WeldlogType> | null;
};

/**
 * @param {dataWeldlog} data
 * @return {any}
 */
export function setDataWeldlog(data) {
  return {
    type: SET_DATA_WELDLOG,
    payload: data,
  };
}

/**
 * @param {Array<WeldlogType>} data
 * @return {any}
 */
export function setContentWeldlog(data) {
  return {
    type: SET_CONTENT_WELDLOG,
    payload: data,
  };
}

const initialState: WeldlogReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function weldlogReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDLOG:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDLOG:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

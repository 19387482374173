import { ResponseData } from '../../utils/API/APITypes';
import { AoRPIType } from '../../components/Workplace/AGPP/AoRPI/AoRPIData';

const SET_DATA_AORPI = 'SET_DATA_AORPI';
const SET_CONTENT_AORPI = 'SET_CONTENT_AORPI';

export declare type dataAoRPI = {
  success: boolean;
  productReportDataList: ResponseData<AoRPIType>;
};

export declare type AoRPIReducer = {
  data: dataAoRPI | null;
  content: Array<AoRPIType> | null;
};

/**
 * @param {dataAoRPI} data
 * @return {any}
 */
export function setDataAoRPI(data) {
  return {
    type: SET_DATA_AORPI,
    payload: data,
  };
}

/**
 * @param {Array<AoRPIType>} data
 * @return {any}
 */
export function setContentAoRPI(data) {
  return {
    type: SET_CONTENT_AORPI,
    payload: data,
  };
}

const initialState: AoRPIReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function aorpiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_AORPI:
      return { ...state, data: action.payload };
    case SET_CONTENT_AORPI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

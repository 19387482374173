import { DictionaryData } from '../../DictionariesTypes';
import { getInitialColumns } from './extra';

const idName = 'id';
const api = 'api/group-technical-devices/steel-bridge-structures';
const name = 'title';

export const steelBridgeStructureData: DictionaryData = {
  apiMethod: api,
  dataListName: 'steelBridgeStructuresDataList',
  idName: idName,
  pageHeaderTitle: 'Steel bridge structures',
  tabNameField: name,
  columnsName: 'steelBridgeStructureTable',
  columns: getInitialColumns,
  name: name,
  addComponent: false,
  notGotRecord: true,
};

import React from 'react';
import { PopupProps } from './PopupTypes';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

/**
 * Контекстное меню
 */
class Popup extends React.Component<PopupProps, any> {
  private popupRef = React.createRef<HTMLUListElement>();
  /**
   * @param {PopupProps} props
   */
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      listNew: null,
      pageY: props.pageY,
    };
  }

  /**
   * @param {PopupProps} prevProps
   */
  componentDidUpdate(prevProps) {
    const { pageY } = this.props;
    if (prevProps.pageY !== pageY) this.checkWindowSize();
  }

  /**
   * Проверка размеров попапа для отображения в окне
   */
  checkWindowSize = () => {
    const windowSize = document.documentElement.clientHeight;
    const popUp = this.popupRef;
    const popSize = popUp.current?.clientHeight || 0;
    if (popSize + (this.props.pageY || 0) > windowSize) {
      this.setState({ pageY: windowSize - popSize - 20 });
    } else this.setState({ pageY: this.props.pageY });
  };
  /**
   * Метод отрисовки контекстного меню
   * @return {JSX}
   */
  render() {
    const { confirm } = Modal;
    const { pageY } = this.state;
    const { pageX, visible, list, setDeleteRow, currentRow, t } = this.props;
    const { listNew } = this.state;
    const rawList = listNew || list;
    const darkTheme = localStorage.getItem('darkTheme');

    return (
      <ul
        className={darkTheme ? 'popup_dark popup' : 'popup'}
        ref={this.popupRef}
        data-testid="popup"
        style={{
          top: `${pageY}px`,
          left: `${pageX}px`,
          display: `${visible ? 'block' : 'none'}`,
          position: 'fixed',
          zIndex: '9999',
        }}
      >
        {Array.isArray(rawList) &&
          rawList.map((item, index) => {
            const showConfirm = () => {
              setDeleteRow?.(currentRow);
              confirm({
                title:
                  item.type === 'delete'
                    ? t('Are you sure to delete this element?')
                    : t('Are you sure to delete this elements?'),
                icon: <ExclamationCircleOutlined />,
                okText: t('Yes'),
                okType: 'danger',
                cancelText: t('Cancel'),
                onOk() {
                  item.onClick();
                },
                onCancel() {
                  setDeleteRow?.(null);
                },
              });
            };
            if (
              item.type === 'delete' ||
              item.type === 'massDelete' ||
              item.type === 'deleteId'
            ) {
              return (
                <li
                  key={index + item.title}
                  style={{ display: 'flex' }}
                  onClick={showConfirm}
                >
                  <div style={{ marginRight: '10px' }}>{item.icon}</div>
                  <div>{item.title}</div>
                </li>
              );
            } else {
              return (
                <li
                  key={index + item.title}
                  style={{ display: 'flex' }}
                  onClick={item.onClick}
                >
                  <div style={{ marginRight: '10px' }}>{item.icon}</div>
                  <div>{item.title}</div>
                </li>
              );
            }
          })}
      </ul>
    );
  }
}

export default withTranslation()(Popup);

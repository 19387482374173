import React, { ReactNode } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Alert, Button, Descriptions, Tabs, TabsProps } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { ElementViewProps, ElementViewState } from '../AbstractElementTypes';
import { withTranslation } from 'react-i18next';
import CollapsibleModal from '../../../CollapsibleModal/CollapsibleModal';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { isDesktop, isMobile } from 'react-device-detect';
import { additionalValues } from '../../../../store/reducers/WorkplaceTabs';
import get from 'lodash/get';
import { isPermissionAction } from '../../../Base/helpers/permissionHelpers';

/**
 * Отображение отдельного элемента
 */
class AbstractElementViewer extends React.Component<
  ElementViewProps,
  ElementViewState
> {
  /**
   * @param {ElementViewProps} props
   */
  constructor(props) {
    super(props);

    this.state = {
      tabKey: new URLSearchParams(window.location.search).get('tab') ?? '0',
    };
  }

  /**
   * Отрисовка меню extra
   * @return {Array<JSX>}
   */
  renderExtra = () => {
    const { tabs, pageExtra, editMode, content, descriptions } = this.props;
    const { tabKey: key } = this.state;
    const editButton: ReactNode = (
      <Button
        key="edit"
        type={'primary'}
        icon={<EditOutlined />}
        onClick={() => {
          this.props.openEdit(true);
        }}
      />
    );
    const pageExtraButtons: Array<any> = [];
    if (pageExtra) {
      pageExtra.forEach((item) => {
        if (React.isValidElement(item)) {
          if (this.props?.getRecord) {
            pageExtraButtons.push(
              React.cloneElement(item, {
                // @ts-ignore
                onClick: () => {
                  // @ts-ignore
                  this.props.getRecord(this.props.content);
                },
              })
            );
          } else {
            pageExtraButtons.push(item);
          }
        }
      });
    }

    if (key === '0') {
      if (
        editMode &&
        this.props.editComponent &&
        isPermissionAction('update')
      ) {
        return pageExtra ? [...pageExtraButtons, editButton] : [editButton];
      } else {
        return pageExtra ? pageExtraButtons : null;
      }
    } else if (tabs) {
      const idx = descriptions ? +key - 1 : +key;
      return tabs[idx]?.extra ? tabs[idx].extra!(content) : null;
    } else {
      return null;
    }
  };

  /**
   * Обработка заголовка страницы
   * @return {string}
   */
  pageHeaderTitle = () => {
    const { pass, title } = this.props.pageHeaderList;
    const { t } = this.props;
    if (pass) {
      const passResult = this.checkPass(pass, t('Empty'));

      if (title) {
        return `${title}: ${passResult}`;
      } else {
        return passResult;
      }
    } else {
      if (title) {
        return title;
      } else {
        return t('Empty');
      }
    }
  };

  /**
   * @param {string} pass
   * @param {string} errorName
   * @return {JSX | string}
   */
  checkPass = (pass: string | string[] | ((any) => string), errorName) => {
    const { content } = this.props;
    const getValue = (content, value) => get(content, value);
    const data =
      typeof pass === 'function' ? pass(content) : getValue(content, pass);
    const typeOfData = typeof data;
    if (
      typeOfData === 'string' ||
      typeOfData === 'boolean' ||
      typeOfData === 'number' ||
      typeOfData === 'object'
    ) {
      return pass === 'string'
        ? pass.toLowerCase().includes('date') &&
          !pass.toLowerCase().includes('temperature')
          ? data
            ? dayjs(data).format('DD.MM.YYYY')
            : null
          : data
        : data;
    } else {
      return errorName;
    }
  };
  /**
   * @param {string} activeKey
   */
  onChangeTab = (activeKey) => {
    const { dispatch, router } = this.props;
    dispatch!(
      additionalValues([
        {
          action: 'add',
          key: 'params',
          value: activeKey,
        },
      ])
    );
    router.setSearchParams(`tab=${activeKey}`);

    this.setState({ tabKey: activeKey });
  };

  /**
   * Инъекция
   * @param {React.ReactNode} children
   * @return {React.ReactNode}
   */
  injection = (children) => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        // @ts-ignore
        content: this.props.content,
        routerProps: this.props.router,
      });
    }
  };

  /**
   * @return {JSX}
   */
  render() {
    const {
      t,
      profile,
      router,
      destroyInactiveTabPane,
      avatar,
      tabs,
      descriptions,
      content,
      alert,
      darkTheme,
    } = this.props;
    const getNotNullAlert = () => {
      return !!(
        alert &&
        (alert.notNull === true ||
          (Array.isArray(content[alert.notNull])
            ? content[alert.notNull].length
            : content[alert.notNull]))
      );
    };
    const tabsElements =
      tabs?.map((tab, index) => {
        return {
          key: String(index + (descriptions ? 1 : 0)),
          label: tab.title,
          children: this.injection(tab.component(content)),
        };
      }) ?? [];
    const infoTab = descriptions
      ? {
          key: '0',
          label: t('Info'),
          children: (
            <>
              {getNotNullAlert() && (
                <Alert
                  {...alert}
                  description={
                    alert!.descriptionF(content) ?? alert?.description
                  }
                  message={alert!.messageF(content) ?? alert?.message}
                  style={{ marginBottom: 16 }}
                />
              )}
              <Descriptions
                bordered
                column={24}
                layout="vertical"
                labelStyle={{ fontWeight: 'bold' }}
              >
                {descriptions.map((item) => {
                  return (
                    <Descriptions.Item
                      span={isMobile ? 24 : item.span}
                      label={
                        typeof item.label === 'string'
                          ? t(item.label)
                          : item.label
                      }
                      key={item.fieldName}
                    >
                      {!item.render && (
                        <>{this.checkPass(item.fieldName, t('No data'))}</>
                      )}
                      {item.render && (
                        <>
                          {item.render(
                            this.checkPass(item.fieldName, t('No data')),
                            this.props.content
                          )}
                        </>
                      )}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            </>
          ),
        }
      : undefined;
    const items: TabsProps['items'] = [];
    if (infoTab) {
      items.push(infoTab);
    }
    items.push(...tabsElements);

    const organization =
      profile?.organizationPositionData?.organizationDepartmentData
        ?.organizationData?.title;
    const location = router.location.pathname;

    return (
      <>
        <PageHeader
          title={
            <span
              style={{
                color: darkTheme ? 'rgba(255, 255, 255, 0.85)' : undefined,
              }}
            >
              {this.pageHeaderTitle()}
            </span>
          }
          extra={
            location.includes('accounts')
              ? this.renderExtra()
              : organization?.toLowerCase().includes('нипи')
              ? null
              : this.renderExtra()
          }
          avatar={
            avatar ? { icon: <UserOutlined />, src: avatar.src } : undefined
          }
        />

        <Tabs
          items={items}
          defaultActiveKey={this.state.tabKey}
          onChange={this.onChangeTab}
          destroyInactiveTabPane={destroyInactiveTabPane}
        />

        {this.props.isEdit && (
          <CollapsibleModal
            title={t('Edit record')}
            onCancel={() => {
              this.props.openEdit(false);
            }}
            visible={this.props.isEdit}
            width={
              isDesktop
                ? typeof this.props.editModalWidth === 'number'
                  ? `${this.props.editModalWidth}vw`
                  : this.props.editModalWidth
                : '90vw'
            }
          >
            {this.props.editComponent}
          </CollapsibleModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.authReducer.profile,
  darkTheme: state.authReducer.darkTheme,
});

export default connect(mapStateToProps)(
  withTranslation()(AbstractElementViewer)
);

import { NewPageData } from '../../../../AppTypes';

export const TitleListData: NewPageData = {
  apiMethod: 'api/title',
  dataListName: 'titleListDataList',
  idName: 'titleId',
  pageHeaderTitle: 'Titles',
  tabNameField: 'number',
  columnsName: 'TitlesTable',
};

import React, { useState } from 'react';
import { Button, ColorPicker, Form, Input } from 'antd';
import { NewFormProps } from '../../../../AppTypes';
import { useTranslation } from 'react-i18next';

const ConstructionDirectionForm = React.memo(
  ({ onFinishAdd, onFinishEdit, currentRow, type }: NewFormProps<any>) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState(currentRow?.title);
    const [description, setDescription] = useState(currentRow?.description);
    const [color, setColor] = useState(currentRow?.color);
    const onFinish = (value) => {
      type === 'edit'
        ? onFinishEdit!({
            ...value,
            constructionDirectionId: currentRow?.constructionDirectionId,
          })
        : onFinishAdd!(value);
    };

    const fields = [
      { name: 'title', value: title },
      { name: 'color', value: color },
      { name: 'description', value: description },
    ];

    return (
      <Form layout={'vertical'} onFinish={onFinish} fields={fields}>
        <Form.Item
          name={'title'}
          label={t('Title_1')}
          rules={[{ required: true, message: '' }]}
        >
          <Input onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item name={'color'} label={t('Color')}>
          <ColorPicker
            presets={[
              {
                label: t('Recommended'),
                colors: [
                  '#000000',
                  '#000000E0',
                  '#000000A6',
                  '#00000073',
                  '#00000040',
                  '#00000026',
                  '#0000001A',
                  '#00000012',
                  '#0000000A',
                  '#00000005',
                  '#F5222D',
                  '#FA8C16',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                  '#F5222D4D',
                  '#FA8C164D',
                  '#FADB144D',
                  '#8BBB114D',
                  '#52C41A4D',
                  '#13A8A84D',
                  '#1677FF4D',
                  '#2F54EB4D',
                  '#722ED14D',
                  '#EB2F964D',
                ],
              },
            ]}
            onChange={(value, hex) => setColor(hex)}
          />
        </Form.Item>
        <Form.Item name={'description'} label={t('Description')}>
          <Input onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>
        <Button htmlType={'submit'} type={'primary'}>
          {type === 'edit' ? t('Edit') : t('Create')}
        </Button>
      </Form>
    );
  }
);
ConstructionDirectionForm.displayName = 'ConstructionDirectionForm';
export default ConstructionDirectionForm;

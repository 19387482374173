import React, { useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { SearchFieldsType } from './types/SearchPanelTypes';
import debounce from 'lodash/debounce';
import API from '../API/API';
import get from 'lodash/get';
import { useDictionarySelectOptions } from '../Base/hooks/useDictionarySelectOptions';

const SelectSearchPanel = React.memo(
  ({
    item: selectSettings,
    disabled,
  }: {
    item: SearchFieldsType;
    disabled: boolean;
  }) => {
    const [dataList, setDataList] = useState<any[]>();
    const [selectItemsSearch, setSelectItemsSearch] = useState<
      Array<{ value: any; item: any }>
    >([]);

    const selectItems = selectSettings?.link
      ? selectItemsSearch
      : selectSettings.selectItem ?? [];

    const onSearch = (value) => {
      const { link, dataListName } = selectSettings;
      API.get(`${link}${value}`).then((res) => {
        const resData = Array.isArray(res.data[dataListName!])
          ? res.data[dataListName!]
          : res.data[dataListName!].content;
        const rawData = resData.map((selectItem) => {
          return {
            value: get(selectItem, selectSettings.selectItem![0].value),
            item: get(selectItem, selectSettings.selectItem![0].item),
          };
        });
        setDataList(resData);
        setSelectItemsSearch(rawData);
      });
    };

    const searchHandler = debounce((value) => {
      if (value && value.length) {
        onSearch(value.trim());
      }
    }, 300);

    const dictionaryOptions = selectSettings.dictionaryData
      ? useDictionarySelectOptions(selectSettings.dictionaryData).options
      : undefined;

    return (
      <Form.Item
        label={selectSettings.label}
        name={selectSettings.name}
        rules={[{ required: selectSettings.required, message: '' }]}
      >
        <Select
          mode={selectSettings.selectMode}
          optionFilterProp={'children'}
          filterOption={
            selectSettings.showSearch
              ? false
              : (input, option) => {
                  // @ts-ignore
                  return (option?.children ?? option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }
          }
          notFoundContent={
            selectSettings.loading ? <Spin size="small" /> : null
          }
          showSearch
          onSearch={selectSettings.showSearch ? searchHandler : undefined}
          placeholder={selectSettings.placeholder}
          allowClear={!selectSettings.selectMode}
          style={{ width: '100%' }}
          disabled={disabled}
          options={dictionaryOptions?.map((option) => ({
            ...option,
            value: option.value.toString(),
          }))}
        >
          {selectSettings &&
            selectItems.length !== 0 &&
            !selectSettings.dictionaryData && (
              <>
                {selectSettings.selectItem?.[0]?.render
                  ? dataList?.map((data) => {
                      return (
                        <Select.Option
                          key={get(
                            data,
                            selectSettings.selectItem![0].value
                          ).toString()}
                          value={get(
                            data,
                            selectSettings.selectItem![0].value
                          ).toString()}
                        >
                          {/* @ts-ignore */}
                          {selectSettings.selectItem[0]!.render(data)}
                        </Select.Option>
                      );
                    })
                  : selectItems.map((rawItem) => {
                      return (
                        <Select.Option
                          key={rawItem?.value}
                          value={rawItem?.value.toString()}
                        >
                          {rawItem?.item}
                        </Select.Option>
                      );
                    })}
              </>
            )}
        </Select>
      </Form.Item>
    );
  }
);
SelectSearchPanel.displayName = 'SelectSearchPanelF';
export default SelectSearchPanel;

import API from '../../API/API';
import { AccountType } from '../../../components/Admin/Accounts/AccountsData';
import {
  setIsFetchAccount,
  setPermission,
  updateState,
} from '../../../store/reducers/AuthReducer';
import store from '../../../store/store';
import { setTablesSettings } from '../../../store/reducers/AbstractColumnsSlice';

const getUserPermission = async (account) => {
  const response = await API.get(
    // eslint-disable-next-line max-len
    `/api/permission/account?accountName=${account.personName}&accountSurname=${account.personSurname}&accountPatronymic=${account.personPatronymic}&organizationServiceNumber=${account.organizationServiceNumber}&size=1000`
  );
  store.dispatch(
    setPermission(response.data.permissionAccountDataList.content)
  );
};

export const getUserData = async (id: string | number) => {
  store.dispatch(setIsFetchAccount(true));
  const response = await API.get('api/account/key-cloak/' + id);
  const account: AccountType = response.data.accountData;

  await getUserPermission(account);
  store.dispatch(updateState(account));
  store.dispatch(setIsFetchAccount(false));
  store.dispatch(
    setTablesSettings(
      JSON.parse(account.accountProfileData?.tablesSettings ?? '[]')
    )
  );
};

import { useGetAbstractDataQuery } from '../../../store/api/abstract.api';

export const useGetAbstractContent = <T>(
  apiMethod: string,
  dataListName: string,
  id?: string | number
): { data: T | undefined; isLoading: boolean } => {
  const path = apiMethod.replace('api/', '');
  const { data, ...any } = useGetAbstractDataQuery(
    `${path}${id ? `/${id}` : ''}`
  );
  return { data: data?.[dataListName], ...any };
};

import { ResponseData } from '../../utils/API/APITypes';
import { BasePhaseType } from '../../components/Workplace/AGPP/BasePhase/BasePhaseData';

const SET_DATA_BASEPHASE = 'SET_DATA_BASEPHASE';
const SET_CONTENT_BASEPHASE = 'SET_CONTENT_BASEPHASE';

export declare type dataBasePhase = {
  success: boolean;
  basePhaseDataList: ResponseData<BasePhaseType>;
};

export declare type BasePhaseReducer = {
  data: dataBasePhase | null;
  content: Array<BasePhaseType> | null;
};

/**
 * @param {dataBasePhase} data
 * @return {any}
 */
export function setDataBasePhase(data) {
  return {
    type: SET_DATA_BASEPHASE,
    payload: data,
  };
}

/**
 * @param {Array<BasePhaseType>} data
 * @return {any}
 */
export function setContentBasePhase(data) {
  return {
    type: SET_CONTENT_BASEPHASE,
    payload: data,
  };
}

const initialState: BasePhaseReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function basephaseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_BASEPHASE:
      return { ...state, data: action.payload };
    case SET_CONTENT_BASEPHASE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

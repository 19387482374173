import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_SPECIFICATIONS = 'SET_DATA_SPECIFICATIONS';
const SET_CONTENT_SPECIFICATIONS = 'SET_CONTENT_SPECIFICATIONS';

export declare type dataSpecifications = {
  success: boolean;
  specificationDataList: ResponseData<any>;
};

export declare type SpecificationReducer = {
  data: dataSpecifications | null;
  content: Array<any> | null;
};

/**
 * @param {dataAoRPI} data
 * @return {any}
 */
export function setSpecificationData(data) {
  return {
    type: SET_DATA_SPECIFICATIONS,
    payload: data,
  };
}

/**
 * @param {Array<AoRPIType>} data
 * @return {any}
 */
export function setSpecificationContent(data) {
  return {
    type: SET_CONTENT_SPECIFICATIONS,
    payload: data,
  };
}

const initialState: SpecificationReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function specificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_SPECIFICATIONS:
      return { ...state, data: action.payload };
    case SET_CONTENT_SPECIFICATIONS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { InputControlJournal } from '../../components/Workplace/AGCC/InputControlJournal/type';

const SET_DATA_INPUT_CONTROL_JOURNAL = 'SET_DATA_INPUT_CONTROL_JOURNAL';
const SET_CONTENT_INPUT_CONTROL_JOURNAL = 'SET_CONTENT_INPUT_CONTROL_JOURNAL';

export declare type DataInputControlJournal = {
  success: boolean;
  inputControlJournalDataList: ResponseData<InputControlJournal>;
};

export declare type InputControlJournalReducer = {
  data: DataInputControlJournal | null;
  content: Array<InputControlJournal> | null;
};

/**
 * @param {DataInputControlJournal} data
 * @return {any}
 */
export function setDataInputControlJournal(data) {
  return {
    type: SET_DATA_INPUT_CONTROL_JOURNAL,
    payload: data,
  };
}

/**
 * @param {Array<DataInputControlJournal>} data
 * @return {any}
 */
export function setContentInputControlJournal(data) {
  return {
    type: SET_CONTENT_INPUT_CONTROL_JOURNAL,
    payload: data,
  };
}

const initialState: InputControlJournalReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function inputControlJournalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_INPUT_CONTROL_JOURNAL:
      return { ...state, data: action.payload };
    case SET_CONTENT_INPUT_CONTROL_JOURNAL:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

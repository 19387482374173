import { ResponseData } from "../../utils/API/APITypes";
import { WeldersType } from "../../components/Workplace/AGPP/Welders/WeldersData";

const SET_DATA_WELDERS = 'SET_DATA_WELDERS';
const SET_CONTENT_WELDERS = 'SET_CONTENT_WELDERS';

export declare type dataWelders = {
  success: boolean;
  weldersDataList: {
    content: ResponseData<WeldersType>;
  };
};

export declare type WeldersReducer = {
  data: dataWelders | null;
  content: Array<WeldersType> | null;
};

/**
 * @param {dataWelders} data
 * @return {any}
 */
export function setDataWelders(data) {
  return {
    type: SET_DATA_WELDERS,
    payload: data,
  };
}

/**
 * @param {Array<WeldersType>} data
 * @return {any}
 */
export function setContentWelders(data) {
  return {
    type: SET_CONTENT_WELDERS,
    payload: data,
  };
}

const initialState: WeldersReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function weldersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDERS:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDERS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

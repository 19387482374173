import { Responsible, SelectOptions } from '../../RFI/RFITypes';
import { useDictionarySelectOptions } from '../../../../../utils/Base/hooks/useDictionarySelectOptions';
import { ResponsibleData } from '../../RFI/Responsible/Responsible.data';

export const useResponsible = () => {
  const props = useDictionarySelectOptions<Responsible>({
    ...ResponsibleData,
    name: 'fullName',
  });

  const responsibleOptionsIsYamataContainer: SelectOptions = [];
  const responsibleOptionsNotYamataContainer: SelectOptions = [];

  props.options.forEach(({ data: responsible }) => {
    const rightArray = responsible.isYamata
      ? responsibleOptionsIsYamataContainer
      : responsibleOptionsNotYamataContainer;
    rightArray.push({
      label: responsible.fullName,
      item: responsible.fullName,
      value: responsible[ResponsibleData.idName],
    });
  });

  return {
    responsibleOptionsIsYamata: responsibleOptionsIsYamataContainer,
    responsibleOptionsNotYamata: responsibleOptionsNotYamataContainer,
  };
};

import React, { useEffect, useState } from 'react';
import WorkplaceMenu from './components/WorkplaceMenu';
import {
  Location,
  Params,
  useLocation,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { ConfigProvider, Layout, theme } from 'antd';
import { routerProps } from '../../AppTypes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/rootReducer';
import {
  updateAvatar,
  updateBackground,
} from '../../store/reducers/AuthReducer';
import { getFileImage } from '../../utils/Base/helpers/getFileImage';
import { setActiveKey } from '../../store/reducers/WorkplaceTabs';
import ruRu from 'antd/es/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import arraySupport from 'dayjs/plugin/arraySupport';
import Footer from './components/Footer';
import WorkplaceTabsF from './components/WorkplaceTabsF';
import { useChangeTab } from './components/helpers/useChangeTab';
import { useTranslation } from 'react-i18next';

export const RouterProvider = React.createContext({});

const { darkAlgorithm, defaultAlgorithm, compactAlgorithm } = theme;

dayjs.extend(arraySupport);

/**
 * Функция отрисовки основного окружения сайта
 * @param {string} name
 * @param {React.ReactNode} children
 * @param {any} permission
 * @constructor
 */
export default function WorkspaceLayout({ name, children, permission }: any) {
  const { Content, Header } = Layout;
  const dispatch = useDispatch();
  const location: Location = useLocation();
  const params: Params = useParams();
  const { t } = useTranslation();

  const navigationType = useNavigationType();
  const [darkTheme, setDarkTheme] = useState(
    !!localStorage.getItem('darkTheme')
  );

  const {
    permission: permissionList,
    profile,
    backgroundUrl: urlFile,
  } = useAppSelector((state) => state.authReducer);
  const navigate = useChangeTab();
  const userBackgroundId = profile?.accountProfileData?.userBackgroundId;
  const userAvatarId = profile?.accountProfileData?.userAvatarId;

  const locale = localStorage.getItem('i18nextLng');
  if (locale === 'ru-RU') {
    dayjs.locale('ru');
  }

  useEffect(() => {
    if (navigationType === 'POP') {
      dispatch(setActiveKey(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userBackgroundId) {
      getFileImage(userBackgroundId, updateBackground);
    }
    if (userAvatarId) {
      getFileImage(userAvatarId, updateAvatar);
    }
  }, [userBackgroundId, userAvatarId]);

  const routerProps: Omit<routerProps, 'searchParams' | 'setSearchParams'> = {
    navigate,
    location,
    params,
  };
  // @ts-ignore
  if (location?.state?.name) name = location.state.name;

  const theme = [
    darkTheme ? darkAlgorithm : defaultAlgorithm,
    compactAlgorithm,
  ];

  return (
    <RouterProvider.Provider value={routerProps}>
      <ConfigProvider
        locale={locale === 'ru-RU' ? ruRu : undefined}
        datePicker={{ style: { width: '100%' } }}
        form={{
          validateMessages: { required: t('Field cannot be empty') },
        }}
        theme={{
          algorithm: theme,
        }}
      >
        <Layout>
          <Layout
            role={'layout'}
            className={darkTheme ? 'site-layout-dark' : 'site-layout'}
            style={{
              minHeight: '100vh',
              backgroundImage: urlFile ? `url(${urlFile})` : 'none',
              backgroundSize: 'cover',
            }}
          >
            <Header
              style={{
                position: 'sticky',
                zIndex: 200,
                width: '100%',
                top: 0,
              }}
            >
              <WorkplaceMenu name={name} router={routerProps} />
            </Header>
            <Content
              className={darkTheme ? 'site-layout-dark' : 'site-layout'}
              style={{
                padding: '0 5px',
                position: 'relative',
                zIndex: 150,
              }}
            >
              <WorkplaceTabsF
                name={name}
                permission={permission}
                permissionList={permissionList}
              >
                {children}
              </WorkplaceTabsF>
            </Content>
            <Footer setDarkTheme={setDarkTheme} darkTheme={darkTheme} />
          </Layout>
        </Layout>
      </ConfigProvider>
    </RouterProvider.Provider>
  );
}

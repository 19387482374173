import { getInitialColumns, getSearchFields } from './DictionariesExtra';
import { getPopupListDeleteId } from '../../../utils/Popup/PopupExtra';
import { OnceRecordProps } from './DictionariesTypes';

const idName = 'id';
const apiMethod = 'api/reference-data/material-supply-request-status';
export const aorpiRequestStatusData = {
  apiMethod,
  dataListName: 'materialSupplyRequestStatusDataList',
  idName,
  pageHeaderTitle: 'Request statuses',
  tabNameField: 'title',
  columnsName: 'RequestStatusesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupListDeleteId,
};

export const onceAorpiRequestStatusProps: OnceRecordProps = {
  idName,
  apiMethod,
  dataListName: 'materialSupplyRequestStatus',
  name: 'title',
};

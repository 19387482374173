import {
  getInitialColumns,
  getInitialColumnsElementType,
  getSearchFields,
} from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';
import AddInspector from '../Components/AddInspector';
import React from 'react';
import EditInspector from '../Components/EditInspector';
import {
  getInitialColumnsInspectors,
  getPageHeaderExtraQc,
  getSearchFieldsInspectors,
} from '../Components/InspectorsExtra';
import {
  getInitialColumnsTemperature,
  getSearchFieldsTemperature,
} from '../TemperatureExtra';
import TemperatureForm from '../Components/TemperatureForm';
import {
  getInitialColumnsPwhtOperator,
  getSearchFieldsPwhtOperator,
} from '../PwhtOperatorExtra';
import FormPWHTOperator from '../Components/FormPWHTOperator';
import {
  formFieldsFolderStatuses,
  getInitialColumnsFolderStatuses,
} from './FolderStatusesExtra';
import {
  descriptionBlockFactorRef,
  descriptionElementTypeAgccLocation,
  descriptionFolderLocation,
  descriptionWeldersOnJointStatus,
} from '../../../../utils/Descriptions/DescriptionObjects';
import FormWelderOnJointStatus from '../Components/FormWelderOnJointStatus';
import { getInitialColumnsBlockFactors } from './BlockFactorsExtra';
import FormBlockFactor from '../Components/FormBlockFactor';
import ConstructionDirectionForm from '../Components/ConstructionDirectionForm';
import { AddPipingCategory } from '../Components/AddPipingCategory';
import FoldersStatusForm from '../../../Workplace/AGPP/Electrican/Folders/Components/FoldersStatusForm';
import FormElementType from '../Components/FormElementType';
import { DictionaryData } from '../DictionariesTypes';

export const testPacketAccountStatuses = {
  apiMethod: 'api/reference-data/test-packet-account-statuses',
  dataListName: 'testPacketAccountStatusesDataList',
  idName: 'testPacketAccountStatusId',
  pageHeaderTitle: 'Test packet account statuses',
  tabNameField: 'title',
  columnsName: 'TestPackAccountStatusesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTestPacketAccountStatusesProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/test-packet-account-statuses',
  dataListName: 'testPacketAccountStatusesData',
  name: 'title',
};

export const testPacketStatusesData = {
  apiMethod: 'api/reference-data/test-packet-statuses',
  dataListName: 'testPacketStatusesDataList',
  idName: 'testPacketStatusId',
  pageHeaderTitle: 'Test packet statuses',
  tabNameField: 'title',
  columnsName: 'TestPackStatusesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTestPacketStatusesProps = {
  idName: 'testPacketStatusId',
  apiMethod: 'api/reference-data/test-packet-statuses',
  dataListName: 'testPacketStatusesData',
  name: 'title',
};

export const jointTypesData: DictionaryData = {
  apiMethod: 'api/joint-type',
  dataListName: 'jointTypeDataList',
  idName: 'jointTypeId',
  pageHeaderTitle: 'Joint types',
  tabNameField: 'name',
  columnsName: 'JointTypesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'name',
  popupList: getPopupList,
};

export const onceJointTypesProps = {
  idName: 'jointTypeId',
  apiMethod: 'api/joint-type',
  dataListName: 'jointTypeData',
  name: 'name',
};

export const elementTypesData = {
  apiMethod: 'api/reference-data/element-type',
  dataListName: 'elementTypeDataList',
  idName: 'elementTypeId',
  pageHeaderTitle: 'Element types',
  tabNameField: 'title',
  columnsName: 'ElementTypesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const elementTypesDataAgcc = {
  apiMethod: 'api/reference-data/element-type',
  dataListName: 'elementTypeDataList',
  idName: 'elementTypeId',
  pageHeaderTitle: 'Element types',
  tabNameField: 'title',
  columnsName: 'ElementTypesTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsElementType,
  name: 'title',
  popupList: getPopupList,
  addComponent: <FormElementType type={'add'} />,
  editComponent: <FormElementType type={'edit'} />,
};

export const onceElementTypeProps = {
  idName: 'elementTypeId',
  apiMethod: 'api/reference-data/element-type',
  dataListName: 'elementTypeData',
  name: 'title',
};

export const onceElementTypeAgccProps = {
  idName: 'elementTypeId',
  apiMethod: 'api/reference-data/element-type',
  dataListName: 'elementTypeData',
  name: 'title',
  editComponent: <FormElementType type={'edit'} />,
  descriptions: descriptionElementTypeAgccLocation,
};

export const unitsOfMeasureData = {
  apiMethod: 'api/reference-data/measurement-unit',
  dataListName: 'measurementUnitDataList',
  idName: 'measurementUnitId',
  pageHeaderTitle: 'Unit of measure',
  tabNameField: 'title',
  columnsName: 'UnitsOfMeasureTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceUnitOfMeasurementProps = {
  idName: 'measurementUnitId',
  apiMethod: 'api/reference-data/measurement-unit',
  dataListName: 'measurementUnitData',
  name: 'title',
};

export const typesOfSupportData = {
  apiMethod: 'api/reference-data/types-support-joint',
  dataListName: 'typesSupportJointDataList',
  idName: 'typesSupportJointId',
  pageHeaderTitle: 'Type of support',
  tabNameField: 'title',
  columnsName: 'TypesOfSupportTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOfSupportProps = {
  idName: 'typesSupportJointId',
  apiMethod: 'api/reference-data/types-support-joint',
  dataListName: 'typesSupportJointData',
  name: 'title',
};

export const weldingLocationsData = {
  apiMethod: 'api/join-welding-location',
  dataListName: 'joinWeldingLocationDataList',
  idName: 'jointWeldingLocationId',
  pageHeaderTitle: 'Welding locations',
  tabNameField: 'name',
  columnsName: 'WeldingLocationsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'name',
  popupList: getPopupList,
};

export const onceWeldingLocationProps = {
  idName: 'jointWeldingLocationId',
  apiMethod: 'api/join-welding-location',
  dataListName: 'joinWeldingLocationData',
  name: 'name',
};

export const weldingMethodsData = {
  apiMethod: 'api/welding-method',
  dataListName: 'weldingMethodDataList',
  idName: 'weldingMethodId',
  pageHeaderTitle: 'Welding method',
  tabNameField: 'name',
  columnsName: 'WeldingMethodsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'name',
  popupList: getPopupList,
};

export const weldingMethodsAgccData = { ...weldingMethodsData, idName: 'id' };

export const onceWeldingMethodsProps = {
  idName: 'weldingMethodId',
  apiMethod: 'api/welding-method',
  dataListName: 'weldingMethodData',
  name: 'name',
};

export const pipelineMaterialsData = {
  apiMethod: 'api/pipeline-material',
  dataListName: 'pipelineMaterialDataList',
  idName: 'pipelineMaterialId',
  pageHeaderTitle: 'Pipeline materials',
  tabNameField: 'name',
  columnsName: 'PipelineMaterialsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'name',
  popupList: getPopupList,
};

export const oncePipelineMaterialsProps = {
  idName: 'pipelineMaterialId',
  apiMethod: 'api/pipeline-material',
  dataListName: 'pipelineMaterialData',
  name: 'name',
};

export const pipelineCategoryData = {
  apiMethod: 'api/reference-data/piping-category',
  dataListName: 'pipingCategoryDataList',
  idName: 'pipingCategoryId',
  pageHeaderTitle: 'Pipeline category',
  tabNameField: 'name',
  columnsName: 'PipelineCategoryTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
  // @ts-ignore
  addComponent: <AddPipingCategory name={'name'} />,
  editComponent: null,
};

export const oncePipelineCategoryProps = {
  idName: 'pipelineCategoryId',
  apiMethod: 'api/reference-data/piping-category',
  dataListName: 'pipingCategoryData',
  name: 'title',
};

export const inspectorsData: DictionaryData = {
  apiMethod: 'api/qc-inspector',
  dataListName: 'qcInspectorDataList',
  idName: 'qcInspectorId',
  pageHeaderTitle: 'QC inspectors',
  tabNameField: 'surname',
  columnsName: 'InspectorsTable',
  searchFields: getSearchFieldsInspectors,
  columns: getInitialColumnsInspectors,
  pageHeaderExtra: getPageHeaderExtraQc,
  name: 'name',
  popupList: getPopupList,
  // @ts-ignore
  addComponent: <AddInspector />,
  editComponent: <EditInspector idName={'qcInspectorId'} />,
};

export const temperatureData = {
  apiMethod: '/api/temperature',
  dataListName: 'temperatureDataList',
  idName: 'temperatureId',
  pageHeaderTitle: 'Температура',
  tabNameField: 'name',
  columnsName: 'TemperatureTable',
  searchFields: getSearchFieldsTemperature,
  columns: getInitialColumnsTemperature,
  name: 'title',
  popupList: getPopupList,
  addComponent: <TemperatureForm type={'add'} />,
  editComponent: <TemperatureForm type={'edit'} />,
};

export const onceTemperatureProps = {
  idName: 'temperature',
  apiMethod: 'api/temperature',
  dataListName: 'temperatureData',
  name: 'date',
};

export const pwhtOperatorData = {
  apiMethod: '/api/pwht-operator',
  dataListName: 'pwhtOperatorDataList',
  idName: 'pwhtOperatorId',
  pageHeaderTitle: 'PWHT operators',
  tabNameField: 'full_name',
  columnsName: 'PwhtOperatorsTable',
  searchFields: getSearchFieldsPwhtOperator,
  columns: getInitialColumnsPwhtOperator,
  name: 'title',
  popupList: getPopupList,
  addComponent: (
    <FormPWHTOperator type={'add'} dataListName={'pwhtOperatorDataList'} />
  ),
  editComponent: <FormPWHTOperator type={'edit'} />,
};

export const installationsData = {
  apiMethod: 'api/installations',
  dataListName: 'installationsDataList',
  idName: 'installationsId',
  pageHeaderTitle: 'Installations',
  tabNameField: 'title',
  columnsName: 'InstallationsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceInstallationsProps = {
  idName: 'installationsId',
  apiMethod: 'api/installations',
  dataListName: 'installationsData',
  name: 'title',
};

export const typeOfLaboratories = {
  apiMethod: 'api/reference-data/labs-control-types',
  dataListName: 'labsControlTypesDataList',
  idName: 'labControlTypeId',
  pageHeaderTitle: 'Types of laboratory controls',
  tabNameField: 'title',
  columnsName: 'TypesOfLaboratoriesTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOfLaboratoriesProps = {
  idName: 'labControlTypeId',
  apiMethod: 'api/reference-data/labs-control-types',
  dataListName: 'labsControlTypesData',
  name: 'title',
};

export const ewMarks = {
  apiMethod: 'api/reference-data/ew-marks',
  dataListName: 'marksDataList',
  idName: 'markId',
  pageHeaderTitle: 'Marks',
  tabNameField: 'title',
  columnsName: 'EwMarksTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceEwMarksProps = {
  idName: 'markId',
  apiMethod: 'api/reference-data/ew-marks',
  dataListName: 'markData',
  name: 'title',
};

export const ewFolderStatuses = {
  apiMethod: 'api/reference-data/ew-folders-status',
  dataListName: 'foldersStatusDataList',
  idName: 'folderStatusId',
  pageHeaderTitle: 'Folder statuses',
  tabNameField: 'title',
  columnsName: 'EwFolderStatusesTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsFolderStatuses,
  name: 'title',
  popupList: getPopupList,
  editComponent: <FoldersStatusForm type={'edit'} />,
  addComponent: <FoldersStatusForm type={'add'} />,
};

export const onceEwFolderStatusesProps = {
  idName: 'folderStatusId',
  apiMethod: 'api/reference-data/ew-folders-status',
  dataListName: 'folderStatusData',
  name: 'title',
  formFields: formFieldsFolderStatuses,
};

export const ewKQ = {
  apiMethod: 'api/reference-data/ewkq',
  dataListName: 'ewKqDataList',
  idName: 'ewKqId',
  pageHeaderTitle: 'KQ',
  tabNameField: 'title',
  columnsName: 'KQTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceKqProps = {
  idName: 'ewKqId',
  apiMethod: 'api/reference-data/ewkq',
  dataListName: 'ewKqData',
  name: 'title',
};

export const weldingPositionDescriptions = {
  apiMethod: 'api/welding-position',
  dataListName: 'weldingPositionDataList',
  idName: 'weldingPositionId',
  pageHeaderTitle: 'Welding position',
  tabNameField: 'name',
  columnsName: 'WeldingPositionTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'name',
  popupList: getPopupList,
};

export const onceWeldingPositionProps = {
  idName: 'weldingPositionId',
  apiMethod: 'api/welding-position',
  dataListName: 'weldingPositionData',
  name: 'name',
};

export const ewFolderLocation = {
  apiMethod: 'api/reference-data/folder-location',
  dataListName: 'folderLocationDataList',
  idName: 'folderLocationId',
  pageHeaderTitle: 'Folders location',
  tabNameField: 'title',
  columnsName: 'EwFolderLocationTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsFolderStatuses,
  name: 'title',
  popupList: getPopupList,
  formFields: formFieldsFolderStatuses,
};

export const onceFolderLocationProps = {
  idName: 'folderLocationId',
  apiMethod: 'api/reference-data/folder-location',
  dataListName: 'folderLocation',
  name: 'title',
  descriptions: descriptionFolderLocation,
  formFields: formFieldsFolderStatuses,
};

export const onceWelderOnJointStatusProps = {
  idName: 'welderJointStatusId',
  apiMethod: 'api/welder-joint-status',
  dataListName: 'welderJointStatusData',
  name: 'name',
  descriptions: descriptionWeldersOnJointStatus,
  editComponent: <FormWelderOnJointStatus type="edit" />,
};

export const typesLaboratoryDescriptions = {
  apiMethod: 'api/reference-data/lab-control-kind',
  dataListName: 'labControlKindDataList',
  idName: 'labControlKindId',
  pageHeaderTitle: 'Types of laboratory',
  tabNameField: 'title',
  columnsName: 'TypesOfLaboratoryTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOFLaboratoryProps = {
  idName: 'labControlKindId',
  apiMethod: 'api/reference-data/lab-control-kind',
  dataListName: 'labControlKindData',
  name: 'title',
};

export const blockFactorsDescriptions = {
  apiMethod: 'api/reference-data/ew-folders-block-factors-reference',
  dataListName: 'ewFoldersBlockFactorsReferenceDataList',
  idName: 'ewFoldersBlockFactorReferenceId',
  pageHeaderTitle: 'Block factors',
  tabNameField: 'title',
  columnsName: 'BlockFactorsTable',
  searchFields: getSearchFields,
  columns: getInitialColumnsBlockFactors,
  name: 'title',
  popupList: getPopupList,
  addComponent: <FormBlockFactor type={'add'} />,
  editComponent: <FormBlockFactor type={'edit'} />,
};

export const onceBlockFactorsProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/ew-folders-block-factors-reference',
  dataListName: 'ewFoldersBlockFactorsReferenceData',
  name: 'title',
  descriptions: descriptionBlockFactorRef,
  editComponent: <FormBlockFactor type="edit" />,
};

export const typeOfWorkRFIData = {
  apiMethod: 'api/reference-data/types-of-works',
  dataListName: 'typesOfWorksDataList',
  idName: 'id',
  pageHeaderTitle: 'Types of work',
  tabNameField: 'title',
  columnsName: 'TypesOfWorkRFITable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOfWorkRfiProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/types-of-works',
  dataListName: 'typesOfWorksData',
  name: 'title',
};

export const typesOfQualityControlRFIData = {
  apiMethod: 'api/reference-data/types-of-quality-control',
  dataListName: 'typesOfQualityControlDataList',
  idName: 'id',
  pageHeaderTitle: 'Types and methods of control',
  tabNameField: 'title',
  columnsName: 'TypesOfQualityControlRFITable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOfQualityControlRfiProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/types-of-quality-control',
  dataListName: 'typesOfQualityControlData',
  name: 'title',
};

export const typesOfControlRFIData = {
  apiMethod: 'api/reference-data/types-of-control',
  dataListName: 'typesOfControlDataList',
  idName: 'id',
  pageHeaderTitle: 'Methods of control',
  tabNameField: 'title',
  columnsName: 'TypesOfQualityControlRFITable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceTypesOfControlRFIProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/types-of-control',
  dataListName: 'typesOfControlData',
  name: 'title',
};

export const scopeOfControlRFIData = {
  apiMethod: 'api/reference-data/scope-of-control',
  dataListName: 'scopeOfControlDataList',
  idName: 'id',
  pageHeaderTitle: 'Scope of control',
  tabNameField: 'title',
  columnsName: 'ScopeOfQualityControlRFITable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceScopeOfControlRFIProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/scope-of-control',
  dataListName: 'scopeOfControlData',
  name: 'title',
};

export const resultsRFIData = {
  apiMethod: 'api/reference-data/rfi-result',
  dataListName: 'rfiResultDataList',
  idName: 'id',
  pageHeaderTitle: 'RFI results',
  tabNameField: 'title',
  columnsName: 'RFIResultsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceResultsRFIProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/rfi-result',
  dataListName: 'rfiResultData',
  name: 'title',
};

export const rfiKindOfControlData = {
  apiMethod: 'api/reference-data/kind-control',
  dataListName: 'kindControlDataList',
  idName: 'id',
  pageHeaderTitle: 'Kinds of control',
  tabNameField: 'title',
  columnsName: 'KindIfControlRfiTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceKindOfControlRFIProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/kind-control',
  dataListName: 'kindControlData',
  name: 'title',
};

export const marksData = {
  apiMethod: 'api/reference-data/marks',
  dataListName: 'marksDataList',
  idName: 'id',
  pageHeaderTitle: 'Marks',
  tabNameField: 'title',
  columnsName: 'marksTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceMarksProps = {
  idName: 'id',
  apiMethod: 'api/reference-data/marks',
  dataListName: 'marksData',
  name: 'title',
};

export const reportTypeOfWorksData = {
  apiMethod: 'api/reference-data/reports-type-of-works',
  dataListName: 'reportsTypeOfWorksDataList',
  idName: 'reportsTypeOfWorksId',
  pageHeaderTitle: 'Type of works',
  tabNameField: 'title',
  columnsName: 'ReportTypeOfWorksTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
};

export const onceReportTypeOfWorksProps = {
  idName: 'reportsTypeOfWorksId',
  apiMethod: 'api/reference-data/reports-type-of-works',
  dataListName: 'reportsTypeOfWorksData',
  name: 'title',
};

export const constructionDirectionData = {
  apiMethod: 'api/reference-data/construction-direction',
  dataListName: 'constructionDirectionDataList',
  idName: 'constructionDirectionId',
  pageHeaderTitle: 'Construction directions',
  tabNameField: 'title',
  columnsName: 'ConstructionDirectionsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: 'title',
  popupList: getPopupList,
  addComponent: <ConstructionDirectionForm type={'add'} />,
  editComponent: <ConstructionDirectionForm type={'edit'} />,
  width: '400px',
};

export const onceConstructionDirectionProps = {
  idName: 'constructionDirectionId',
  apiMethod: 'api/reference-data/construction-direction',
  dataListName: 'constructionDirectionData',
  name: 'title',
};

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/sub-discipline-of-work';
const name = 'title';

export const subDisciplineOfWorkData: DictionaryData = {
  apiMethod: api,
  dataListName: 'subDisciplineOfWorkList',
  idName: idName,
  pageHeaderTitle: 'Sub disciplines',
  tabNameField: name,
  columnsName: 'SubDisciplineTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
};

export const onceSubDisciplineProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'subDisciplineOfWork',
  name: name,
};

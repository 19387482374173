import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

export const getInitialColumnsBlockFactors = (
  t,
  name = 'title'
): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: name,
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
  {
    title: t('Organization'),
    dataIndex: ['organizationDepartmentData', 'title'],
    key: 'organizationDepartmentData',
    align: 'center',
    visible: true,
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

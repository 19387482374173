import { useDispatch } from 'react-redux';
import { commonApi } from '../../../store/commonApi';
import { TagDescription } from '@reduxjs/toolkit/query';

export const useInvalidateAbstractData = () => {
  const dispatch = useDispatch();

  return (api: string, id?: string | number | string[] | number[]) => {
    const key = api.replace('api/', '');

    const arrayTags: TagDescription<'AbstractData' | 'SearchParams'>[] = [
      {
        type: 'AbstractData',
        id: key,
      },
    ];
    if (id) {
      if (Array.isArray(id)) {
        arrayTags.push(
          ...id.map((currentId) => ({
            type: 'AbstractData' as const,
            id: `${key}/${currentId}`,
          }))
        );
      } else
        arrayTags.push({
          type: 'AbstractData',
          id: `${key}/${id}`,
        });
    }
    dispatch(commonApi.util.invalidateTags(arrayTags));
  };
};

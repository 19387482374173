import { ResponseData } from '../../utils/API/APITypes';
import { TypesOfLaboratoryControl } from '../../components/Admin/Dictionaries/TypesOfLaboratories';

const SET_DATA_TYPES_OF_LABORATORIES = 'SET_DATA_TYPES_OF_LABORATORIES';
const SET_CONTENT_TYPES_OF_LABORATORIES = 'SET_CONTENT_TYPES_OF_LABORATORIES';

export declare type DataTypesOfLaboratories = {
  success: boolean;
  labsControlTypesDataList: ResponseData<TypesOfLaboratoryControl>;
};

export declare type TypesOfLaboratoriesReducer = {
  data: DataTypesOfLaboratories | null;
  content: Array<TypesOfLaboratoryControl> | null;
};

/**
 * @param {Array<any>} data
 * @return {any}
 */
export function setContentTypeOfLaboratories(data) {
  return {
    type: SET_CONTENT_TYPES_OF_LABORATORIES,
    payload: data,
  };
}

const initialState: TypesOfLaboratoriesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function typesOfLaboratoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TYPES_OF_LABORATORIES:
      return { ...state, data: action.payload };
    case SET_CONTENT_TYPES_OF_LABORATORIES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

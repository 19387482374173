import { useGetNotificationQuery } from '../../../../store/yambookApi';
import { useRef } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

const POOLING_INTERVAL = 120000;

export const useGetNotificationCount = () => {
  const errorRef = useRef<FetchBaseQueryError | SerializedError>(null);
  const { data, error } = useGetNotificationQuery(undefined, {
    // @ts-ignore
    skipPollingIfUnfocused: true,
    pollingInterval:
      errorRef.current &&
      'status' in errorRef.current &&
      errorRef.current?.status === 400
        ? 0
        : POOLING_INTERVAL,
  });

  // @ts-ignore
  errorRef.current = error;

  return (
    data?.filter((notification) => notification.type === 'comment').length ?? 0
  );
};

import { DictionaryData } from '../../../Admin/Dictionaries/DictionariesTypes';
import { getInitialColumns } from './TitleListExtra';
import { getPopupList } from '../AoRPI/AoRPIExtra';
import { TitleListData } from './TitleListData';

const idName = TitleListData.idName;
const api = TitleListData.apiMethod;
const name = 'number';

export const titleListDictionaryData: DictionaryData = {
  apiMethod: api,
  dataListName: TitleListData.dataListName,
  idName: idName,
  pageHeaderTitle: 'Title',
  tabNameField: name,
  columnsName: 'Title',
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

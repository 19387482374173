import React from 'react';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { NewFormProps } from '../../../../../../AppTypes';
import { CommentRfiStatus } from '../type';

const CommentRfiFormAgcc = React.memo(
  ({
    type,
    onFinishAdd,
    onFinishEdit,
    currentRow,
    isFetching,
  }: NewFormProps<CommentRfiStatus>) => {
    const { t } = useTranslation();

    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!(values)
        : onFinishEdit!({
            ...values,
            id: currentRow?.id,
          });
    };

    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={
          currentRow
            ? {
                ...currentRow,
                ewRfiStatusId: currentRow?.rfiResult?.id,
              }
            : undefined
        }
      >
        <Form.Item
          label={t('Comment')}
          name={'title'}
          rules={[{ required: true, message: '' }]}
        >
          <TextArea autoSize={{ maxRows: 3 }} />
        </Form.Item>
        <Form.Item label={t('Description')} name={'description'}>
          <TextArea autoSize={{ maxRows: 3 }} />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
CommentRfiFormAgcc.displayName = 'CommentRfiFormAgcc';
export default CommentRfiFormAgcc;

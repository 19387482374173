import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import { NewFormProps } from '../../../../AppTypes';
import { BlockFactors } from '../../../../store/reducers/BlockFactors';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store/rootReducer';
import { organizationDepartmentData } from '../../Departments/DepartamentsData';
import { getDepartments } from '../../../../utils/RequestDictionaries/getDepartments';

const FormBlockFactor = React.memo(
  ({
    currentRow,
    onFinishEdit,
    type,
    isFetching,
    onFinishAdd,
  }: NewFormProps<BlockFactors>) => {
    const { t } = useTranslation();
    const record = type === 'edit' ? currentRow : null;
    const [title, setTitle] = useState(record?.title ?? null);
    const [description, setDescription] = useState(record?.description ?? null);
    const [organizationDepartmentId, setOrganizationDepartmentId] = useState(
      record?.organizationDepartmentData.organizationDepartmentId ?? null
    );
    const [loading, setLoading] = useState(false);
    const departments =
      useAppSelector((state) => state.departmentsReducer.content) ?? [];

    useEffect(() => {
      if (!departments.length) {
        setLoading(true);
        getDepartments()
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      }
    }, []);

    const optionList = useMemo(
      () =>
        departments.map((dep: organizationDepartmentData) =>
          Object.create({
            label: dep.title,
            value: dep.organizationDepartmentId,
          })
        ),
      [departments]
    );

    const onFinish = (values) => {
      if (type === 'edit')
        values.ewFoldersBlockFactorReferenceId =
          record?.ewFoldersBlockFactorReferenceId ?? record?.id;
      type === 'edit' ? onFinishEdit!(values) : onFinishAdd!(values);
    };

    return (
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          fields={[
            { name: 'title', value: title },
            {
              name: 'organizationDepartmentId',
              value: organizationDepartmentId,
            },
            { name: 'description', value: description },
          ]}
        >
          <Form.Item
            key="title"
            label={t('Title')}
            name="title"
            rules={[{ required: true, message: '' }]}
          >
            <Input onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
          <Form.Item
            key="organizationDepartmentId"
            label={t('Department')}
            name="organizationDepartmentId"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              options={optionList}
              onChange={setOrganizationDepartmentId}
            />
          </Form.Item>
          <Form.Item
            key="description"
            label={t('Description')}
            name="description"
          >
            <Input onChange={(e) => setDescription(e.target.value)} />
          </Form.Item>
          <Form.Item key="button">
            <Button type="primary" htmlType="submit" loading={isFetching}>
              {t('Save')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
);
FormBlockFactor.displayName = 'FormBlockFactor';
export default FormBlockFactor;

import axios, { AxiosError } from 'axios';
import { notification } from 'antd';
import { t } from 'i18next';
import { KeycloakApp } from '../Keycloak/Keycloak';
import { TIMEOUT_TOKEN } from '../Base/constants/TIMEOUT_TOKEN';

declare module 'axios' {
  export interface AxiosRequestConfig {
    notNotification?: boolean;
  }
}

const API = axios.create();

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    if (KeycloakApp?.isTokenExpired?.(TIMEOUT_TOKEN)) {
      await KeycloakApp.updateToken(TIMEOUT_TOKEN);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
API.interceptors.request.use(
  async function (config) {
    const AUTH_TOKEN = KeycloakApp?.token;
    const PROJECT = localStorage.getItem('project');
    if (KeycloakApp?.isTokenExpired?.(TIMEOUT_TOKEN)) {
      await KeycloakApp.updateToken(TIMEOUT_TOKEN);
    }
    config.headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    };
    config.baseURL =
      PROJECT === 'agpp'
        ? process.env.REACT_APP_PRODUCTION_URL
        : process.env.REACT_APP_PRODUCTION_URL_AGCC;
    return config;
  },
  async function (error) {
    if (error.response && error.response.data.statusCode === 401) {
      await KeycloakApp.updateToken(300);
    }
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    if (
      response.config.method === 'put' ||
      response.config.method === 'post' ||
      response.config.method === 'delete'
    ) {
      if (!response.config.notNotification) {
        notification.success({
          message: response.data.message ?? t('Success'),
        });
      }
    }
    return response;
  },
  async function (error: AxiosError<{ statusCode: number; message: string }>) {
    if (error?.response?.status === 401) {
      KeycloakApp.login();
    }

    const defaultMessage = {
      message: error.response?.data.message,
    };

    if (error.response && !error.response.config.notNotification) {
      notification.error(defaultMessage);
    }

    return Promise.reject(error);
  }
);

export default API;

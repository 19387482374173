import { UnitsOfMeasureType } from '../../components/Admin/Dictionaries/UnitsOfMeasureData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_UNITSOFMEASURE = 'SET_DATA_UNITSOFMEASURE';
const SET_CONTENT_UNITSOFMEASURE = 'SET_CONTENT_UNITSOFMEASURE';

export declare type dataUnitsOfMeasure = {
  success: boolean;
  measurementUnitDataList: ResponseData<UnitsOfMeasureType>;
};

export declare type UnitsOfMeasureReducer = {
  data: dataUnitsOfMeasure | null;
  content: Array<UnitsOfMeasureType> | null;
};

/**
 * @param {dataUnitsOfMeasure} data
 * @return {any}
 */
export function setDataUnitsOfMeasure(data) {
  return {
    type: SET_DATA_UNITSOFMEASURE,
    payload: data,
  };
}

/**
 * @param {Array<UnitsOfMeasureType>} data
 * @return {any}
 */
export function setContentUnitsOfMeasure(data) {
  return {
    type: SET_CONTENT_UNITSOFMEASURE,
    payload: data,
  };
}

const initialState: UnitsOfMeasureReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function unitsofmeasureReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_UNITSOFMEASURE:
      return { ...state, data: action.payload };
    case SET_CONTENT_UNITSOFMEASURE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

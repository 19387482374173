import { organizationTypeData } from '../../components/Admin/TypeOfOrganizations/TypeOfOrganizationsData';

import { OrganizationsReducer } from './OrganizationsReducer';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_ORGANIZATIONTYPE = 'SET_DATA_ORGANIZATIONTYPE';
const SET_CONTENT_ORGANIZATIONTYPE = 'SET_CONTENT_ORGANIZATIONTYPE';

export declare type dataOrganizationtype = {
  success: boolean;
  organizationTypeDataList: ResponseData<organizationTypeData>;
};

export declare type OrganizationTypeReducer = {
  data: dataOrganizationtype | null;
  content: Array<organizationTypeData> | null;
};

/**
 * @param {dataOrganizationtype} data
 * @return {any}
 */
export function setDataOrganizationType(data) {
  return {
    type: SET_DATA_ORGANIZATIONTYPE,
    payload: data,
  };
}

/**
 * @param {Array<organizationTypeData>} data
 * @return {any}
 */
export function setContentOrganizationType(data) {
  return {
    type: SET_CONTENT_ORGANIZATIONTYPE,
    payload: data,
  };
}

const initialState: OrganizationsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function organizationtypeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ORGANIZATIONTYPE:
      return { ...state, data: action.payload };
    case SET_CONTENT_ORGANIZATIONTYPE:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

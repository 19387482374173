import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

export const getInitialColumnsFolderStatuses = (t): AbstractColumns => [
  {
    title: t('Title'),
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    visible: true,
    sorter: true,
  },
  {
    title: t('Status duration'),
    dataIndex: 'processTime',
    key: 'processTime',
    align: 'center',
    visible: true,
    sorter: true,
  },
  {
    title: t('Folder status status'),
    dataIndex: ['statusOfFolderStatuses', 'title'],
    key: 'statusOfFolderStatuses',
    align: 'center',
    visible: true,
    sorter: true,
  },
  {
    title: t('Description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    visible: true,
    sorter: true,
  },
];

export const formFieldsFolderStatuses = (t) => {
  return [
    {
      label: t('Status duration'),
      name: 'processTime',
      component: 'inputNumber',
    },
  ];
};

import { ResponseData } from '../../utils/API/APITypes';
import { EwFolder } from '../../components/Workplace/AGPP/Electrican/Folders/FoldersTypes';
import { LogContent } from '../../components/Workplace/AGPP/WDB/TypesWDB';

const SET_DATA_FOLDERS = 'SET_DATA_FOLDERS';
const SET_CONTENT_FOLDERS = 'SET_CONTENT_FOLDERS';
const SET_CONTENT_FOLDERS_HISTORY = 'SET_CONTENT_FOLDERS_HISTORY';
const SET_LOGS_FOLDERS_HISTORY = 'SET_LOGS_FOLDERS_HISTORY';
const SET_UPDATE_FOLDERS_HISTORY = 'SET_UPDATE_FOLDERS_HISTORY';

export declare type DataFolders = {
  success: boolean;
  DataList: ResponseData<EwFolder>;
};

export declare type FoldersReducer = {
  data: DataFolders | null;
  content: Array<EwFolder> | null;
  foldersLogsDataList: any;
  contentLog: Array<LogContent>;
  update: boolean;
};

/**
 * @param {DataFolders} data
 * @return {any}
 */
export function setDataFolders(data) {
  return {
    type: SET_DATA_FOLDERS,
    payload: data,
  };
}

/**
 * @param {DataFolders} data
 * @return {any}
 */
export function setContentFoldersHistory(data) {
  return {
    type: SET_CONTENT_FOLDERS_HISTORY,
    payload: data,
  };
}

/**
 * @param {DataFolders} data
 * @return {any}
 */
export function setLogsFoldersHistory(data) {
  return {
    type: SET_LOGS_FOLDERS_HISTORY,
    payload: data,
  };
}

/**
 * @param {DataFolders} data
 * @return {any}
 */
export function setUpdateFoldersHistory(data) {
  return {
    type: SET_UPDATE_FOLDERS_HISTORY,
    payload: data,
  };
}

/**
 * @param {Array<DataFolders>} data
 * @return {any}
 */
export function setContentFolders(data) {
  return {
    type: SET_CONTENT_FOLDERS,
    payload: data,
  };
}

const initialState: FoldersReducer = {
  data: null,
  content: null,
  foldersLogsDataList: {},
  contentLog: [],
  update: false,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function foldersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_FOLDERS:
      return { ...state, data: action.payload };
    case SET_CONTENT_FOLDERS:
      return { ...state, content: action.payload };
    case SET_CONTENT_FOLDERS_HISTORY:
      return { ...state, contentLog: action.payload };
    case SET_LOGS_FOLDERS_HISTORY:
      return { ...state, foldersLogsDataList: action.payload };
    case SET_UPDATE_FOLDERS_HISTORY:
      return { ...state, update: action.payload };
    default:
      return state;
  }
}

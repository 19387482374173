import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_RFI_RESULTS = 'SET_DATA_RFI_RESULTS';
const SET_CONTENT_RFI_RESULTS = 'SET_CONTENT_RFI_RESULTS';

export declare type DataRFIResults = {
  success: boolean;
  rfiResultDataList: ResponseData<Dictionary>;
};

export declare type RFIResultsReducer = {
  data: DataRFIResults | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {Array<Dictionary>} data
 * @return {any}
 */
export function setContentRFIResults(data) {
  return {
    type: SET_CONTENT_RFI_RESULTS,
    payload: data,
  };
}

const initialState: RFIResultsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function rfiResultsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_RFI_RESULTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_RFI_RESULTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

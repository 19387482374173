import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_TEST_PACKET_STATUS = 'SET_DATA_TEST_PACKET_STATUS';
const SET_CONTENT_TEST_PACKET_STATUS = 'SET_CONTENT_TEST_PACKET_STATUS';

export declare type TestPacketStatusType = {
  testPacketStatusId: number;
  title: string;
  description: null | string;
};

export declare type DataTestPacketStatus = {
  success: boolean;
  testPacketStatusesDataList: ResponseData<TestPacketStatusType>;
};

export declare type TestPacketStatusReducer = {
  data: DataTestPacketStatus | null;
  content: Array<TestPacketStatusType> | null;
};

/**
 * @param {Array<DataTestPacketStatus>} data
 * @return {any}
 */
export function setContentTestPacketStatus(data) {
  return {
    type: SET_CONTENT_TEST_PACKET_STATUS,
    payload: data,
  };
}

const initialState: TestPacketStatusReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function testPacketStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TEST_PACKET_STATUS:
      return { ...state, data: action.payload };
    case SET_CONTENT_TEST_PACKET_STATUS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

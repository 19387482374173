import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KeycloakApp } from '../utils/Keycloak/Keycloak';
import { TIMEOUT_TOKEN } from '../utils/Base/constants/TIMEOUT_TOKEN';

const uri =
  localStorage.getItem('project') === 'agcc'
    ? process.env.REACT_APP_PRODUCTION_URL_AGCC
    : process.env.REACT_APP_PRODUCTION_URL;

// Define a service using a base URL and expected endpoints
export const commonApi = createApi({
  reducerPath: 'commonApi',
  tagTypes: ['AbstractData', 'SearchParams', 'BasePhaseGroup'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${uri}/api`,
    prepareHeaders: async (headers) => {
      const keycloak = KeycloakApp;
      if (keycloak?.isTokenExpired?.(TIMEOUT_TOKEN)) {
        await keycloak.updateToken(TIMEOUT_TOKEN);
      }
      headers.set('Authorization', 'Bearer ' + keycloak.token);
    },
  }),
  endpoints: (_) => ({}),
  keepUnusedDataFor: 300,
});

import { Tag } from 'antd';
import React from 'react';
import {
  BasePhaseDescriptionList,
  DescriptionsList,
} from '../Components/AbstractElement/AbstractElementTypes';
import { t } from 'i18next';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { renderDate } from '../Base/helpers/renderDate';

const getWelderTag = (item, id) => {
  const welder = item?.welderForJointDataSet?.find(
    (welder) => welder.welderJointStatusData.welderJointStatusId === id
  )?.welderData;
  if (welder) {
    return (
      <>
        <Tag>{welder.stamp}</Tag>
        {welder.surname + ' ' + welder.name}
      </>
    );
  } else {
    // @ts-ignore
    return <Tag color="red">{t('No data')}</Tag>;
  }
};

export const lineDescriptions: DescriptionsList = [
  {
    label: 'Line number',
    fieldName: 'number',
    span: 4,
  },
  {
    label: 'Work environment code',
    fieldName: 'workingEnvironmentCode',
    span: 4,
  },
  {
    label: 'Environment',
    fieldName: 'usage',
    span: 4,
  },
  {
    label: 'Pipeline class',
    fieldName: 'pipelineClass',
    span: 4,
  },
  {
    label: 'Piping category TP TC',
    fieldName: 'pipingCategoryTr',
    span: 4,
  },
  {
    label: 'Max working pressure',
    fieldName: 'maxOperatingPressure',
    span: 4,
  },
  {
    label: 'Max working temperature',
    fieldName: 'maxOperatingTemperature',
    span: 4,
  },
  {
    label: 'Min calculation pressure',
    fieldName: 'minCalculatedPressure',
    span: 4,
  },
  {
    label: 'Max calculation pressure',
    fieldName: 'maxCalculatedPressure',
    span: 4,
  },
  {
    label: 'Min calculating temperature',
    fieldName: 'minCalculatedTemperature',
    span: 4,
  },
  {
    label: 'Max calculating temperature',
    fieldName: 'maxCalculatedTemperature',
    span: 4,
  },
  {
    label: 'Pipeline category',
    fieldName: 'pipingCategory',
    span: 4,
    render: (item) => {
      if (item) {
        return <span>{item.title}</span>;
      } else {
        return <Tag color="red">{`${t('Absent')}`}</Tag>;
      }
    },
  },
  {
    label: 'Pressure test for strength and density',
    fieldName: 'pressureStrengthAndDensityTests',
    span: 4,
  },
  {
    label: 'Pneumatic test pressure',
    fieldName: 'pressurePneumaticTests',
    span: 4,
  },
  {
    label: 'Radiographic/Ultrasonic inspection',
    fieldName: 'xrayUltrasonicControl',
    span: 4,
  },
  {
    label: 'Capillary inspection',
    fieldName: 'pvkControl',
    span: 4,
  },
  {
    label: 'pipelineName',
    fieldName: 'pipelineName',
    span: 4,
  },
  {
    label: 'hazardClass',
    fieldName: 'hazardClass',
    span: 4,
  },
  {
    label: 'fireHazard',
    fieldName: 'fireHazard',
    span: 4,
    render: (item) => (
      // @ts-ignore
      <Tag>{item ? `${t('Yes')}` : `${t('No')}`}</Tag>
    ),
  },

  {
    label: 'Positive material identification',
    fieldName: 'pmiControl',
    span: 4,
    render: (item) => (
      // @ts-ignore
      <Tag>{item ? `${t('Yes')}` : `${t('No')}`}</Tag>
    ),
  },
  {
    label: 'Hardness test',
    fieldName: 'hardnessControl',
    span: 4,
    render: (item) => (
      // @ts-ignore
      <Tag>{item ? `${t('Yes')}` : `${t('No')}`}</Tag>
    ),
  },
];

export const titleDescriptions: DescriptionsList = [
  {
    label: 'Name_1',
    fieldName: 'titleName',
    span: 12,
  },
  {
    label: 'Operational complex',
    fieldName: 'operationalComplex',
    span: 12,
  },
  {
    label: 'Launch complex',
    fieldName: 'launchComplex',
    span: 12,
  },
  {
    label: 'Title object',
    fieldName: 'titleObject',
    span: 12,
  },
];

export const testPackDescriptions: DescriptionsList = [
  {
    label: 'Number',
    fieldName: 'number',
    span: 4,
  },
  {
    label: 'Short number',
    fieldName: 'shortNumber',
    span: 4,
  },
  {
    label: 'Archive number',
    fieldName: 'archiveNumber',
    span: 4,
  },
  {
    label: 'Formation status',
    fieldName: 'isFormed',
    span: 4,
    render: (item) => (
      <Tag>{item ? `${t('Formed')}` : `${t('Not formed')}`}</Tag>
    ),
  },
  {
    label: 'ГГН',
    fieldName: 'isGGN',
    span: 4,
    render: (item) => (
      <Tag>{item ? `${t('With signer')}` : `${t('Without signer')}`}</Tag>
    ),
  },
  {
    label: 'Test type',
    fieldName: 'testType',
    span: 4,
    render: (item) => {
      switch (item) {
        case 'hydraulic':
          return 'Hydraulic';
        case 'visual':
          return 'Visual';
        case 'pneumatic':
          return 'Pneumo';
        default:
          return 'Unknown type';
      }
    },
  },
  {
    label: 'Test pressure',
    fieldName: 'testPressure',
    span: 4,
    render: (item) => {
      return item ? item : <Tag color="red">{`${t('No data')}`}</Tag>;
    },
  },
  {
    label: 'Welding percentage',
    fieldName: 'weldingPercentage',
    span: 4,
    render: (item) => {
      return item ? item : <Tag color="red">{`${t('No data')}`}</Tag>;
    },
  },
  {
    label: 'Flushing (purging) act date',
    fieldName: 'flushingActDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Installation certificate date',
    fieldName: 'installationCertificateDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Test report date',
    fieldName: 'testReportDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Date of approval for the right to test',
    fieldName: 'rightToTestApprovalDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Visual inspection act date',
    fieldName: 'visualInspectionActDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Test permit date temperature',
    fieldName: 'testPermitDateTemperature',
    span: 4,
    render: (item) => {
      return item ? item : <Tag color="red">{`${t('No data')}`}</Tag>;
    },
  },
  {
    label: 'Test report date temperature',
    fieldName: 'testReportDateTemperature',
    span: 4,
    render: (item) => {
      return item ? item : <Tag color="red">{`${t('No data')}`}</Tag>;
    },
  },
  {
    label: 'Comment',
    fieldName: 'commentOnBlockFactor',
    span: 4,
    render: (item, data) => {
      return item && data?.testPacketStatus?.testPacketStatusId === 4 ? (
        item
      ) : (
        <Tag color="red">{`${t('No data')}`}</Tag>
      );
    },
  },
  {
    label: 'Certificate 7.2.1',
    fieldName: 'certificate721',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Certificate 7.2.2',
    fieldName: 'certificate722',
    span: 4,
    render: renderDate,
  },
];

export const isoDescriptions: DescriptionsList = [
  {
    label: 'Number',
    fieldName: 'number',
    span: 12,
  },
  {
    label: 'Document number',
    fieldName: 'documentNumber',
    span: 12,
  },
  {
    label: 'Document title',
    fieldName: 'documentTitle',
    span: 12,
  },
];

export const consumableDescriptions: DescriptionsList = [
  {
    label: 'aorpiNumber',
    fieldName: 'aorpiNumber',
    span: 4,
  },
  {
    label: 'aorpiDate',
    fieldName: 'aorpiDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'Phases',
    fieldName: 'phases',
    span: 4,
    render: (item) => item?.join(', '),
  },
  {
    label: 'elementName',
    fieldName: 'elementName',
    span: 4,
  },

  {
    label: 'typeRus',
    fieldName: 'typeRus',
    span: 4,
  },
  {
    label: 'typeEng',
    fieldName: 'typeEng',
    span: 4,
  },
  {
    label: 'parameters',
    fieldName: 'parameters',
    span: 4,
  },
  {
    label: 'aorpiQuantity',
    fieldName: 'aorpiQuantity',
    span: 4,
  },
  {
    label: 'unit',
    fieldName: 'measurementUnitData.title',
    span: 4,
  },
  {
    label: 'literOfOneBank',
    fieldName: 'literOfOneBank',
    span: 4,
  },
  {
    label: 'litresOfAllBanks',
    fieldName: 'litresOfAllBanks',
    span: 4,
  },
  {
    label: 'steelType',
    fieldName: 'steelType',
    span: 4,
  },
  {
    label: 'batchNumber',
    fieldName: 'batchNumber',
    span: 4,
  },
  {
    label: 'elementNumber',
    fieldName: 'elementNumber',
    span: 4,
  },
  {
    label: 'certificate',
    fieldName: 'certificate',
    span: 4,
  },
  {
    label: 'certificateDate',
    fieldName: 'certificateDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'naks',
    fieldName: 'naks',
    span: 4,
  },
  {
    label: 'naksDate',
    fieldName: 'naksDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'rfiNumber',
    fieldName: 'rfiNumber',
    span: 4,
  },
  {
    label: 'manufacturer',
    fieldName: 'manufacturer',
    span: 4,
  },
  {
    label: 'gost',
    fieldName: 'gost',
    span: 4,
  },
];

export const aorpiDescription: DescriptionsList = [
  {
    label: 'Number',
    fieldName: 'number',
    span: 4,
  },
  {
    label: 'aorpiDate',
    fieldName: 'aorpiDate',
    span: 4,
    render: renderDate,
  },
  {
    label: 'aorpiTotalQuantity',
    fieldName: 'aorpiTotalQuantity',
    span: 4,
  },
  {
    label: 'signed',
    fieldName: 'signed',
    span: 4,
    render: (item) => {
      return item ? `${t('signed')}` : `${t('notSigned')}`;
    },
  },
  {
    label: 'phases',
    fieldName: 'productReportPhaseDataList',
    span: 4,
    render: (item) => {
      if (item.length === 0) return `${t('No data')}`;
      const phases = sortBy(item, [
        function (element) {
          return element.phaseNumber;
        },
      ]);
      return phases.map((phase: any, index) => {
        return <Tag key={index + phase.phaseNumber}>{phase.phaseNumber}</Tag>;
      });
    },
  },
];

export const productReportDescriptions: DescriptionsList = [
  {
    label: 'aorpiNumber',
    fieldName: 'productReportData',
    render: (item) => {
      return item.number;
    },
    span: 6,
  },
  {
    label: 'aorpiDate',
    fieldName: 'productReportData',
    render: (item) => {
      return dayjs(item.aorpiDate).format('DD.MM.YYYY');
    },
    span: 6,
  },
  {
    label: 'Certificate number',
    fieldName: 'certificateNumber',
    span: 6,
  },
  {
    label: 'Certificate date',
    fieldName: 'certificateDate',
    render: (item) => {
      return dayjs(item).format('DD.MM.YYYY');
    },
    span: 6,
  },
  {
    label: 'ItemCode',
    fieldName: 'elementItemCode',
    span: 6,
  },
  {
    label: 'Unit of measure',
    fieldName: 'measurementUnitData',
    render: (item) => {
      return item.description;
    },
    span: 6,
  },
  {
    label: 'Quantity',
    fieldName: 'elementQuantity',
    span: 6,
  },
  {
    label: 'Residue',
    fieldName: 'elementResidue',
    span: 6,
  },
  {
    label: 'Fuse and factory number',
    fieldName: 'elementFuseFactoryNumber',
    span: 6,
  },
  {
    label: 'elementName',
    fieldName: 'title',
    span: 6,
  },
  {
    label: 'Short name',
    fieldName: 'elementShortTitle',
    span: 6,
  },
  {
    label: 'Element type',
    fieldName: 'elementTypeData',
    render: (item) => {
      return item.title;
    },
    span: 6,
  },
  {
    label: 'Isometry number',
    fieldName: 'isoListData',
    render: (item) => {
      return item ? item.number : '';
    },
    span: 6,
  },
  {
    label: 'Element documentation',
    fieldName: 'elementDocumentation',
    span: 6,
  },
  {
    label: 'Material documentation',
    fieldName: 'materialDocumentation',
    span: 12,
  },
  {
    label: 'First diameter (inch)',
    fieldName: 'firstInsideDiameter',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'First diameter (mm)/Support length',
    fieldName: 'firstOutsideDiameter',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'Second diameter (inch)/Bolt length',
    fieldName: 'secondInsideDiameter',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'Second diameter (mm)',
    fieldName: 'secondOutsideDiameter',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'First thickness (USA)',
    fieldName: 'firstWallThicknessUSA',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'First thickness (mm)/Support thickness',
    fieldName: 'firstWallThicknessRUS',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'Second thickness (USA)',
    fieldName: 'secondWallThicknessUSA',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
  {
    label: 'Second thickness (mm)',
    fieldName: 'secondWallThicknessRUS',
    render: (item) => {
      return item ? item : '-';
    },
    span: 6,
  },
];

export const welderDescriptions: DescriptionsList = [
  {
    label: 'Surname',
    fieldName: 'surname',
    span: 8,
  },
  {
    label: 'Name',
    fieldName: 'name',
    span: 8,
  },
  {
    label: 'Patronymic',
    fieldName: 'patronymic',
    span: 8,
  },
  {
    label: 'Stamp',
    fieldName: 'stamp',
    span: 8,
  },
  {
    label: 'Welder class',
    fieldName: 'welderClass',
    span: 8,
  },
  {
    label: 'Status',
    fieldName: 'isWorking',
    render: (item) => {
      return item ? t('Working') : t('Fired');
    },
    span: 8,
  },
  {
    label: 'Work permit',
    fieldName: 'isSuspended',
    render: (item) => {
      if (item === undefined || item === null) {
        return '-';
      } else {
        return item ? t('Suspended') : t('Permitted to work');
      }
    },
    span: 8,
  },
  {
    label: 'Suspended from',
    fieldName: 'suspendedFrom',
    render: (item) => {
      return item ? dayjs(item).format('DD.MM.YYYY') : '-';
    },
    span: 8,
  },
  {
    label: 'Suspended to',
    fieldName: 'suspendedTo',
    render: (item) => {
      return item ? dayjs(item).format('DD.MM.YYYY') : '-';
    },
    span: 8,
  },
];

export const basePhaseDescriptions: BasePhaseDescriptionList = (innerName) => {
  const descriptions = [
    {
      label: 'Isometry number',
      fieldName: 'isoList.number',
      span: 6,
    },
    {
      label: 'Position number',
      fieldName: 'position',
      span: 6,
    },
    {
      label: 'Item Code',
      fieldName: 'itemCode',
      span: 6,
    },
    {
      label: 'Element quantity',
      fieldName: 'quantity',
      span: 6,
    },
    {
      label: 'Unit of measure',
      fieldName: 'units.title',
      span: 6,
    },
    {
      label: 'Element name',
      fieldName: 'elementName',
      span: 6,
    },
    {
      label: 'Material documentation',
      fieldName: 'documentationForMaterial',
      span: 6,
    },
    {
      label: 'Element type',
      fieldName: 'elementType.title',
      span: 6,
    },
    {
      label: 'Nominal pressure',
      fieldName: 'pn',
      span: 6,
    },
    {
      label: 'Nominal diameter',
      fieldName: 'dn',
      span: 6,
    },
    {
      label: 'Element welded',
      fieldName: 'isWelding',
      span: 6,
      render: (item) => {
        return item ? t('Welded') : t('Not welded');
      },
    },
    {
      label: 'Welding date',
      fieldName: 'weldingDate',
      span: 6,
      render: (item) => {
        return item ? dayjs(item).format('DD.MM.YYYY') : '-';
      },
    },
    {
      label: 'First diameter (inch)',
      fieldName: 'firstInch',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'First diameter (mm)/Support length',
      fieldName: 'firstOutsideDiameter',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'Second diameter (inch)/Bolt length',
      fieldName: 'secondInch',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'Second diameter (mm)',
      fieldName: 'secondOutsideDiameter',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'First thickness (USA)',
      fieldName: 'firstWallThicknessUsa',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'First thickness (mm)/Support thickness',
      fieldName: 'firstWallThicknessRus',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'Second thickness (USA)',
      fieldName: 'secondWallThicknessUsa',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'Second thickness (mm)',
      fieldName: 'secondWallThicknessRus',
      render: (item) => {
        return item ? item : '-';
      },
      span: 6,
    },
    {
      label: 'Length',
      fieldName: 'length',
      render: (item) => {
        return item ? `${item} м.` : '-';
      },
      span: 6,
    },
  ];
  return innerName
    ? descriptions.map((item) => {
        return { ...item, fieldName: `${innerName}.${item.fieldName}` };
      })
    : descriptions;
};
export const specificationDescriptions: DescriptionsList = [
  {
    label: 'Isometry number',
    fieldName: 'isoListBondData.isoListData.number',
    span: 12,
  },
  {
    label: 'Line',
    fieldName: 'isoListBondData.lineListData.category',
    span: 12,
  },
  {
    label: 'Test Packet',
    fieldName: 'isoListBondData.testPacketData.shortNumber',
    span: 12,
  },
  {
    label: 'Title',
    fieldName: 'isoListBondData.titleListData.number',
    span: 12,
  },
];

export const wdbDescription: DescriptionsList = [
  {
    label: 'Zone number',
    fieldName: 'zoneNumber',
    span: 6,
  },
  {
    label: 'Title',
    fieldName: 'titleListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Line',
    fieldName: 'lineListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Isometry number',
    fieldName: 'isoListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Test Pack',
    fieldName: 'testPacketData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Number',
    fieldName: 'number',
    span: 6,
  },
  {
    label: 'Welding location',
    fieldName: 'jointWeldingLocationData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Joint type',
    fieldName: 'jointTypeData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Welding method',
    fieldName: 'weldingMethodDataSet',
    span: 6,
    render: (item) => {
      let methods = '';
      for (let i = 0; i < item.length; i++) {
        if (i === 0) {
          methods += item[i]?.name;
        } else {
          methods += ' + ' + item[i]?.name;
        }
      }
      return methods;
    },
  },
  {
    label: 'Pipeline material',
    fieldName: 'pipelineMaterialData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Spool',
    fieldName: 'spool',
    span: 6,
  },
  {
    label: 'Joint diameter',
    fieldName: 'jointDiameter',
    span: 6,
  },
  {
    label: 'First element',
    fieldName: 'firstElement',
    span: 6,
    render: (item) => {
      if (item && item?.elementName) {
        return item.elementName;
      } else {
        return <Tag color="red">{`${t('No data')}`}</Tag>;
      }
    },
  },
  {
    label: 'Second element',
    fieldName: 'secondElement',
    span: 6,
    render: (item) => {
      if (item && item?.elementName) {
        return item.elementName;
      } else {
        return <Tag color="red">{`${t('No data')}`}</Tag>;
      }
    },
  },
  {
    label: 'QC accept',
    fieldName: 'qcAccept',
    span: 6,
    render: (item) => {
      return item ? (
        <CheckCircleFilled style={{ color: 'green', fontSize: 'large' }} />
      ) : (
        <CloseCircleFilled style={{ color: 'red', fontSize: 'large' }} />
      );
    },
  },
  {
    label: 'Rejection reason',
    fieldName: 'rejectionReason',
    span: 6,
    render: (item) => (item ? item : ''),
  },
];

export const wlgDescription: DescriptionsList = [
  {
    label: 'Zone number',
    fieldName: 'zoneNumber',
    span: 6,
  },
  {
    label: 'Title',
    fieldName: 'titleListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Line',
    fieldName: 'lineListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Isometry number',
    fieldName: 'isoListData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Test Pack',
    fieldName: 'testPacketData',
    span: 6,
    render: (item) => (item?.number ? item.number : '-'),
  },
  {
    label: 'Number',
    fieldName: 'number',
    span: 6,
  },
  {
    label: 'Welding location',
    fieldName: 'jointWeldingLocationData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Joint type',
    fieldName: 'jointTypeData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Welding method',
    fieldName: 'weldingMethodDataSet',
    span: 6,
    render: (item) => {
      let methods = '';
      for (let i = 0; i < item.length; i++) {
        if (i === 0) {
          methods += item[i]?.name;
        } else {
          methods += ' + ' + item[i]?.name;
        }
      }
      return methods;
    },
  },
  {
    label: 'Pipeline material',
    fieldName: 'pipelineMaterialData',
    span: 6,
    render: (item) => (item?.name ? item.name : '-'),
  },
  {
    label: 'Spool',
    fieldName: 'spool',
    span: 6,
  },
  {
    label: 'Joint diameter',
    fieldName: 'jointDiameter',
    span: 6,
  },
  {
    label: 'First element',
    fieldName: 'firstElement',
    span: 6,
    render: (item) => (item?.elementName ? item.elementName : '-'),
  },
  {
    label: 'Second element',
    fieldName: 'secondElement',
    span: 6,
    render: (item) => (item?.elementName ? item.elementName : '-'),
  },
];

export const jointDescription: DescriptionsList = [
  {
    label: 'Title',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      return item?.titleListData?.number ? item.titleListData.number : '-';
    },
  },
  {
    label: 'Line',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      return item?.lineListData?.number ? item.lineListData.number : '-';
    },
  },
  {
    label: 'Isometry number',
    fieldName: 'wdbData',
    span: 6,
    render: (item) => {
      return item?.isoListData?.number ? item.isoListData.number : '-';
    },
  },
  {
    label: 'Test Pack',
    fieldName: 'wdbData',
    span: 6,
    render: (item) => {
      return item?.testPacketData?.number ? item.testPacketData.number : '-';
    },
  },
  {
    label: 'Welding date',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      if (item?.weldingDate) {
        return dayjs(item.weldingDate).format('DD.MM.YYYY');
      } else {
        // @ts-ignore
        return <Tag color="red">{t('No data')}</Tag>;
      }
    },
  },
  {
    label: 'Welding location',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      return item?.jointWeldingLocationData?.name;
    },
  },
  {
    label: 'Number',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      return item?.number;
    },
  },
  {
    label: 'Spool',
    fieldName: 'wdbData',
    span: 4,
    render: (item) => {
      return item?.spool;
    },
  },
  {
    label: 'Joint type',
    fieldName: 'wdbData',
    span: 6,
    render: (item) => {
      return item?.jointTypeData?.name;
    },
  },
  {
    label: 'Pipeline material',
    fieldName: 'wdbData',
    span: 6,
    render: (item) => {
      return item?.pipelineMaterialData?.name;
    },
  },
  {
    label: `First welder`,
    fieldName: 'wdbData',
    span: 6,
    render: (item) => getWelderTag(item, 1),
  },
  {
    label: `Second welder`,
    fieldName: 'wdbData',
    span: 6,
    render: (item) => getWelderTag(item, 2),
  },
  {
    label: `Third welder`,
    fieldName: 'wdbData',
    span: 6,
    render: (item) => getWelderTag(item, 3),
  },
  {
    label: `Fourth welder`,
    fieldName: 'wdbData',
    span: 6,
    render: (item) => getWelderTag(item, 4),
  },
  {
    label: 'First element',
    fieldName: 'wdbData',
    span: 12,
    render: (item) => {
      if (item?.firstElement) {
        return (
          <>
            <Tag>{item.firstElement?.itemCode}</Tag>
            {item.firstElement?.elementName}
          </>
        );
      } else {
        // @ts-ignore
        return <Tag color="red">{t('No data')}</Tag>;
      }
    },
  },
  {
    label: 'Second element',
    fieldName: 'wdbData',
    span: 12,
    render: (item) => {
      if (item?.secondElement) {
        return (
          <>
            <Tag>{item.secondElement?.itemCode}</Tag>
            {item.secondElement?.elementName}
          </>
        );
      } else {
        // @ts-ignore
        return <Tag color="red">{t('No data')}</Tag>;
      }
    },
  },
  {
    label: 'VIC',
    fieldName: 'vicDate',
    span: 6,
    render: (item, record) => {
      let result: any = null;
      switch (record?.vicResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.vicReport}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'PVK',
    fieldName: 'penetrationDate',
    span: 6,
    render: (item, record) => {
      let result: any = null;
      switch (record?.penetrationResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.penetrationReport}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'PMI',
    fieldName: 'pmiDate',
    span: 6,
    render: (item, record) => {
      let result: any = null;
      switch (record?.pmiResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.pmiReport}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'RK',
    fieldName: 'xrayDate',
    span: 6,
    render: (item, record) => {
      let result: any = null;
      switch (record?.xrayResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.xrayReport}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'Ultrasonic',
    fieldName: 'ultrasonicDate',
    span: 8,
    render: (item, record) => {
      let result: any = null;
      switch (record?.ultrasonicResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.ultrasonicReport}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'PWHT',
    fieldName: 'pwhtDate',
    span: 8,
    render: (item, record) => {
      let result: any = null;
      switch (record?.pwhtResult) {
        case 'A':
          result = <Tag color="green">A</Tag>;
          break;
        case 'R':
          result = <Tag color="yellow">R</Tag>;
          break;
        case 'C':
          result = <Tag color="red">C</Tag>;
          break;
        default:
          break;
      }

      if (!item) {
        return '-';
      } else {
        return (
          <div>
            {t('Operator stamp')}:
            <Tag style={{ marginLeft: '5px' }}>{record?.pwhtOperatorStamp}</Tag>
            {t('Diagram number')}:
            <Tag style={{ marginLeft: '5px' }}>{record?.pwhtDiagramNumber}</Tag>
            {result}
            {item}
          </div>
        );
      }
    },
  },
  {
    label: 'HT',
    fieldName: 'htDate',
    span: 8,
    render: (item, record) => {
      if (!item) {
        return '-';
      } else {
        return (
          <div>
            <Tag>{record?.htReport}</Tag>
            {item}
          </div>
        );
      }
    },
  },
];

export const descriptionsPassport = [
  {
    label: 'Number',
    fieldName: 'number',
    span: 6,
  },
  {
    label: 'Actual number',
    fieldName: 'actualNumber',
    span: 6,
  },
  {
    label: 'Installation',
    fieldName: 'installationData.title',
    span: 6,
  },
  {
    label: 'Pipeline purpose',
    fieldName: 'pipelinePurpose',
    span: 6,
  },
  {
    label: 'Conformity declarations',
    fieldName: 'conformityDeclarations',
    span: 6,
  },
  {
    label: 'Number of technical query',
    fieldName: 'numberOfTechnicalQuery',
    span: 6,
    render: (item) => item || '-',
  },
  {
    label: 'Additional leak tests',
    fieldName: 'additionalLeakTests',
    span: 6,
    render: (item) => (
      <Tag color={item ? 'green' : 'red'}>{item ? 'Да' : 'Нет'}</Tag>
    ),
  },
];

export const descriptionWeldersOnJointStatus = [
  {
    label: 'Name',
    fieldName: 'name',
    span: 1,
  },
  {
    label: 'Is system',
    fieldName: 'isSystem',
    span: 1,
    render: (item) => <Tag>{item ? 'Да' : 'Нет'}</Tag>,
  },
];

export const descriptionBlockFactorRef = [
  {
    label: 'Title_1',
    fieldName: 'title',
    span: 1,
  },
  {
    label: 'Department',
    fieldName: 'organizationDepartmentData',
    span: 1,
    render: (item) => item.title,
  },
  {
    label: 'Description',
    fieldName: 'description',
    span: 1,
  },
];

export const descriptionFolderLocation = [
  {
    label: 'Title_1',
    fieldName: 'title',
    span: 1,
  },
  {
    label: 'Status duration',
    fieldName: 'processTime',
    span: 1,
  },
  {
    label: 'Description',
    fieldName: 'description',
    span: 1,
  },
];

export const descriptionElementTypeAgccLocation = [
  {
    label: 'Title_1',
    fieldName: 'title',
    span: 1,
  },
  {
    label: 'Specification table number',
    fieldName: 'specificationTableNumber',
    span: 1,
  },
  {
    label: 'Description',
    fieldName: 'description',
    span: 1,
  },
];

import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setActiveKey } from '../../../store/reducers/WorkplaceTabs';
import { useDispatch } from 'react-redux';

const NotFoundPage = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t!('Sorry, the page you visited does not exist')}
      extra={
        <Button
          type="primary"
          onClick={() => {
            dispatch(setActiveKey('/'));
            navigate(`/`);
          }}
        >
          {t!('Back Home')}
        </Button>
      }
    />
  );
});
NotFoundPage.displayName = 'NotFoundPage';
export default NotFoundPage;

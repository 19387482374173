import { Card, Spin, Tabs, Tag, Typography } from 'antd';
import React from 'react';
import {
  useGetNotificationQuery,
  useGetSupportTaskQuery,
} from '../../../../../store/yambookApi';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store/rootReducer';
import RequestToSupportTabChildren from './RequestToSupportTabChildren';

const RequestToSupport = () => {
  const { profile } = useAppSelector((state) => state.authReducer);
  const { data, isLoading } = useGetSupportTaskQuery(profile?.keyCloakId!, {
    pollingInterval: 60000,
    // @ts-ignore
    skipPollingIfUnfocused: true,
  });
  const { t } = useTranslation();
  const { data: notificationList } = useGetNotificationQuery(1);

  const getNewMessage = (taskId: number) => {
    return notificationList?.filter(
      (notification) =>
        notification.type === 'comment' && notification.taskId === taskId
    );
  };

  const COLUMN_STATUSES = {
    COMPLETE: { color: 'green', title: t('Complete') },
    IN_PROGRESS: { color: 'blue', title: t('In work') },
    TO_DO: { color: undefined, title: t('Created') },
  };

  const height = 'calc(100vh - 294px)';

  return (
    <Spin spinning={isLoading}>
      <Tabs
        tabPosition={'left'}
        destroyInactiveTabPane
        style={{ height }}
        items={data?.map((task) => {
          const { color, title } = COLUMN_STATUSES[task.column.status];
          const newMessage = getNewMessage(task.id);
          const countMessage = newMessage?.length ?? 0;
          return {
            label: (
              <Card
                title={
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    #{task.serialNumber} {task.title}
                  </div>
                }
                extra={<Tag color={color}>{title}</Tag>}
                size={'small'}
                bordered={false}
                style={{
                  width: 300,
                  textAlign: 'left',
                }}
              >
                {countMessage ? (
                  <Typography.Text italic>
                    {t('New messages')}: {countMessage}
                  </Typography.Text>
                ) : (
                  ''
                )}
              </Card>
            ),
            key: task.id,
            children: (
              <RequestToSupportTabChildren
                task={task}
                newMessage={newMessage}
              />
            ),
          };
        })}
      />
    </Spin>
  );
};

export default RequestToSupport;

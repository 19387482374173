import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/reference-data/working-categories';
const name = 'title';

export const workingCategoryData: DictionaryData = {
  apiMethod: api,
  dataListName: 'workingCategoriesDataList',
  idName: idName,
  pageHeaderTitle: 'Types of work',
  tabNameField: name,
  columnsName: 'WorkingCategoryTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceWorkingCategoryProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'workingCategoriesData',
  name: name,
};

import React, { useEffect, useRef } from 'react';
import { Button, Card, Col, Flex, Form, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import {
  useSendCommentMutation,
  useViewNotificationMutation,
} from '../../../../../store/yambookApi';
import { useAppSelector } from '../../../../../store/rootReducer';
import dayjs from 'dayjs';
import { FormFiles } from './FormFiles';

const StartDate = ({ createDate }: { createDate: dayjs.Dayjs }) => {
  return (
    <Typography.Paragraph italic style={{ textAlign: 'center' }}>
      {createDate.format('DD MMMM YYYY')}
    </Typography.Paragraph>
  );
};

const RequestToSupportTabChildren = React.memo(
  ({
    task,
    newMessage,
  }: {
    newMessage: any;
    task: {
      fileList: any[];
      comments: any[];
      description: string;
      authorId: number;
      id: number;
    };
  }) => {
    const [form] = useForm();
    const height = 'calc(100vh - 294px)';
    const { t } = useTranslation();
    const [onMutate, { isLoading }] = useSendCommentMutation();
    const { profile, darkTheme } = useAppSelector((state) => state.authReducer);
    const [viewTask] = useViewNotificationMutation();

    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [isLoading]);

    const onInitTab = () => {
      if (newMessage?.length) {
        viewTask({
          body: { isView: true, idList: newMessage.map((el) => el.id) },
        });
      }
    };

    useEffect(() => {
      onInitTab();
    }, []);

    const onFinish = (value) => {
      onMutate({
        body: {
          ...value,
        },
        keycloakId: profile.keyCloakId!,
      });
      form.resetFields();
    };

    const getDescription = (text: string) => {
      const regex = new RegExp(
        `Сообщение\\n-+\\n?([\\s\\S]*?)\\n\\nАвтор\\n-+\\n?`,
        'i'
      );
      const match = text.match(regex);
      return match ? match[1] : null;
    };

    return (
      <Row>
        <Col span={14}>
          <Flex
            vertical
            justify={'space-between'}
            style={{ height, maxWidth: 800 }}
          >
            <div
              className={darkTheme ? 'scroll-design dark' : 'scroll-design'}
              style={{ overflowY: 'auto', paddingRight: 4 }}
            >
              <Typography.Paragraph type={'secondary'} italic>
                {t('Description')}: {getDescription(task.description)}
              </Typography.Paragraph>
              {task.comments.map((comment, index) => {
                const createDate = dayjs(comment.creationTime);
                const previousCreateDate =
                  index > 0
                    ? dayjs(task.comments[index - 1].creationTime)
                    : undefined;
                return (
                  <>
                    {previousCreateDate ? (
                      createDate.get('date') !==
                        previousCreateDate.get('date') && (
                        <StartDate createDate={createDate} />
                      )
                    ) : (
                      <StartDate createDate={createDate} />
                    )}
                    <Flex
                      justify={
                        comment.authorId === task.authorId ? 'end' : 'start'
                      }
                      key={comment.id}
                      ref={
                        index === task.comments.length - 1
                          ? endOfMessagesRef
                          : undefined
                      }
                    >
                      <Card style={{ marginBottom: 16 }} size={'small'}>
                        <Typography.Paragraph style={{ margin: 0 }}>
                          {comment.text}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                          type={'secondary'}
                          style={{
                            margin: 0,
                            fontSize: '0.85em',
                            textAlign: 'right',
                          }}
                        >
                          {createDate.format('HH:mm')}
                        </Typography.Paragraph>
                      </Card>
                    </Flex>
                  </>
                );
              })}
            </div>
            <Form
              form={form}
              onFinish={(values) =>
                onFinish({
                  ...values,
                  taskId: task.id,
                  authorId: task.authorId,
                })
              }
            >
              <Row gutter={8}>
                <Col span={22}>
                  <Form.Item name={'text'}>
                    <TextArea
                      autoSize={{ maxRows: 4, minRows: 1 }}
                      placeholder={t('Message')}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    loading={isLoading}
                    htmlType={'submit'}
                  >
                    {t('Submit')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Flex>
        </Col>
        <Col span={8}>
          <Typography.Title style={{ margin: '4px 0 0' }} level={5}>
            Вложения:
          </Typography.Title>
          <FormFiles task={task} />
        </Col>
      </Row>
    );
  }
);
RequestToSupportTabChildren.displayName = 'RequestToSupportTabChildren';
export default RequestToSupportTabChildren;

import React, { useState } from 'react';
import { Button, Card, Empty, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../utils/Base/helpers/getUserData';
import { setActiveKey } from '../../store/reducers/WorkplaceTabs';
import { useDispatch } from 'react-redux';
import { setIsFetchAccount } from '../../store/reducers/AuthReducer';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';
import { initSocket } from '../../utils/Socket/Socket';

const Enter = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onClick = (project: 'agcc' | 'agpp') => {
    localStorage.setItem('project', project);
    setLoading(true);
    getUserData(KeycloakApp.subject!)
      .then(() => {
        if (project === 'agpp') {
          initSocket();
        }
        dispatch(setActiveKey('/'));
        navigate('/');
      })
      .catch((error) => {
        dispatch(setIsFetchAccount(false));
        if (
          error?.response?.data.message ===
          'Пользователь по запрошенному идентификатору не найден!'
        ) {
          navigate(`/registry`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return KeycloakApp ? (
    <Spin spinning={loading}>
      <Row align="middle" className="authorization">
        <Card
          title={t('Project selection')}
          bordered
          style={{ width: 450 }}
          extra={[
            <img
              alt={'logo'}
              key={'yamataLogo'}
              src={'https://ssas.weldnet.ru/img/yamataLogo.png'}
              height={'40px'}
            />,
          ]}
        >
          <div>
            {KeycloakApp?.hasRealmRole('realm-role-agpp') ||
            KeycloakApp?.hasRealmRole('realm-role-agcc') ? (
              <Space>
                {KeycloakApp?.hasRealmRole('realm-role-agpp') && (
                  <Button onClick={() => onClick('agpp')} size={'large'}>
                    {t('AGPP')}
                  </Button>
                )}
                {KeycloakApp?.hasRealmRole('realm-role-agcc') && (
                  <Button onClick={() => onClick('agcc')} size={'large'}>
                    {t('AGCC')}
                  </Button>
                )}
              </Space>
            ) : (
              <Empty
                description={`${t(
                  "You don't have any projects available."
                )} ${t('To gain access, contact higher management')}`}
              />
            )}
          </div>
          <Row justify={'end'} style={{ marginTop: 8 }}>
            <Button onClick={() => KeycloakApp?.logout()}>
              {t('Log out')}
            </Button>
          </Row>
        </Card>
      </Row>
    </Spin>
  ) : (
    <></>
  );
});
Enter.displayName = 'Login';
export default Enter;

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { AddInspectorState } from '../DictionariesTypes';
import { AddFormProps } from '../../../../AppTypes';

/**
 * Добавление инспектора
 */
class AddInspector extends React.Component<AddFormProps, AddInspectorState> {
  /**
   * @return {JSX}
   */
  render() {
    const { t, isFetching } = this.props;

    return (
      <Form layout="vertical" onFinish={this.props.onFinishAdd}>
        <Form.Item
          key="surname"
          label={t('Surname')}
          name="surname"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="name"
          label={t('Name')}
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item key="patronymic" label={t('Patronymic')} name="patronymic">
          <Input />
        </Form.Item>

        <Form.Item key="button">
          <Button type="primary" htmlType="submit" disabled={isFetching}>
            {t('Create')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(AddInspector);

import React, { useEffect, useRef } from 'react';
import { Form, Input, InputRef } from 'antd';
import { ExplorerViewProps } from '../ExplorerType';
import { useForm } from 'antd/es/form/Form';
import { REQUIRED_RULES } from '../../../Base/constants/requiredRule';

const FormDirectory = (
  props: Pick<
    ExplorerViewProps,
    'onFinishCreateFolder' | 'changeDirectory' | 'directoryPath'
  > & { record: any }
) => {
  const { onFinishCreateFolder, changeDirectory, directoryPath, record } =
    props;
  const inputRef = useRef<InputRef | null>(null);
  const [form] = useForm();

  const onBlurEditInput = (type: string, name: string) => {
    const currentPath = directoryPath?.replace('null', '') ?? '';
    return type === 'new folder'
      ? (v) => onFinishCreateFolder(v.value)
      : (v) =>
          changeDirectory(
            {
              oldPath: `${currentPath}/${name}`,
              newPath: `${currentPath}/${v.value.trim()}`,
            },
            'directory'
          );
  };
  useEffect(() => {
    inputRef.current?.focus({ cursor: 'all' });
  });

  return (
    <Form
      form={form}
      onFinish={onBlurEditInput(record.contentType, record.name)}
    >
      <Form.Item
        noStyle
        name="value"
        label=""
        initialValue={record.name}
        rules={REQUIRED_RULES}
      >
        <Input ref={inputRef} onBlur={form.submit} />
      </Form.Item>
    </Form>
  );
};

export default FormDirectory;

import { WeldingMethodsType } from '../../components/Admin/Dictionaries/WeldingMethodsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_WELDINGMETHODS = 'SET_DATA_WELDINGMETHODS';
const SET_CONTENT_WELDINGMETHODS = 'SET_CONTENT_WELDINGMETHODS';

export declare type dataWeldingMethods = {
  success: boolean;
  weldingMethodDataList: ResponseData<WeldingMethodsType>;
};

export declare type WeldingMethodsReducer = {
  data: dataWeldingMethods | null;
  content: Array<WeldingMethodsType> | null;
};

/**
 * @param {Array<WeldingMethodsType>} data
 * @return {any}
 */
export function setContentWeldingMethods(data) {
  return {
    type: SET_CONTENT_WELDINGMETHODS,
    payload: data,
  };
}

const initialState: WeldingMethodsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function weldingmethodsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDINGMETHODS:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDINGMETHODS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

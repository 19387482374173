import { combineReducers } from 'redux';
import { authReducer } from './reducers/AuthReducer';
import { wdbReducer } from './reducers/WDBReducer';
import { linelistReducer } from './reducers/LineListReducer';
import { isolistReducer } from './reducers/IsoListReducer';
import { testpacksReducer } from './reducers/TestPacksReducer';
import { titlelistReducer } from './reducers/TitleListReducer';
import { weldersReducer } from './reducers/WeldersReducer';
import { basephaseReducer } from './reducers/BasePhaseReducer';
import { weldlogReducer } from './reducers/WeldlogReducer';
import { wlgReducer } from './reducers/WLGReducer';
import { aorpiReducer } from './reducers/AoRPIReducer';
import { consumablesReducer } from './reducers/ConsumablesReducer';
import { elementtypesReducer } from './reducers/ElementTypesReducer';
import { unitsofmeasureReducer } from './reducers/UnitsOfMeasureReducer';
import { weldinglocationsReducer } from './reducers/WeldingLocationsReducer';
import { weldingmethodsReducer } from './reducers/WeldingMethodsReducer';
import { jointTypesReducer } from './reducers/JointTypes';
import { pipelinematerialsReducer } from './reducers/PipelineMaterialsReducer';
import { inspectorsReducer } from './reducers/InspectorsReducer';
import { positionsReducer } from './reducers/PositionsReducer';
import { departmentsReducer } from './reducers/DepartmentsReducer';
import { organizationsReducer } from './reducers/OrganizationsReducer';
import { organizationtypeReducer } from './reducers/OrganizationTypeReducer';
import { productreportelementReducer } from './reducers/ProductReportElementReducer';
import { accountsReducer } from './reducers/AccountsReducer';
import { specificationsReducer } from './reducers/SpecificationsReducer';
import { ndtReducer } from './reducers/NDTReducer';
import { passportsReducer } from './reducers/PassportsReducer';
import { welderOnJointStatusReducer } from './reducers/WelderOnJointStatusReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import store from './store';
import { testPacketAccountStatusReducer } from './reducers/TestPacketAccountStatusesReducer';
import { testPacketStatusReducer } from './reducers/TestPacketStatusReducer';
import { typesOfLaboratoriesReducer } from './reducers/TypesOfLaboratoriesReducer';
import { laboratoriesReducer } from './reducers/Laboratories';
import { ewFolderStatusesReducer } from './reducers/EwFolderStatuses';
import { titleMarkReducer } from './reducers/TitleMark';
import { kqReducer } from './reducers/KQ';
import { ewBasePhaseReducer } from './reducers/EwBasePhase';
import { weldingPositionReducer } from './reducers/WeldingPosition';
import { rfiReducer } from './reducers/Rfi';
import { folderLocationReducer } from './reducers/FolderLocation';
import { updatesReducer } from './reducers/Updates';
import { labTypesReducer } from './reducers/LabTypes';
import { foldersReducer } from './reducers/Folders';
import { scopeOfControlRFIReducer } from './reducers/ScopeOfControlRFI';
import { typesOfWorkRFIReducer } from './reducers/TypesOfWorkRFI';
import { kindControlRfiReducer } from './reducers/KindControlRfi';
import { responsibleRFIReducer } from './reducers/ResponsibleRFI';
import { rfiResultsReducer } from './reducers/RFIResults';
import { responsibleAorpiReducer } from './reducers/ResponsibleAorpi';
import { typesOfSupportReducer } from './reducers/TypesOfSupportReducer';
import { metalStructuresProductReportElementReducer } from './reducers/MetalStructuresProductReportElement';
import { inputControlJournalReducer } from './reducers/InputControlJournal';
import { workingTypesBondReducer } from './reducers/WorkingTypesBond';
import { workingAmountReducer } from './reducers/WorkingAmount';
import { abstractClassDataReducer } from './reducers/AbstractClassData';
import { ewStatisticReducer } from './reducers/EwStatistic';
import { commonApi } from './commonApi';
import workplaceTabsReducer from './reducers/WorkplaceTabs';
import abstractColumnsSlice from './reducers/AbstractColumnsSlice';
import { yambookApi } from './yambookApi';
import { formationApi } from "./formationApi";

const rootReducer = combineReducers({
  workplaceTabsReducer,
  abstractClassDataReducer,
  authReducer: authReducer,
  abstractColumnsSlice,
  wdbReducer: wdbReducer,
  linelistReducer: linelistReducer,
  isolistReducer: isolistReducer,
  testpacksReducer: testpacksReducer,
  titlelistReducer: titlelistReducer,
  weldersReducer: weldersReducer,
  basephaseReducer: basephaseReducer,
  weldlogReducer: weldlogReducer,
  wlgReducer: wlgReducer,
  aorpiReducer: aorpiReducer,
  consumablesReducer: consumablesReducer,
  elementtypesReducer: elementtypesReducer,
  unitsofmeasureReducer: unitsofmeasureReducer,
  weldinglocationsReducer: weldinglocationsReducer,
  weldingmethodsReducer: weldingmethodsReducer,
  jointTypesReducer: jointTypesReducer,
  pipelinematerialsReducer: pipelinematerialsReducer,
  inspectorsReducer: inspectorsReducer,
  positionsReducer: positionsReducer,
  departmentsReducer: departmentsReducer,
  organizationsReducer: organizationsReducer,
  organizationtypeReducer: organizationtypeReducer,
  productreportelementReducer: productreportelementReducer,
  accountsReducer: accountsReducer,
  specificationReducer: specificationsReducer,
  ndtReducer: ndtReducer,
  passportsReducer: passportsReducer,
  welderOnJointStatusReducer: welderOnJointStatusReducer,
  testPacketStatusReducer: testPacketStatusReducer,
  testPacketAccountStatusReducer: testPacketAccountStatusReducer,
  typesOfLaboratoriesReducer: typesOfLaboratoriesReducer,
  laboratoriesReducer: laboratoriesReducer,
  ewFolderStatusesReducer: ewFolderStatusesReducer,
  titleMarkReducer: titleMarkReducer,
  kqReducer: kqReducer,
  ewBasePhaseReducer: ewBasePhaseReducer,
  weldingPositionReducer: weldingPositionReducer,
  rfiReducer: rfiReducer,
  folderLocationReducer: folderLocationReducer,
  updatesReducer: updatesReducer,
  labTypesReducer: labTypesReducer,
  foldersReducer: foldersReducer,
  scopeOfControlRFIReducer: scopeOfControlRFIReducer,
  typesOfWorkRFIReducer: typesOfWorkRFIReducer,
  kindControlRfiReducer: kindControlRfiReducer,
  responsibleRFIReducer: responsibleRFIReducer,
  rfiResultsReducer: rfiResultsReducer,
  typesOfSupportReducer: typesOfSupportReducer,
  responsibleAorpiReducer,
  metalStructuresProductReportElementReducer,
  inputControlJournalReducer,
  workingTypesBondReducer,
  workingAmountReducer,
  ewStatisticReducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [yambookApi.reducerPath]: yambookApi.reducer,
  [formationApi.reducerPath]: formationApi.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KeycloakApp } from '../utils/Keycloak/Keycloak';
import { notification } from 'antd';
import { Notification } from '../components/Admin/Accounts/AccountInfo/Notification.type';
import { TIMEOUT_TOKEN } from '../utils/Base/constants/TIMEOUT_TOKEN';

const uri = process.env.REACT_APP_YAMBOOK;

const KEEP_TIME = 120;

export const yambookApi = createApi({
  reducerPath: 'yambookApi',
  tagTypes: ['Yambook'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${uri}/api`,
    prepareHeaders: async (headers) => {
      const keycloak = KeycloakApp;
      if (keycloak?.isTokenExpired?.(TIMEOUT_TOKEN)) {
        await keycloak.updateToken(TIMEOUT_TOKEN);
      }
      headers.set('Authorization', 'Bearer ' + keycloak.token);
    },
  }),
  endpoints: (builder) => ({
    getSupportTask: builder.query({
      query: (keycloakId: string) =>
        `tasks?boardId=76&keycloakId=${keycloakId}&size=999`,
      providesTags: (result, error) => [
        { type: 'Yambook', id: 'getSupportTask' },
      ],
      transformResponse(baseQueryReturnValue: {
        data: {
          content: Array<any>;
        };
      }) {
        return baseQueryReturnValue.data.content;
      },
    }),
    getNotification: builder.query({
      query: () => `notification?size=999`,
      providesTags: (result, error) => [
        { type: 'Yambook', id: 'Notification' },
      ],
      transformResponse(baseQueryReturnValue: {
        data: {
          content: Array<Notification>;
        };
      }) {
        return baseQueryReturnValue.data.content;
      },
    }),
    sendComment: builder.mutation({
      query: ({
        body,
      }: {
        body: {
          taskId: number;
          authorId?: number;
          text?: string;
          fileList?: number[];
        };
        keycloakId: string;
      }) => ({
        url: 'comment-to-task',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ keycloakId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          yambookApi.util.updateQueryData(
            'getSupportTask',
            keycloakId,
            (draft) => {
              const currentTask = draft.find((task) => task.id === body.taskId);
              currentTask.comments.push(body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          notification.error({ message: 'Ошибка отправки сообщения' });
        }
      },
    }),
    updateTask: builder.mutation({
      query: ({
        body,
        taskId,
      }: {
        taskId: number;
        body: {
          authorId?: number;
          text?: string;
          fileList?: number[];
        };
      }) => ({
        url: `tasks/${taskId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Yambook', id: 'getSupportTask' }],
    }),
    viewNotification: builder.mutation({
      query: ({ body }: { body: { isView: boolean; idList: number[] } }) => ({
        url: 'notification',
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'Yambook', id: 'Notification' }],
    }),
  }),
  keepUnusedDataFor: KEEP_TIME,
  refetchOnMountOrArgChange: KEEP_TIME,
});

export const {
  useGetSupportTaskQuery,
  useSendCommentMutation,
  useGetNotificationQuery,
  useViewNotificationMutation,
  useUpdateTaskMutation,
} = yambookApi;

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getSearchFields } from '../DictionariesExtra';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';
import DisciplineOfWorkForm from '../Components/DisciplineOfWorkForm';
import React from 'react';

const idName = 'id';
const api = 'api/discipline-of-work';
const name = 'title';

export const disciplineOfWorkData: DictionaryData = {
  apiMethod: api,
  dataListName: 'disciplineOfWorkList',
  idName: idName,
  pageHeaderTitle: 'Discipline',
  tabNameField: name,
  columnsName: 'DisciplineOfWorkTable',
  searchFields: getSearchFields,
  columns: (t) => [
    {
      title: t('Name_1'),
      dataIndex: name,
      key: 'name',
      align: 'center',
      sorter: true,
      visible: true,
    },
    {
      title: t('Types of works'),
      dataIndex: ['workingCategories', 'title'],
      key: 'workingCategories',
      align: 'center',
      visible: true,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      sorter: true,
      visible: true,
    },
  ],
  name: name,
  popupList: getPopupListDeleteId,
  addComponent: <DisciplineOfWorkForm type={'add'} />,
  editComponent: <DisciplineOfWorkForm type={'edit'} />,
};

export const onceDisciplineOfWorkProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'disciplineOfWork',
  name: name,
};

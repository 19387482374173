import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/reference-data/status-of-folder-statuses';
const name = 'title';

export const statusStatusFolderData: DictionaryData = {
  apiMethod: api,
  dataListName: 'statusOfFolderStatusesDataList',
  idName: idName,
  pageHeaderTitle: 'Area',
  tabNameField: name,
  columnsName: 'StatusStatusFolderTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceStatusStatusProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'statusOfFolderStatusesData',
  name: name,
};

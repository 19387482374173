import { ResponseData } from '../../utils/API/APITypes';
import { TestPacksType } from '../../components/Workplace/AGPP/TestPacks/TestPacksData';

const SET_DATA_TESTPACKS = 'SET_DATA_TESTPACKS';
const SET_CONTENT_TESTPACKS = 'SET_CONTENT_TESTPACKS';

export declare type dataTestPacks = {
  success: boolean;
  testPacketDataList: ResponseData<TestPacksType>;
};

export declare type TestPacksReducer = {
  data: dataTestPacks | null;
  content: Array<TestPacksType> | null;
  contentList: {
    [key: string]: TestPacksType;
  };
};

/**
 * @param {dataTestPacks} data
 * @return {any}
 */
export function setDataTestPacks(data) {
  return {
    type: SET_DATA_TESTPACKS,
    payload: data,
  };
}

/**
 * @param {Array<TestPacksType>} data
 * @return {any}
 */
export function setContentTestPacks(data) {
  return {
    type: SET_CONTENT_TESTPACKS,
    payload: data,
  };
}

const initialState: TestPacksReducer = {
  data: null,
  content: null,
  contentList: {},
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function testpacksReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TESTPACKS:
      return { ...state, data: action.payload };
    case SET_CONTENT_TESTPACKS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

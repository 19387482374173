import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';

const ForbiddenPage = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t!('Sorry, you do not have permission to view this page')}
    />
  );
});
ForbiddenPage.displayName = 'ForbiddenPage';
export default ForbiddenPage;

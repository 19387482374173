import React, { ReactNode } from 'react';
import { Form, InputNumber, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const FromToInput = React.memo(
  ({
    item,
  }: {
    item: {
      min: number;
      max: number;
      label: ReactNode;
      name: string;
      required?: boolean;
    };
  }) => {
    const { t } = useTranslation();
    return (
      <Form.Item label={item.label}>
        <Space.Compact>
          <Form.Item
            name={[item.name, 'from']}
            noStyle
            rules={[{ required: item.required, message: '' }]}
          >
            <InputNumber
              placeholder={t!('from')}
              controls={false}
              min={item.min}
              max={item.max}
              step={1}
            />
          </Form.Item>
          <Form.Item
            name={[item.name, 'to']}
            rules={[{ required: item.required, message: '' }]}
            noStyle
          >
            <InputNumber
              placeholder={t!('to')}
              controls={false}
              min={item.min}
              max={item.max}
              step={1}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
    );
  }
);
FromToInput.displayName = 'FromToInput';
export default FromToInput;

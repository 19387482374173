import {
  DictionaryData,
  OnceRecordProps,
} from '../../../../Admin/Dictionaries/DictionariesTypes';
import {
  getInitialColumns,
  getSearchFields,
} from '../../../../Admin/Dictionaries/DictionariesExtra';
import { getPopupList } from '../../../../../utils/Popup/PopupExtra';

const idName = 'areaId';
const api = 'api/area';
const name = 'title';

export const areaAgppData: DictionaryData = {
  apiMethod: api,
  dataListName: 'areaDataList',
  idName: idName,
  pageHeaderTitle: 'Area',
  tabNameField: name,
  columnsName: 'AreaTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceAreaAgppProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'areaData',
  name: name,
};

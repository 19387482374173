export const saveFile = (buffer: ArrayBuffer, fileName) => {
  const a: any = document.createElement('a');
  document.body.appendChild(a);
  a['style'] = 'display: none';
  const blob = new Blob([buffer]);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

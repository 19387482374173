import { ElementTypesType } from '../../components/Admin/Dictionaries/ElementTypesData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_ELEMENTTYPES = 'SET_DATA_ELEMENTTYPES';
const SET_CONTENT_ELEMENTTYPES = 'SET_CONTENT_ELEMENTTYPES';

export declare type dataElementTypes = {
  success: boolean;
  elementTypeDataList: ResponseData<ElementTypesType>;
};

export declare type ElementTypesReducer = {
  data: dataElementTypes | null;
  content: Array<ElementTypesType> | null;
};

/**
 * @param {dataElementTypes} data
 * @return {any}
 */
export function setDataElementTypes(data) {
  return {
    type: SET_DATA_ELEMENTTYPES,
    payload: data,
  };
}

/**
 * @param {Array<ElementTypesType>} data
 * @return {any}
 */
export function setContentElementTypes(data) {
  return {
    type: SET_CONTENT_ELEMENTTYPES,
    payload: data,
  };
}

const initialState: ElementTypesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function elementtypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ELEMENTTYPES:
      return { ...state, data: action.payload };
    case SET_CONTENT_ELEMENTTYPES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/reference-data/works-names';
const name = 'title';

export const worksNamesData: DictionaryData = {
  apiMethod: api,
  dataListName: 'worksNamesDataList',
  idName: idName,
  pageHeaderTitle: 'Titles of works',
  tabNameField: name,
  columnsName: 'NameWorksTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceWorksNamesProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'worksNamesData',
  name: name,
};

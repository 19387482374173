import { DictionaryData } from '../../DictionariesTypes';
import { getInitialColumns } from './extra';

const idName = 'id';
const api = 'api/group-technical-devices/building-structures';
const name = 'title';

export const buildingConstructionData: DictionaryData = {
  apiMethod: api,
  dataListName: 'buildingStructuresDataList',
  idName: idName,
  pageHeaderTitle: 'Building construction',
  tabNameField: name,
  columnsName: 'buildingStructureTable',
  columns: getInitialColumns,
  name: name,
  addComponent: false,
  notGotRecord: true,
};

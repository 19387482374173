import API from '../../API/API';
import store from '../../../store/store';

export const getFileImage = (id, update) => {
  API.get(`api/file/download/${id}`, {
    responseType: 'blob',
  }).then((res) => {
    const file: Blob = res.data;
    store.dispatch(update(URL.createObjectURL(file)));
  });
};

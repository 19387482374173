import React, { useMemo, useState } from 'react';
import { Button, Collapse, notification, Spin, Typography } from 'antd';
import SearchPanel from '../../../../../utils/SearchPanel/SearchPanel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store/rootReducer';
import { useResponsible } from '../../../../Workplace/AGCC/ResponsibleAorpi/helpers/useResponsible';
import API from '../../../../../utils/API/API';
import { useInvalidateAbstractData } from '../../../../../utils/Base/hooks/useInvalidateAbstractData';
import dayjs from 'dayjs';
import { useGetAbstractContent } from '../../../../../utils/Base/hooks/useGetAbstractContent';
import { getSearchFields } from '../AccountInfo.extra';

const SubscriptionManagement = () => {
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.authReducer);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const rfiResults =
    useAppSelector((state) => state.rfiResultsReducer.content) ?? [];
  const { responsibleOptionsIsYamata, responsibleOptionsNotYamata } =
    useResponsible();
  const statusListSelect = useMemo(() => {
    return [
      { value: 0, item: t('(empty)') },
      ...rfiResults.map((dictionaryItem) => ({
        value: dictionaryItem.id,
        item: dictionaryItem.title,
      })),
    ];
  }, [rfiResults]);
  const dictionaryList = {
    statusListSelect,
    responsibleOptionsIsYamata,
    responsibleOptionsNotYamata,
  };
  const invalidate = useInvalidateAbstractData();
  const apiRfiLogNotificationListAccount = `api/rfi-log-notification-list/account/${profile.accountId}`;
  const { data, isLoading } = useGetAbstractContent<{
    conditionsObject: Record<string, any>;
    id: number;
  }>(apiRfiLogNotificationListAccount, 'rfiLogNotificationList');

  const onSaveRfSubscription = async (values: Record<string, any>) => {
    const stringParams = JSON.stringify(values);
    let conditions = '';

    for (let i = 0; i < stringParams.length; i++) {
      const char = stringParams[i];
      conditions += char === '"' ? '"' : char;
    }

    const request = data ? API.put : API.post;

    setIsSaveLoading(true);

    try {
      await request(
        'api/rfi-log-notification-list',
        {
          id: data?.id,
          accountId: profile.accountId,
          conditions,
          lastNotified: dayjs().utc().format(),
        },
        { notNotification: true }
      );

      notification.success({ message: 'Вы подписались на уведомления RFI' });

      invalidate(apiRfiLogNotificationListAccount);
    } finally {
      setIsSaveLoading(false);
    }
  };

  const initialValues = data?.conditionsObject
    ? {
        ...data.conditionsObject,
        titleId: data.conditionsObject.titleId?.map((el) => el.toString()),
        kindControlId: data.conditionsObject.kindControlId?.map((el) =>
          el.toString()
        ),
        scopeOfControl: data.conditionsObject.scopeOfControl?.map((el) =>
          el.toString()
        ),
        statusId: data.conditionsObject.statusId?.map((el) => el.toString()),
        rfiResponsibleId: data.conditionsObject.rfiResponsibleId?.toString(),
        rfiResponsibleYamataId:
          data.conditionsObject.rfiResponsibleYamataId?.toString(),
        measurementUnitId: data.conditionsObject.measurementUnitId?.toString(),
        typesOfWorksId: data.conditionsObject.typesOfWorksId?.map((el) =>
          el.toString()
        ),
        rfiPeriod: data.conditionsObject?.rfiPeriod?.join(','),
        changeStatusDate: data.conditionsObject?.changeStatusDate?.join(','),
      }
    : undefined;

  return (
    <Spin spinning={isLoading}>
      <Typography.Paragraph type={'secondary'}>
        Здесь вы можете подписаться на email рассылку изменений разделов по
        своим фильтрам
      </Typography.Paragraph>
      <Collapse
        items={[
          {
            label: 'RFI',
            children: (
              <SearchPanel
                searchFields={getSearchFields(t, dictionaryList)}
                getFormValue={onSaveRfSubscription}
                actionButtons={
                  <Button
                    htmlType={'submit'}
                    type={'primary'}
                    loading={isSaveLoading}
                  >
                    {t('Save')}
                  </Button>
                }
                initialValues={initialValues}
              />
            ),
          },
        ]}
      />
    </Spin>
  );
};

export default SubscriptionManagement;

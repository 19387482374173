import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getSearchFields } from '../DictionariesExtra';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';
import FormTitle from '../Components/FormTitle';
import React from 'react';
import { AbstractColumns } from '../../../../utils/Components/AbstractClass/AbstractClassTypes';

const idName = 'id';
const api = 'api/welding-method';
const name = 'name';

const getInitialColumns = (t): AbstractColumns => [
  {
    title: t('Name_1'),
    dataIndex: name,
    key: 'name',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const weldingMethodsAgccData: DictionaryData = {
  apiMethod: api,
  dataListName: 'weldingMethodDataList',
  idName: idName,
  pageHeaderTitle: 'Welding methods',
  tabNameField: name,
  columnsName: 'WeldingMethodsTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
  addComponent: <FormTitle idName={idName} type={'add'} titleName={name} />,
  editComponent: <FormTitle idName={idName} type={'edit'} titleName={name} />,
};

export const onceWeldingMethodsAgccProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'weldingMethodData',
  editComponent: <FormTitle idName={idName} type={'edit'} titleName={name} />,
  descriptions: [{ fieldName: 'name', span: 4, label: 'Name_1' }],
  name: name,
};

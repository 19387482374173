import React, { useState } from 'react';
import AbstractElementF from '../../../utils/Components/AbstractElement/AbstractElementF';
import {
  getDescriptions,
  getPageExtra,
} from '../../Admin/Accounts/AccountInfo/AccountInfo.extra';
import CollapsibleModal from '../../../utils/CollapsibleModal/CollapsibleModal';
import EditDesign from '../../Admin/Accounts/AccountInfo/Components/EditDesign';
import { tabsList } from '../../../utils/Components/AbstractElement/AbstractElementTypes';
import SubscriptionManagement from '../../Admin/Accounts/AccountInfo/Components/SubscriptionManagement';
import NotificationLabel from '../../Layouts/components/Notificationlabel';
import RequestToSupport from '../../Admin/Accounts/AccountInfo/Components/RequestToSupport';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/rootReducer';
import { Modal } from 'antd';
import AccountForm from '../../Registry/components/AccountForm';
import { AccountsData } from '../../Admin/Accounts/AccountsData';
import { useEditData } from '../../../utils/Base/hooks/useEditData';
import { useInvalidateAbstractData } from '../../../utils/Base/hooks/useInvalidateAbstractData';

const Profile = () => {
  const { t } = useTranslation();
  const { avatarUrl, profile } = useAppSelector((state) => state.authReducer);
  const [isEditDesign, setIsEditDesign] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const invalidate = useInvalidateAbstractData();
  const apiMethod = `api/account/key-cloak/${profile?.keyCloakId}`;

  const tabs: (tabsList[number] & { visible?: boolean })[] = [
    {
      title: t('Subscription management'),
      component: () => <SubscriptionManagement />,
      extra: null,
      visible: localStorage.getItem('project') === 'agcc',
    },
    {
      title: (
        <>
          {t('My requests to support')}
          <NotificationLabel />
        </>
      ),
      component: () => <RequestToSupport />,
      extra: null,
    },
  ].filter((el) => el.visible !== false);

  const toggleDesign = () => {
    setIsEditDesign((prevState) => !prevState);
  };
  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  const pageExtra = getPageExtra(toggleDesign, toggleEdit, t);

  const { onFinishEdit, isLoading } = useEditData(AccountsData.apiMethod);

  const onFinishForm = async (values: any) => {
    const res = await onFinishEdit(values);
    toggleEdit();
    invalidate(apiMethod);
    return res;
  };

  return (
    <>
      <AbstractElementF
        apiMethod={apiMethod}
        dataListName={'accountData'}
        editMode={false}
        tabs={tabs}
        descriptions={getDescriptions(t!)}
        pageHeaderList={{ pass: 'personFullName' }}
        avatar={{ src: avatarUrl }}
        pageExtra={pageExtra}
      />
      {isEditDesign && (
        <CollapsibleModal
          title={t('Edit')}
          visible={isEditDesign}
          onCancel={toggleDesign}
          width={'20vw'}
        >
          <EditDesign onCancel={toggleDesign} />
        </CollapsibleModal>
      )}
      <Modal
        open={isEdit}
        onCancel={toggleEdit}
        title={t('Edit')}
        footer={false}
      >
        <AccountForm
          type="edit"
          currentRow={profile}
          onFinishEdit={onFinishForm}
          isFetching={isLoading}
        />
      </Modal>
    </>
  );
};

export default Profile;

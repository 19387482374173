import { Dictionary } from '../../AppTypes';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_KIND_CONTROL_RFI = 'SET_DATA_KIND_CONTROL_RFI';
const SET_CONTENT_KIND_CONTROL_RFI = 'SET_CONTENT_KIND_CONTROL_RFI';

export declare type DataKindControlRfi = {
  success: boolean;
  DataList: ResponseData<Dictionary>;
};

export declare type KindControlRfiReducer = {
  data: DataKindControlRfi | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {Array<DataKindControlRfi>} data
 * @return {any}
 */
export function setContentKindControlRfi(data) {
  return {
    type: SET_CONTENT_KIND_CONTROL_RFI,
    payload: data,
  };
}

const initialState: KindControlRfiReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function kindControlRfiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_KIND_CONTROL_RFI:
      return { ...state, data: action.payload };
    case SET_CONTENT_KIND_CONTROL_RFI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

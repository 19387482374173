import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_SCOPE_OF_CONTROL_RFI = 'SET_DATA_SCOPE_OF_CONTROL_RFI';
const SET_CONTENT_SCOPE_OF_CONTROL_RFI = 'SET_CONTENT_SCOPE_OF_CONTROL_RFI';

export declare type DataScopeOfControlRFI = {
  success: boolean;
  typesOfControlDataList: ResponseData<Dictionary>;
};

export declare type ScopeOfControlRFIReducer = {
  data: DataScopeOfControlRFI | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {Array<Dictionary>} data
 * @return {any}
 */
export function setContentScopeOfControlRFI(data) {
  return {
    type: SET_CONTENT_SCOPE_OF_CONTROL_RFI,
    payload: data,
  };
}

const initialState: ScopeOfControlRFIReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function scopeOfControlRFIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_SCOPE_OF_CONTROL_RFI:
      return { ...state, data: action.payload };
    case SET_CONTENT_SCOPE_OF_CONTROL_RFI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { WDBType } from '../../components/Workplace/AGPP/WDB/WDBData';

const SET_DATA_WLG = 'SET_DATA_WLG';
const SET_CONTENT_WLG = 'SET_CONTENT_WLG';

export declare type dataWLG = {
  success: boolean;
  wdbDataList: ResponseData<WDBType>;
  totalElements: number;
};

export declare type WLGReducer = {
  data: dataWLG | null;
  content: Array<WDBType> | null;
  statistic: {
    jointsInWeldlog: number;
    jointsNotInWeldlog: number;
    welderStatisticDataList: Array<any>;
  } | null;
};

/**
 * @param {dataWLG} data
 * @return {any}
 */
export function setDataWLG(data) {
  return {
    type: SET_DATA_WLG,
    payload: data,
  };
}

/**
 * @param {Array<WDBType>} data
 * @return {any}
 */
export function setContentWLG(data) {
  return {
    type: SET_CONTENT_WLG,
    payload: data,
  };
}

const initialState: WLGReducer = {
  data: null,
  content: null,
  statistic: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function wlgReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WLG:
      return { ...state, data: action.payload };
    case SET_CONTENT_WLG:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { MetalStructuresProductReportElement } from '../../components/Workplace/AGCC/MetalStructures/types';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT =
  'SET_DATA_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT';
const SET_CONTENT_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT =
  'SET_CONTENT_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT';

export declare type DataMetalStructuresProductReportElement = {
  success: boolean;
  metalStructuresProductReportElementDataList: ResponseData<MetalStructuresProductReportElement>;
};

export declare type MetalStructuresProductReportElementReducer = {
  data: DataMetalStructuresProductReportElement | null;
  content: Array<MetalStructuresProductReportElement> | null;
};

/**
 * @param {DataMetalStructuresProductReportElement} data
 * @return {any}
 */
export function setDataMetalStructuresProductReportElement(data) {
  return {
    type: SET_DATA_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT,
    payload: data,
  };
}

/**
 * @param {Array<DataMetalStructuresProductReportElement>} data
 * @return {any}
 */
export function setContentMetalStructuresProductReportElement(data) {
  return {
    type: SET_CONTENT_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT,
    payload: data,
  };
}

const initialState: MetalStructuresProductReportElementReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function metalStructuresProductReportElementReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_DATA_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT:
      return { ...state, data: action.payload };
    case SET_CONTENT_METAL_STRUCTURES_PRODUCT_REPORT_ELEMENT:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/group-of-block-factor';
const name = 'title';

export const groupOfBlockFactorData: DictionaryData = {
  apiMethod: api,
  dataListName: 'groupOfBlockFactorList',
  idName: idName,
  pageHeaderTitle: 'Groups of block factors',
  tabNameField: name,
  columnsName: 'GroupOfBlockFactorTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
};

export const onceGroupOfBlockFactorProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'groupOfBlockFactor',
  name: name,
};

import { DictionaryData } from '../../DictionariesTypes';
import { getInitialColumns } from './extra';

const idName = 'id';
const api = 'api/group-technical-devices/chemical-petrochemical-equipment';
const name = 'title';

export const chemicalPetrochemicalEquipmentData: DictionaryData = {
  apiMethod: api,
  dataListName: 'chemicalPetrochemicalEquipmentDataList',
  idName: idName,
  pageHeaderTitle:
    'Equipments for chemical, petrochemical, oil refining and explosion-hazardous industries',
  tabNameField: name,
  columnsName: 'chemicalPetrochemicalTable',
  columns: getInitialColumns,
  name: name,
  addComponent: false,
  notGotRecord: true,
};

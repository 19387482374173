import { Button, Popover, Tag, Tooltip } from 'antd';
import i18n from 'i18next';
import dayjs from 'dayjs';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { KeycloakApp } from '../../../../utils/Keycloak/Keycloak';
import React from 'react';
import { DescriptionsList } from '../../../../utils/Components/AbstractElement/AbstractElementTypes';
import { renderDate } from '../../../../utils/Base/helpers/renderDate';
import { SearchFieldsType } from '../../../../utils/SearchPanel/types/SearchPanelTypes';
import {
  marksData,
  rfiKindOfControlData,
  scopeOfControlRFIData,
  typeOfWorkRFIData,
  unitsOfMeasureData,
} from '../../Dictionaries/Extra/DictionariesData';
import { titleListDictionaryData } from '../../../Workplace/AGCC/TitleList/titleListDictionaryData';
import { areaAgccData } from '../../Dictionaries/Extra/AreaExtra';

export const getPageExtra = (
  setIsEditDesign: () => void,
  setIsEdit: () => void,
  t: Function
) => [
  <Button
    onClick={setIsEditDesign}
    style={{ padding: '0 5px' }}
    key="design"
    type="primary"
  >
    💅🏻
  </Button>,
  <Popover
    key="language"
    placement="bottom"
    trigger="click"
    content={
      <div>
        <Button
          onClick={() => {
            localStorage.setItem('i18nextLng', 'en-US');
            i18n.changeLanguage('en-US');
            dayjs.locale('en');
          }}
          type={
            localStorage.getItem('i18nextLng') === 'en-US'
              ? 'primary'
              : 'default'
          }
        >
          English
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem('i18nextLng', 'ru-RU');
            i18n.changeLanguage('ru-RU');
            dayjs.locale('ru');
          }}
          type={
            localStorage.getItem('i18nextLng') === 'ru-RU'
              ? 'primary'
              : 'default'
          }
        >
          Русский
        </Button>
      </div>
    }
  >
    <Button type="primary">{t('Change language')}</Button>
  </Popover>,
  <Tooltip key="logout" title={t('Log out')}>
    <Button
      type="primary"
      danger
      icon={<LogoutOutlined />}
      onClick={() => {
        localStorage.removeItem('project');
        KeycloakApp.logout();
      }}
    />
  </Tooltip>,
  <Button
    onClick={setIsEdit}
    key="edit"
    type="primary"
    icon={<EditOutlined />}
  />,
];

export const getDescriptions = (t: Function): DescriptionsList => [
  {
    label: t('Login'),
    fieldName: 'login',
    span: 6,
  },
  {
    label: 'Email',
    fieldName: 'email',
    span: 6,
  },
  {
    label: t('Staff number'),
    fieldName: 'organizationServiceNumber',
    span: 6,
  },
  {
    label: t('Language'),
    fieldName: 'language',
    span: 6,
    render: (item) => {
      return item === 'RU' ? 'Русский' : 'English';
    },
  },
  {
    label: t('Active'),
    fieldName: 'enabled',
    span: 6,
    render: (item) => {
      if (item) {
        return <Tag color="green">{t('Account is active')}</Tag>;
      } else {
        return <Tag color="red">{t('Account is active')}</Tag>;
      }
    },
  },
  {
    label: t('Creation time'),
    fieldName: 'creationTime',
    span: 6,
    render: (item) => {
      return dayjs(item).format('DD.MM.YYYY');
    },
  },
  {
    label: t('Update time'),
    fieldName: 'updateTime',
    span: 6,
    render: renderDate,
  },
  {
    label: t('Position'),
    fieldName: 'organizationPositionData.title',
    span: 6,
  },
  {
    label: t('Department'),
    fieldName: 'organizationPositionData.organizationDepartmentData.title',
    span: 6,
  },
  {
    label: t('Organization'),
    fieldName:
      'organizationPositionData.organizationDepartmentData.' +
      'organizationData.title',
    span: 6,
  },
];

export const getSearchFields = (
  t,
  dictionaries: Record<
    | 'statusListSelect'
    | 'responsibleOptionsIsYamata'
    | 'responsibleOptionsNotYamata',
    { value: number; item: string }[]
  >
): SearchFieldsType[] => {
  const {
    statusListSelect,
    responsibleOptionsIsYamata,
    responsibleOptionsNotYamata,
  } = dictionaries;
  return [
    {
      component: 'Input',
      label: t('Number'),
      name: 'number',
      placeholder: '',
      required: false,
      span: 2,
    },
    {
      component: 'Input',
      label: t('Customer number'),
      name: 'numberCustomer',
      placeholder: '',
      required: false,
      span: 2,
    },
    {
      component: 'select',
      label: t('Types of work'),
      name: 'typesOfWorksId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: typeOfWorkRFIData,
      selectMode: 'multiple',
    },
    {
      component: 'select',
      label: t('Kinds of control'),
      name: 'kindControlId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: rfiKindOfControlData,
      selectMode: 'multiple',
    },
    {
      component: 'select',
      label: t('Titles'),
      name: 'titleId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: titleListDictionaryData,
      selectMode: 'multiple',
    },
    {
      component: 'select',
      label: t('Areas'),
      name: 'areaTitleList',
      placeholder: '',
      required: false,
      link: areaAgccData.apiMethod + '?size=20&title=',
      dataListName: areaAgccData.dataListName,
      showSearch: true,
      selectMode: 'multiple',
      selectItem: [
        {
          value: areaAgccData.name,
          item: areaAgccData.name,
        },
      ],
      span: 4,
    },
    {
      component: 'select',
      label: t('Marks'),
      name: 'markTitleList',
      placeholder: '',
      required: false,
      link: marksData.apiMethod + '?size=20&title=',
      dataListName: marksData.dataListName,
      showSearch: true,
      selectMode: 'multiple',
      selectItem: [
        {
          value: marksData.name,
          item: marksData.name,
        },
      ],
      span: 4,
    },
    {
      component: 'select',
      label: t('Scope of control'),
      name: 'scopeOfControl',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: [],
      dictionaryData: scopeOfControlRFIData,
      selectMode: 'multiple',
    },
    {
      component: 'select',
      label: t('Statuses'),
      name: 'statusId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: statusListSelect,
      selectMode: 'multiple',
    },
    {
      component: 'Input',
      label: t('Working drawing number'),
      name: 'workingDrawingNumber',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: t('Other referenced documents'),
      name: 'otherReferenceDocuments',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: t('Attached documents confirming quality'),
      name: 'attachedDocumentsConfirmingQuality',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'Input',
      label: t('Description'),
      name: 'description',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'rangepicker',
      label: t('Date'),
      name: 'rfiPeriod',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'rangepicker',
      label: t('Change status date'),
      name: 'changeStatusDate',
      placeholder: '',
      required: false,
      span: 4,
    },
    {
      component: 'select',
      label: t('Types of work'),
      name: 'worksNameList',
      link: 'api/working-types-bond?size=20&workName=',
      dataListName: 'workingTypesBondData',
      showSearch: true,
      selectMode: 'multiple',
      selectItem: [
        {
          value: 'workNameData.title',
          item: 'workNameData.title',
          render: (record) => `${record.workNameData.title}`,
        },
      ],
      span: 4,
    },
    {
      component: 'select',
      label: t('unit'),
      name: 'measurementUnitId',
      placeholder: '',
      required: false,
      span: 4,
      dictionaryData: unitsOfMeasureData,
      selectItem: [],
    },
    {
      component: 'select',
      label: t('Responsible') + ' YAMATA',
      name: 'rfiResponsibleYamataId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: responsibleOptionsIsYamata,
    },
    {
      component: 'select',
      label: t('Responsible for the customer'),
      name: 'rfiResponsibleId',
      placeholder: '',
      required: false,
      span: 4,
      selectItem: responsibleOptionsNotYamata,
    },
  ];
};

import { ResponseData } from "../../utils/API/APITypes";
import { TypesOfLaboratoryControl } from "../../components/Admin/Dictionaries/TypesOfLaboratories";
import { organizationDataType } from "../../components/Admin/Organizations/OrganizationsData";
import { Equipments } from "../../components/Workplace/AGPP/Laboratories/Equipments/EquipmentsTypes";
import { Employees } from "../../components/Workplace/AGPP/Laboratories/Employees/EmployeesTypes";
import { LabRequest } from "../../components/Workplace/AGPP/Laboratories/Requests/LabRequestsType";

const SET_DATA_EQUIPMENTS = 'SET_DATA_EQUIPMENTS';
const SET_CONTENT_EQUIPMENTS = 'SET_CONTENT_EQUIPMENTS';
const SET_DATA_EMPLOYEES = 'SET_DATA_EMPLOYEES';
const SET_CONTENT_EMPLOYEES = 'SET_CONTENT_EMPLOYEES';
const SET_DATA_REQUESTS = 'SET_DATA_REQUESTS';
const SET_CONTENT_REQUESTS = 'SET_CONTENT_REQUESTS';
const SET_DATA_REQUESTS_NDT = 'SET_DATA_REQUESTS_NDT';
const SET_CONTENT_REQUESTS_NDT = 'SET_CONTENT_REQUESTS_NDT';
const SET_LABORATORIES = 'SET_LABORATORIES';

export declare type DataEquipments = {
  success: boolean;
  labsEquipmentsDataList: ResponseData<Equipments>;
};

export declare type DataEmployees = {
  success: boolean;
  labsEmployeesDataList: ResponseData<Employees>;
};

export declare type DataLabRequests = {
  success: boolean;
  labRequestDataList: ResponseData<LabRequest>;
};

export declare type Laboratory = {
  code: string;
  controlTypesDataList: Array<TypesOfLaboratoryControl>;
  employeesDataList: Array<Employees>;
  equipmentDataList: Array<Equipments>;
  labsOrganizationId: number;
  licenseNumber: string;
  organizationId: number;
};

export declare type InitialState = {
  equipmentsData: DataEquipments | null;
  equipmentsContent: Array<Equipments> | null;
  employeesData: DataEmployees | null;
  employeesContent: Array<Employees> | null;
  requestsData: DataLabRequests | null;
  requestsContent: Array<LabRequest> | null;
  requestsDataNDT: DataLabRequests | null;
  requestsContentNDT: Array<LabRequest> | null;
  laboratories: Array<organizationDataType> | null;
};

/**
 * @param {DataEquipments} data
 * @return {any}
 */
export function setDataEquipments(data) {
  return {
    type: SET_DATA_EQUIPMENTS,
    payload: data,
  };
}

/**
 * @param {Array<Equipments>} data
 * @return {any}
 */
export function setContentEquipments(data) {
  return {
    type: SET_CONTENT_EQUIPMENTS,
    payload: data,
  };
}
/**
 * @param {DataEmployees} data
 * @return {any}
 */
export function setDataEmployees(data) {
  return {
    type: SET_DATA_EMPLOYEES,
    payload: data,
  };
}

/**
 * @param {Array<Employees>} data
 * @return {any}
 */
export function setContentEmployees(data) {
  return {
    type: SET_CONTENT_EMPLOYEES,
    payload: data,
  };
}
/**
 * @param {DataEmployees} data
 * @return {any}
 */
export function setDataRequests(data) {
  return {
    type: SET_DATA_REQUESTS,
    payload: data,
  };
}

/**
 * @param {Array<Employees>} data
 * @return {any}
 */
export function setContentRequests(data) {
  return {
    type: SET_CONTENT_REQUESTS,
    payload: data,
  };
}
/**
 * @param {DataEmployees} data
 * @return {any}
 */
export function setDataRequestsNDT(data) {
  return {
    type: SET_DATA_REQUESTS_NDT,
    payload: data,
  };
}

/**
 * @param {Array<Employees>} data
 * @return {any}
 */
export function setContentRequestsNDT(data) {
  return {
    type: SET_CONTENT_REQUESTS_NDT,
    payload: data,
  };
}
/**
 * @param {OrganizationsReducer} data
 * @return {any}
 */
export function setLaboratories(data) {
  return {
    type: SET_LABORATORIES,
    payload: data,
  };
}

const initialState: InitialState = {
  equipmentsData: null,
  equipmentsContent: null,
  employeesData: null,
  employeesContent: null,
  requestsContent: null,
  requestsData: null,
  requestsContentNDT: null,
  requestsDataNDT: null,
  laboratories: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function laboratoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_EQUIPMENTS:
      return { ...state, equipmentsData: action.payload };
    case SET_CONTENT_EQUIPMENTS:
      return { ...state, equipmentsContent: action.payload };
    case SET_DATA_EMPLOYEES:
      return { ...state, employeesData: action.payload };
    case SET_CONTENT_EMPLOYEES:
      return { ...state, employeesContent: action.payload };
    case SET_CONTENT_REQUESTS:
      return { ...state, requestsContent: action.payload };
    case SET_DATA_REQUESTS:
      return { ...state, requestsData: action.payload };
    case SET_CONTENT_REQUESTS_NDT:
      return { ...state, requestsContentNDT: action.payload };
    case SET_DATA_REQUESTS_NDT:
      return { ...state, requestsDataNDT: action.payload };
    case SET_LABORATORIES:
      return {
        ...state,
        laboratories: action.payload.organizationDataList.content,
      };
    default:
      return state;
  }
}

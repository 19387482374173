import { JointTypesType } from '../../components/Admin/Dictionaries/JointTypesData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_JOINTTYPES = 'SET_DATA_JOINTTYPES';
const SET_CONTENT_JOINTTYPES = 'SET_CONTENT_JOINTTYPES';

export declare type dataJointTypes = {
  success: boolean;
  jointTypeDataList: ResponseData<JointTypesType>;
};

export declare type JointTypesReducer = {
  data: dataJointTypes | null;
  content: Array<JointTypesType> | null;
};

/**
 * @param {Array<JointTypesType>} data
 * @return {any}
 */
export function setContentJointTypes(data) {
  return {
    type: SET_CONTENT_JOINTTYPES,
    payload: data,
  };
}

const initialState: JointTypesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function jointTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_JOINTTYPES:
      return { ...state, data: action.payload };
    case SET_CONTENT_JOINTTYPES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

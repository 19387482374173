import { ResponseData } from '../../utils/API/APITypes';
import { EwBasePhase } from '../../components/Workplace/AGPP/Electrican/BasePhase/EwBasePhaseTypes';

const SET_DATA_EW_BASE_PHASE = 'SET_DATA_EW_BASE_PHASE';
const SET_CONTENT_EW_BASE_PHASE = 'SET_CONTENT_EW_BASE_PHASE';
const SET_SUMMARY_EW_BASE_PHASE = 'SET_SUMMARY_EW_BASE_PHASE';

export declare type DataEwBasePhase = {
  success: boolean;
  DataList: ResponseData<EwBasePhase>;
};

export declare type EwBasePhaseReducer = {
  data: DataEwBasePhase | null;
  content: Array<EwBasePhase> | null;
  summary: Record<string, string[]>;
};

/**
 * @param {DataEwBasePhase} data
 * @return {any}
 */
export function setDataEwBasePhase(data) {
  return {
    type: SET_DATA_EW_BASE_PHASE,
    payload: data,
  };
}

/**
 * @param {Array<DataEwBasePhase>} data
 * @return {any}
 */
export function setContentEwBasePhase(data) {
  return {
    type: SET_CONTENT_EW_BASE_PHASE,
    payload: data,
  };
}

/**
 * @param {Array<DataEwBasePhase>} data
 * @return {any}
 */
export function setSummaryEwBasePhase(data) {
  return {
    type: SET_SUMMARY_EW_BASE_PHASE,
    payload: data,
  };
}

const initialState: EwBasePhaseReducer = {
  data: null,
  content: null,
  summary: {},
};

/**
 * @param {state}state
 * @param {any} action
 * @return {EwBasePhaseReducer}
 */
export function ewBasePhaseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_EW_BASE_PHASE:
      return { ...state, data: action.payload };
    case SET_CONTENT_EW_BASE_PHASE:
      return { ...state, content: action.payload };
    case SET_SUMMARY_EW_BASE_PHASE:
      return { ...state, summary: action.payload };
    default:
      return state;
  }
}

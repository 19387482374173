import axios from 'axios';
import { KeycloakApp } from '../Keycloak/Keycloak';

declare module 'axios' {
  export interface AxiosRequestConfig {
    notNotification?: boolean;
  }
}

const API_FORM = axios.create();

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
API_FORM.interceptors.request.use(
  async function (config) {
    if (KeycloakApp?.isTokenExpired(300)) {
      await KeycloakApp.updateToken(300);
    }
    const AUTH_TOKEN = KeycloakApp.token;
    const PROJECT = localStorage.getItem('project');
    // @ts-ignore
    config.headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    };
    config.baseURL =
      PROJECT === 'agpp'
        ? process.env.REACT_APP_SSAS_FORMATION_URL
        : process.env.REACT_APP_SSAS_FORMATION_URL_AGCC;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API_FORM.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export default API_FORM;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/store';
import { I18nextProvider } from 'react-i18next';
import i18 from './utils/i18n/i18';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './utils/Router/Router';
import './dayjsPlugins';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://acc4361694b273c25d9376335c60f1c4@o4508267805409280.ingest.de.sentry.io/4508267807244368',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18}>
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

serviceWorkerRegistration.register();

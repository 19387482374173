import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_EW_FOLDER_STATUSES = 'SET_DATA_EW_FOLDER_STATUSES';
const SET_CONTENT_EW_FOLDER_STATUSES = 'SET_CONTENT_EW_FOLDER_STATUSES';

export declare type DataEwFolderStatuses = {
  success: boolean;
  foldersStatusDataList: ResponseData<EwFolderStatuses>;
};
export declare type EwFolderStatuses = {
  folderStatusId: number;
  title: string;
  description: string;
  processTime: number;
};
export declare type EwFolderStatusesReducer = {
  data: DataEwFolderStatuses | null;
  content: Array<DataEwFolderStatuses> | null;
};

/**
 * @param {DataEwFolderStatuses} data
 * @return {any}
 */
export function setDataEwFolderStatuses(data) {
  return {
    type: SET_DATA_EW_FOLDER_STATUSES,
    payload: data,
  };
}

/**
 * @param {Array<DataEwFolderStatuses>} data
 * @return {any}
 */
export function setContentEwFolderStatuses(data) {
  return {
    type: SET_CONTENT_EW_FOLDER_STATUSES,
    payload: data,
  };
}

const initialState: EwFolderStatusesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function ewFolderStatusesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_EW_FOLDER_STATUSES:
      return { ...state, data: action.payload };
    case SET_CONTENT_EW_FOLDER_STATUSES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

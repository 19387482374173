import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const api = 'api/reference-data/area';
const name = 'title';

export const areaAgccData: DictionaryData = {
  apiMethod: api,
  dataListName: 'areaDataList',
  idName: idName,
  pageHeaderTitle: 'Area',
  tabNameField: name,
  columnsName: 'AreaTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceAreaAgccProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'areaData',
  name: name,
};

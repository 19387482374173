import React, { useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from '../../../store/rootReducer';
import { errorRequest } from './helpers/errorRequest';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';

const ErrorAction = React.memo(
  ({
    resetErrorBoundary,
    message,
  }: {
    resetErrorBoundary: Function;
    message: string;
  }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { profile } = useAppSelector((state) => state.authReducer);
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
      setLoading(true);
      errorRequest({
        values,
        message,
        token: KeycloakApp.token,
        personFullName: profile.personFullName,
      })
        .then(() => {
          setOpen(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Space>
        <Button
          size="small"
          type="primary"
          danger
          onClick={() => resetErrorBoundary()}
        >
          {'Try again'}
        </Button>
        <Button size="small" danger onClick={() => setOpen(true)}>
          {t('Report a bug')}
        </Button>
        <Modal
          open={open}
          title={t('Report a bug')}
          onCancel={() => setOpen(false)}
          footer={false}
        >
          <Form layout={'vertical'} onFinish={onFinish}>
            <Form.Item
              label={t('Title_1')}
              name={'title'}
              rules={[{ required: true, message: '' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('Description')} name={'description'}>
              <TextArea
                placeholder={t(
                  'Пример: При нажатии на кнопку "Отправить" на странице с титул-марками, происходит перенаправление на пустую страницу с ошибкой' +
                    ' 404. Ошибка возникает как на компьютере, так и на мобильном устройстве, при попытке сохранить дынные в форме редактирования'
                )}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                htmlType={'submit'}
              >
                {t('Submit')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    );
  }
);
ErrorAction.displayName = 'ErrorAction';
export default ErrorAction;

import { Responsible } from '../../components/Workplace/AGCC/RFI/RFITypes';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_RESPONSIBLE_AORPI = 'SET_DATA_RESPONSIBLE_AORPI';
const SET_CONTENT_RESPONSIBLE_AORPI = 'SET_CONTENT_RESPONSIBLE_AORPI';

export declare type DataResponsibleAorpi = {
  success: boolean;
  DataList: ResponseData<Responsible>;
};

export declare type ResponsibleAorpiReducer = {
  data: DataResponsibleAorpi | null;
  content: Array<Responsible> | null;
};

/**
 * @param {DataResponsibleAorpi} data
 * @return {any}
 */
export function setDataResponsibleAorpi(data) {
  return {
    type: SET_DATA_RESPONSIBLE_AORPI,
    payload: data,
  };
}

/**
 * @param {Array<DataResponsibleAorpi>} data
 * @return {any}
 */
export function setContentResponsibleAorpi(data) {
  return {
    type: SET_CONTENT_RESPONSIBLE_AORPI,
    payload: data,
  };
}

const initialState: ResponsibleAorpiReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function responsibleAorpiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_RESPONSIBLE_AORPI:
      return { ...state, data: action.payload };
    case SET_CONTENT_RESPONSIBLE_AORPI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { WorkingTypesBond } from '../../components/Workplace/AGCC/WorkingAmountsRresults/WorkingTypesBond/types';

const SET_DATA_WORKING_TYPES_BOND = 'SET_DATA_WORKING_TYPES_BOND';
const SET_CONTENT_WORKING_TYPES_BOND = 'SET_CONTENT_WORKING_TYPES_BOND';

export declare type DataWorkingTypesBond = {
  success: boolean;
  workingTypesBondData: ResponseData<WorkingTypesBond>;
};

export declare type WorkingTypesBondReducer = {
  data: DataWorkingTypesBond | null;
  content: Array<WorkingTypesBond> | null;
};

/**
 * @param {DataWorkingTypesBond} data
 * @return {any}
 */
export function setDataWorkingTypesBond(data) {
  return {
    type: SET_DATA_WORKING_TYPES_BOND,
    payload: data,
  };
}

/**
 * @param {Array<DataWorkingTypesBond>} data
 * @return {any}
 */
export function setContentWorkingTypesBond(data) {
  return {
    type: SET_CONTENT_WORKING_TYPES_BOND,
    payload: data,
  };
}

const initialState: WorkingTypesBondReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function workingTypesBondReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WORKING_TYPES_BOND:
      return { ...state, data: action.payload };
    case SET_CONTENT_WORKING_TYPES_BOND:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import React from 'react';
import { NewFormProps } from '../../../../AppTypes';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { PipelineEnvironment } from '../../../Workplace/AGCC/Pipeline/TP/Lines/types';

const FormPipelineEnvironment = React.memo(
  ({
    onFinishEdit,
    onFinishAdd,
    currentRow,
    isFetching,
    type,
  }: NewFormProps<PipelineEnvironment>) => {
    const { t } = useTranslation();
    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!([values])
        : onFinishEdit!([{ ...values, id: currentRow?.id }]);
    };
    const rules = [{ required: true, message: '' }];
    return (
      <Form layout={'vertical'} onFinish={onFinish} initialValues={currentRow}>
        <Form.Item label={t('Name_1')} name={'title'} rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item label={t('Code')} name={'code'} rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item
          name={'fluidService'}
          label={t('Fluid service')}
          rules={rules}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
FormPipelineEnvironment.displayName = 'FormPipelineEnvironment';
export default FormPipelineEnvironment;

import React, { useMemo, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import API from '../../../utils/API/API';
import { useTranslation, withTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { KeycloakApp } from '../../../utils/Keycloak/Keycloak';
import debounce from 'lodash/debounce';
import { NewFormProps } from '../../../AppTypes';
import { AccountsData, AccountType } from '../../Admin/Accounts/AccountsData';

/**
 * Инициализация функции AddAccount
 * @param {AddAccProps} props
 * @return {JSX}
 */
function AccountForm(props: NewFormProps<AccountType>) {
  const { currentRow, type } = props;
  const [positions, setPositions] = useState(
    currentRow?.organizationPositionData
      ? [currentRow?.organizationPositionData]
      : []
  );
  const [fetching, setIsFetching]: Array<any> = useState(false);
  const currentLanguage = localStorage.getItem('i18nextLng');

  const accountInfo = KeycloakApp.token
    ? jwtDecode<any>(KeycloakApp.token)
    : null;

  const { onFinishEdit, isFetching: isFetchingFinish } = props;
  const { t, i18n } = useTranslation();

  const selectOptions = {
    showSearch: true,
    defaultActiveFirstOption: false,
    showArrow: false,
    filterOption: false,
    allowClear: true,
  };

  const searchPositionDebounce = debounce(function (value: string) {
    if (value) {
      searchPosition(value.trim());
    }
  }, 300);

  const searchPosition = (value) => {
    setIsFetching(true);
    API.get(`api/organization/position?title=${value}`)
      .then(function (response) {
        const positions = response.data.organizationPositionDataList.content;
        setPositions(
          type === 'add'
            ? positions.filter(
                (pos) => !pos.title?.toLowerCase().includes('руководитель')
              )
            : positions
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };
  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value === 'RU' ? 'ru-RU' : 'en-US');
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      enabled: values.enabled === 'true',
      email: currentRow?.email ?? accountInfo?.email,
      personName: currentRow?.personName ?? accountInfo?.given_name,
      personSurname: currentRow?.personSurname ?? accountInfo?.family_name,
      login: currentRow?.login ?? accountInfo?.preferred_username,
      keyCloakId: currentRow?.keyCloakId ?? accountInfo?.sub,
      accountId: currentRow?.accountId,
    };

    const request =
      type === 'add'
        ? (data) => API.post(AccountsData.apiMethod, data)
        : (data) => onFinishEdit!({ ...data, language: currentRow?.language });

    request!(data).then(() => {
      if (type === 'add') {
        window.location.reload();
      }
    });
  };

  const optionsPosition = useMemo(
    () =>
      positions?.map((pos) => ({
        label: (
          <>
            <p
              style={{
                textAlign: 'start',
                margin: 0,
              }}
            >
              {pos.title}
            </p>
            <p
              style={{
                textAlign: 'start',
                marginBottom: 0,
                fontSize: '12px',
                color: 'darkgray',
              }}
            >
              {pos.description}
            </p>
          </>
        ),
        value: pos.organizationPositionId,
        selected: pos?.title,
      })),
    [positions]
  );

  const initialValues = currentRow
    ? {
        ...currentRow,
        enabled: currentRow.enabled ? currentRow.enabled.toString() : 'true',
        language:
          currentRow.language ??
          (currentLanguage
            ? currentLanguage.split('-')[0].toUpperCase()
            : 'EN'),
        organizationPositionId:
          currentRow?.organizationPositionData?.organizationPositionId,
      }
    : undefined;

  return (
    <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <Row gutter={16}>
        {type === 'add' && (
          <Col span={12}>
            <Form.Item
              label={'Language / Язык'}
              name="language"
              rules={[{ required: true, message: '' }]}
            >
              <Select onChange={onChangeLanguage}>
                <Select.Option key="RU" value="RU">
                  Русский
                </Select.Option>
                <Select.Option key="EN" value="EN">
                  English
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item name={'personPatronymic'} label={t('Patronymic')}>
            <Input />
          </Form.Item>
        </Col>
        {type === 'edit' && (
          <Col span={12}>
            <Form.Item
              label={t('Active')}
              name="enabled"
              rules={[{ required: true, message: '' }]}
            >
              <Select>
                <Select.Option key="true" value="true">
                  {t('Account is active')}
                </Select.Option>
                <Select.Option key="false" value="false">
                  {t('Account is not active')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            label={t('Position')}
            name="organizationPositionId"
            rules={[{ required: true, message: '' }]}
          >
            <Select
              {...selectOptions}
              onSearch={searchPositionDebounce}
              options={optionsPosition}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              optionLabelProp={'selected'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Staff number')}
            name="organizationServiceNumber"
            rules={[{ required: true, message: '' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div style={{ width: '100%', textAlign: 'end' }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isFetchingFinish}
              >
                {type === 'add' ? t('Create account') : t('Save')}
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default withTranslation()(AccountForm);

import { ResponseData } from '../../utils/API/APITypes';
import { Updates } from '../../components/Super admin/Updates/UpdatesTypes';

const SET_DATA_UPDATES = 'SET_DATA_UPDATES';
const SET_CONTENT_UPDATES = 'SET_CONTENT_UPDATES';

export declare type DataUpdates = {
  success: boolean;
  DataList: ResponseData<Updates>;
};

export declare type UpdatesReducer = {
  data: DataUpdates | null;
  content: Array<Updates> | null;
};

/**
 * @param {DataUpdates} data
 * @return {any}
 */
export function setDataUpdates(data) {
  return {
    type: SET_DATA_UPDATES,
    payload: data,
  };
}

/**
 * @param {Array<DataUpdates>} data
 * @return {any}
 */
export function setContentUpdates(data) {
  return {
    type: SET_CONTENT_UPDATES,
    payload: data,
  };
}

const initialState: UpdatesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function updatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_UPDATES:
      return { ...state, data: action.payload };
    case SET_CONTENT_UPDATES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { PlusOutlined } from '@ant-design/icons';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import React from 'react';
import dayjs from 'dayjs';
import { SearchFieldsType } from '../../../utils/SearchPanel/types/SearchPanelTypes';

export const getInitialColumnsTemperature = (t) => {
  const initialColumns: AbstractColumns = [
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      visible: true,
      sorter: true,
      render: (item) => {
        return dayjs(item).format('DD.MM.YYYY');
      },
    },
    {
      title: t('Night temperature'),
      dataIndex: 'nightTemperature',
      key: 'nightTemperature',
      align: 'center',
      visible: true,
    },
    {
      title: t('Day temperature'),
      dataIndex: 'dayTemperature',
      key: 'dayTemperature',
      align: 'center',
      visible: true,
    },
    {
      title: t('Daily temperature'),
      dataIndex: 'dailyTemperature',
      key: 'dayTemperature',
      align: 'center',
      visible: true,
    },
  ];
  return initialColumns;
};

export const getSearchFieldsTemperature = (t): SearchFieldsType[] => [
  {
    component: 'datepicker',
    label: t('Date'),
    name: 'date',
    required: false,
    span: 8,
  },
];

export const pageHeaderExtra: Array<AbstractClassPageHeaderExtraButtonItem> = [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];

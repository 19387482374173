import { useMemo } from 'react';
import { DictionaryData } from '../../../components/Admin/Dictionaries/DictionariesTypes';
import { useGetAbstractContent } from './useGetAbstractContent';

export const useDictionarySelectOptions = <T,>(
  dictionaryData: Pick<
    DictionaryData,
    'apiMethod' | 'dataListName' | 'name' | 'idName'
  >
) => {
  const { data: dictionaryList, isLoading } = useGetAbstractContent<{
    content: T[];
  }>(dictionaryData.apiMethod + '?size=999', dictionaryData.dataListName!);
  const options = useMemo(
    () =>
      dictionaryList?.content.map((dictionary) => ({
        value: dictionary[dictionaryData.idName!],
        item: dictionary[dictionaryData.name],
        label: dictionary[dictionaryData.name],
        data: dictionary,
      })) ?? [],
    [dictionaryList]
  );
  return {
    options,
    loading: isLoading,
    showSearch: true,
    optionFilterProp: 'label',
  };
};

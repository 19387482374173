import { ResponseData } from "../../utils/API/APITypes";
import { TitleMark } from "../../components/Workplace/AGPP/Electrican/TitleMark/TitleMarkTypes";

const SET_DATA_TITLE_MARK = 'SET_DATA_TITLE_MARK';
const SET_CONTENT_TITLE_MARK = 'SET_CONTENT_TITLE_MARK';

export declare type DataTitleMark = {
  success: boolean;
  marksDataList: ResponseData<TitleMark>;
};

export declare type TitleMarkReducer = {
  data: DataTitleMark | null;
  content: Array<TitleMark> | null;
};

/**
 * @param {DataTitleMark} data
 * @return {any}
 */
export function setDataTitleMark(data) {
  return {
    type: SET_DATA_TITLE_MARK,
    payload: data,
  };
}

/**
 * @param {Array<DataEwMarks>} data
 * @return {any}
 */
export function setContentTitleMark(data) {
  return {
    type: SET_CONTENT_TITLE_MARK,
    payload: data,
  };
}

const initialState: TitleMarkReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function titleMarkReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TITLE_MARK:
      return { ...state, data: action.payload };
    case SET_CONTENT_TITLE_MARK:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import React from 'react';
import {
  ExcelUploadAbstractClassProps,
  ExcelUploadAbstractClassState,
} from './ExcelUploadAbstractClassTypes';
import { InboxOutlined } from '@ant-design/icons';
import { GetProp, Modal, notification, Upload, UploadProps } from 'antd';
import { withTranslation } from 'react-i18next';
import CollapsibleModal from '../../CollapsibleModal/CollapsibleModal';
import { KeycloakApp } from '../../Keycloak/Keycloak';
import API from '../../API/API';

/**
 * @class
 * @extends React.Component
 */
class ExcelUploadAbstractClass extends React.Component<
  ExcelUploadAbstractClassProps,
  ExcelUploadAbstractClassState
> {
  /**
   * @param {ExcelUploadAbstractClassProps} props
   */
  constructor(props: ExcelUploadAbstractClassProps) {
    super(props);
    this.state = {
      additionalData: null,
      isAdditionalData: false,
    };
  }

  /**
   * @param {boolean} value
   */
  setVisibleAdditionalData = (value: boolean) => {
    this.setState({ isAdditionalData: value });
  };

  /**
   * @return {JSX}
   */
  render() {
    const {
      isVisible,
      setIsVisible,
      fileName,
      uri,
      t,
      extraFormData,
      customRequest,
      title,
      accept,
    } = this.props;
    const { isAdditionalData, additionalData } = this.state;

    const uploadSettings: UploadProps = {
      name: fileName,
      multiple: true,
      headers: {
        Authorization: `Bearer ${KeycloakApp.token}`,
      },
      beforeUpload: (
        file: Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]
      ) => {
        const isJpgOrPng = accept ? accept.includes(file.type) : true;
        if (!isJpgOrPng) {
          notification.error({ message: t('Unsupported format') });
          return false;
        }
        return true;
      },
      onChange: (file) => {
        if (file.file.status === 'error') {
          notification.error({
            message: file.file.response.message ?? 'Error',
          });
        }
        if (file.file.status === 'done') {
          this.props.afterDone?.();
        }
      },
      action: uri,
      accept,
      customRequest:
        extraFormData || customRequest
          ? (info) => {
              const formData = new FormData();
              formData.append(fileName, info.file);

              // Для загрузки файлов в Explorer
              if (extraFormData) {
                for (const formDatum in extraFormData) {
                  if (extraFormData.hasOwnProperty(formDatum)) {
                    const uploadFileRequest = new Blob(
                      [JSON.stringify(extraFormData[formDatum])],
                      {
                        type: 'application/json',
                      }
                    );
                    formData.append(formDatum, uploadFileRequest);
                  }
                }
              }
              API.post(uri, formData, {
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                notNotification: true,
              })
                .then((response) => {
                  if (customRequest) {
                    customRequest(response);
                  }
                })
                .then(() => {
                  setIsVisible(false);
                })
                .catch(() => {
                  setIsVisible(false);
                });
            }
          : undefined,
    };
    return (
      <div>
        <Modal
          footer={false}
          title={title ?? t('Upload data')}
          onCancel={() => setIsVisible(false)}
          open={isVisible}
          destroyOnClose
          getContainer={false}
        >
          <Upload.Dragger {...uploadSettings}>
            <p className="ant-upload-text">
              {t('Drag documents here')} <InboxOutlined />
            </p>
          </Upload.Dragger>
        </Modal>

        {isAdditionalData && (
          <CollapsibleModal
            title={t('Positions with error')}
            onCancel={() => this.setVisibleAdditionalData(false)}
            visible={isAdditionalData}
          >
            {additionalData.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </CollapsibleModal>
        )}
      </div>
    );
  }
}

export default withTranslation()(ExcelUploadAbstractClass);

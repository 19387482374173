const ADD_DOWNLOAD_FILE = 'ADD_DOWNLOAD_FILE';
const DELETE_FILE = 'DELETE_FILE';

export declare type AbstractClassDataReducer = {
  downloadList: Record<string, AbortController>;
};

/**
 * @param {string} path
 * @return {any}
 */
export function deleteDownloadFile(path: string) {
  return {
    type: DELETE_FILE,
    payload: path,
  };
}

/**
 * @param {string} path
 * @param {AbortController} controller
 * @return {any}
 */
export function addDownloadFile(path: string, controller: AbortController) {
  return {
    type: ADD_DOWNLOAD_FILE,
    payload: [path, controller],
  };
}

const initialState: AbstractClassDataReducer = {
  downloadList: {},
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function abstractClassDataReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DOWNLOAD_FILE:
      return {
        ...state,
        downloadList: {
          ...state.downloadList,
          [action.payload[0]]: action.payload[1],
        },
      };
    case DELETE_FILE:
      // eslint-disable-next-line no-unused-vars
      const { [action.payload]: removed, ...rest } = state.downloadList;
      return {
        ...state,
        downloadList: rest,
      };
    default:
      return state;
  }
}

import axios from 'axios';

export const errorRequest = async ({
  values,
  personFullName,
  token,
  message,
}) => {
  return axios.post(
    'https://backtaskboard.weldnet.ru/api/tasks',
    {
      title: values.title,
      description: `Ссылка
-----------
${window.location.href}

Ошибка
-----------
${message}

Автор
-----------
${personFullName}

Браузер
-----------
${navigator.userAgent}`,
      authorId: 4,
      columnId: 102,
      memberIdList: [4],
    },
    {
      headers: {
        authorization: 'Bearer ' + token,
      },
    }
  );
};

import { inspectorsData } from '../Extra/DictionariesData';
import { DictionaryData } from '../DictionariesTypes';
import EditInspector from './EditInspector';
import React from 'react';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';

export const qcInspectorsAgccData: DictionaryData = {
  ...inspectorsData,
  pageHeaderExtra: undefined,
  idName: 'id',
  editComponent: <EditInspector idName={'id'} />,
  popupList: getPopupListDeleteId,
};

import {
  Button,
  Form,
  notification,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { KeycloakApp } from '../../../../../utils/Keycloak/Keycloak';
import { useUpdateTaskMutation } from '../../../../../store/yambookApi';
import { saveFile } from '../../../../../utils/Base/helpers/saveFile';
import { UploadChangeParam } from 'antd/es/upload';

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const FormFiles = ({
  task,
}: {
  task: {
    fileList: { file: any; fileId: number }[];
    id: number;
  };
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const url = `https://backtaskboard.weldnet.ru/api`;
  const token = KeycloakApp.token;
  const [update, { isLoading }] = useUpdateTaskMutation();
  const [fileList, setFileList] = useState<UploadFile[]>(() =>
    task.fileList.map(({ file }) => ({
      uid: file.id.toString(),
      name: file.fileName.slice(4),
      url: `${url}/filesystem/${file.id}`,
      type: file.contentType,
    }))
  );

  const onPreview = async (file: UploadFile) => {
    const fileResponse = (
      await axios.get(`${url}/filesystem/${file.uid}`, {
        headers: {
          authorization: 'Bearer ' + token,
        },
        responseType: 'arraybuffer',
      })
    ).data;
    saveFile(fileResponse, file.name);
  };

  const onChange = (file: UploadChangeParam) => {
    if (file.file.status === 'done') {
      setFileList(
        file.fileList.map((item) =>
          item.response
            ? {
                uid: item.response.data.id.toString(),
                name: item.response.data.fileName,
                url: `${url}/filesystem/${item.response.data.id}`,
                type: item.response.data.contentType,
              }
            : item
        )
      );

      form.submit();
    }
  };
  const onRemove = (file: UploadFile) => {
    axios
      .delete(`${url}/filesystem/${file.uid}`, {
        headers: {
          authorization: 'Bearer ' + token,
        },
      })
      .catch(() => notification.error({ message: 'Ошибка удаления файла' }));
  };

  const uploadProps: UploadProps = {
    name: 'file',
    action: `https://backtaskboard.weldnet.ru/api/filesystem`,
    headers: {
      authorization: 'Bearer ' + (token ?? ''),
    },
    fileList,
    multiple: true,
    onPreview: onPreview,
    onChange: onChange,
    onRemove: onRemove,
  };

  const onFinish = (values: { fileList: UploadFile[] }) => {
    const fileIds = values.fileList
      .filter(
        (file) =>
          !task.fileList.find((defaultFile) => defaultFile.fileId === +file.uid)
      )
      .map((file) => +file.uid);
    update({ taskId: task.id, body: { fileList: fileIds } }).then(() => {
      notification.success({
        message: t('The file was successfully attached to the request'),
      });
    });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      fields={[{ value: fileList, name: 'fileList' }]}
    >
      <Form.Item
        noStyle
        name={'fileList'}
        label=""
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload style={{ display: 'flex' }} {...uploadProps}>
          <Button loading={isLoading}>{t('Attach file')}</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

import React from 'react';
import Explorer from '../../../../utils/Components/Explorer/Explorer';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { useAppSelector } from '../../../../store/rootReducer';

const RegulatoryDocuments = () => {
  const { t } = useTranslation();
  const { darkTheme } = useAppSelector((state) => state.authReducer);
  return (
    <>
      <PageHeader
        title={
          <span
            style={{
              color: darkTheme ? 'rgba(255, 255, 255, 0.85)' : undefined,
            }}
          >
            {t('Regulatory documents')}
          </span>
        }
      />
      <Explorer
        api={'api/regulatory-documentation/directory'}
        dataListName="directoryListingData"
        apiDownload={'api/regulatory-documentation/file?'}
        idName={''}
        rootName={'🏠'}
        uploadName="request"
      />
    </>
  );
};

export default RegulatoryDocuments;

import { DictionaryData, OnceRecordProps } from './DictionariesTypes';
import { getInitialColumns, getSearchFields } from './DictionariesExtra';
import { getPopupListDeleteId } from '../../../utils/Popup/PopupExtra';

const api = 'api/welding-card/consumable-type';
const idName = 'id';
const name = 'title';

export const consumablesTypeData: DictionaryData = {
  apiMethod: api,
  dataListName: 'consumableTypeDataList',
  idName: idName,
  pageHeaderTitle: 'Consumable types',
  tabNameField: name,
  columnsName: 'ConsumableTypeTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupListDeleteId,
};

export const onceConsumablesTypeProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'consumableTypeData',
  name: name,
};

import React, { lazy, Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import App from '../../App';
import WorkspaceLayout from '../../components/Layouts/WorkspaceLayout';
import { routerPermissionSetter } from '../Base/helpers/permissionHelpers';
import { useAppSelector } from '../../store/rootReducer';
import { router } from './RouterExtra';
import { routerAGCC } from './RouterExtraAGCC';
import Registry from '../../components/Registry/Registry';
import Enter from '../../components/Enter/Enter';
import NotFoundPage from '../Components/NotFoundPage/NotFoundPage';
import { KeycloakApp } from '../Keycloak/Keycloak';
import { SpinPage } from '../Components/SpinPage/SpinPage';

const DepartmentControl = lazy(
  () => import('../../components/Workplace/DepartmentControl')
);

/**
 * @constructor
 * @return {JSX}
 */
export function Router() {
  const currentRouter = [...router, ...routerAGCC];

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<App />}>
        {[
          ...(currentRouter?.map((route, index) => (
            <Route
              key={route.path}
              path={`${index < router.length ? 'agpp' : 'agcc'}/${route.path}`}
              element={
                <ElementWrapper
                  name={route.elementWrapperProps.name}
                  permission={route.elementWrapperProps.permission ?? []}
                >
                  {route.element}
                </ElementWrapper>
              }
            />
          )) ?? []),

          <Route
            path={'registry'}
            key={'registry'}
            element={
              <RegistryWrapper>
                <Registry />
              </RegistryWrapper>
            }
          />,
          <Route path={'enter'} key={'enter'} element={<Enter />} />,
        ]}
        <Route
          index
          element={
            <ElementWrapper name="Рабочее пространство" permission={[]}>
              <DepartmentControl />
            </ElementWrapper>
          }
        />
        <Route path={'/someurl'} element={<Navigate to={'/'} />} />
        <Route path={'/agcc'} element={<Navigate to={'/'} />} />
        <Route path={'/agpp'} element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  );
}

/**
 * @param {string} name
 * @param {React.ReactNode} children
 * @param {string[]} permission
 * @return {JSX}
 */
const ElementWrapper = ({
  name,
  children,
  permission,
}: {
  name: string;
  children: React.ReactNode;
  permission: Array<string>;
}) => {
  const profile = useAppSelector((state) => state.authReducer.profile);
  const permissionList = useAppSelector(
    (state) => state.authReducer.permission
  );
  const navigate = useNavigate();
  const PROJECT = localStorage.getItem('project');
  const location = useLocation();

  const locationProj = location.pathname.split('/')[1];

  useEffect(() => {
    if (!PROJECT) navigate('/enter');
  });

  const rightRoute =
    locationProj === PROJECT || !locationProj || locationProj === '';

  return rightRoute ? (
    routerPermissionSetter(permission, permissionList, profile) ? (
      <WorkspaceLayout name={name} permission={permission}>
        {children}
      </WorkspaceLayout>
    ) : (
      <Suspense>
        <Navigate to={`/`} />
      </Suspense>
    )
  ) : (
    <NotFoundPage />
  );
};

const RegistryWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { profile, isFetch } = useAppSelector((state) => state.authReducer);

  const isNewUser =
    (!profile && localStorage.getItem('project')) ||
    process.env.NODE_ENV !== 'production';

  return KeycloakApp?.authenticated ? (
    isFetch ? (
      <SpinPage />
    ) : isNewUser ? (
      (children as React.ReactElement)
    ) : (
      <NotFoundPage />
    )
  ) : (
    <SpinPage />
  );
};

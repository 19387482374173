import { ResponseData } from "../../utils/API/APITypes";
import { TitleListType } from "../../components/Workplace/AGPP/TitleList/TitleListData";

const SET_DATA_TITLELIST = 'SET_DATA_TITLELIST';
const SET_CONTENT_TITLELIST = 'SET_CONTENT_TITLELIST';

export declare type dataTitleList = {
  success: boolean;
  titleListDataList: ResponseData<TitleListType>;
};

export declare type TitleListReducer = {
  data: dataTitleList | null;
  content: Array<TitleListType> | null;
};

/**
 * @param {dataTitleList} data
 * @return {any}
 */
export function setDataTitleList(data) {
  return {
    type: SET_DATA_TITLELIST,
    payload: data,
  };
}

/**
 * @param {Array<TitleListType>} data
 * @return {any}
 */
export function setContentTitleList(data) {
  return {
    type: SET_CONTENT_TITLELIST,
    payload: data,
  };
}

const initialState: TitleListReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function titlelistReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TITLELIST:
      return { ...state, data: action.payload };
    case SET_CONTENT_TITLELIST:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

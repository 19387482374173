import { organizationDepartmentData } from '../../components/Admin/Departments/DepartamentsData';
import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_DEPARTMENTS = 'SET_DATA_DEPARTMENTS';
const SET_CONTENT_DEPARTMENTS = 'SET_CONTENT_DEPARTMENTS';

export declare type dataDepartments = {
  success: boolean;
  organizationDepartmentDataList: ResponseData<organizationDepartmentData>;
};

export declare type DepartmentsReducer = {
  data: dataDepartments | null;
  content: Array<organizationDepartmentData> | null;
};

/**
 * @param {dataDepartments} data
 * @return {any}
 */
export function setDataDepartments(data) {
  return {
    type: SET_DATA_DEPARTMENTS,
    payload: data,
  };
}

/**
 * @param {Array<organizationDepartmentData>} data
 * @return {any}
 */
export function setContentDepartments(data) {
  return {
    type: SET_CONTENT_DEPARTMENTS,
    payload: data,
  };
}

const initialState: DepartmentsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function departmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_DEPARTMENTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_DEPARTMENTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { Dictionary } from '../../AppTypes';

const SET_DATA_TYPES_OF_WORK_RFI = 'SET_DATA_TYPES_OF_WORK_RFI';
const SET_CONTENT_TYPES_OF_WORK_RFI = 'SET_CONTENT_TYPES_OF_WORK_RFI';

export declare type DataTypesOfWorkRFI = {
  success: boolean;
  typesOfWorksDataList: ResponseData<Dictionary>;
};

export declare type TypesOfWorkRFIReducer = {
  data: DataTypesOfWorkRFI | null;
  content: Array<Dictionary> | null;
};

/**
 * @param {Array<Dictionary>} data
 * @return {any}
 */
export function setContentTypesOfWorkRFI(data) {
  return {
    type: SET_CONTENT_TYPES_OF_WORK_RFI,
    payload: data,
  };
}

const initialState: TypesOfWorkRFIReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function typesOfWorkRFIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_TYPES_OF_WORK_RFI:
      return { ...state, data: action.payload };
    case SET_CONTENT_TYPES_OF_WORK_RFI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import API from '../API/API';
import store from '../../store/store';
import { setContentDepartments } from '../../store/reducers/DepartmentsReducer';

export const getDepartments = () => {
  const api = 'api/organization/department?size=99';
  return API.get(api).then((res) => {
    store.dispatch(
      setContentDepartments(res.data.organizationDepartmentDataList.content)
    );
  });
};

import React from 'react';
import { NewFormProps } from '../../../../AppTypes';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormTitle = React.memo(
  ({
    currentRow,
    isFetching,
    onFinishAdd,
    idName,
    onFinishEdit,
    type,
    titleName = 'title',
  }: NewFormProps<any> & { idName: string; titleName?: string }) => {
    const { t } = useTranslation();
    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!(values)
        : onFinishEdit!({ ...values, [idName]: currentRow![idName] });
    };
    return (
      <Form onFinish={onFinish} layout={'vertical'} initialValues={currentRow}>
        <Form.Item name={titleName} label={t('Name_1')}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} htmlType={'submit'} loading={isFetching}>
            {t('Save')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
FormTitle.displayName = 'FormTitle';
export default FormTitle;

import React from 'react';
import { PdfViewer } from './Viewer';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Alert, Button, Spin, Tag, Tooltip } from 'antd';
import {
  CloudDownloadOutlined,
  PrinterOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import printJS from 'print-js';
import {
  PDFViewerMainClassPropsTypes,
  PDFViewerMainClassStateTypes,
} from './types/PDFViewerTypes';
import { KeycloakApp } from '../Keycloak/Keycloak';
import { withTranslation } from 'react-i18next';
import { TIMEOUT_TOKEN } from '../Base/constants/TIMEOUT_TOKEN';

/**
 * @class
 * @extends React.Component
 */
class PDFTron extends React.Component<
  PDFViewerMainClassPropsTypes,
  PDFViewerMainClassStateTypes
> {
  private mainDiv: React.RefObject<any>;
  /**
   * @param {PDFViewerMainClassPropsTypes} props
   */
  constructor(props) {
    super(props);
    this.state = {
      numPages: 0,
      numPagesArr: [1],
      scale: 1.5,
      pdf: null,
      visibleMenu: false,
      isRender: false,
      rotate: 0,
    };

    this.mainDiv = React.createRef<HTMLDivElement>();

    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  }

  /**
   * Метод для получения данных
   */
  async componentDidMount() {
    if (KeycloakApp?.isTokenExpired(TIMEOUT_TOKEN)) {
      await KeycloakApp.updateToken(TIMEOUT_TOKEN);
    }

    const loadingTask = pdfjsLib.getDocument({
      url: this.props.api.replaceAll('+', '%2B'),
      cMapPacked: true,
      httpHeaders: { Authorization: `Bearer ${KeycloakApp.token}` },
    });

    await loadingTask.promise
      .then((loadedPdf) => {
        const numPagesArr: Array<number> = [];
        for (let i = 1; i <= loadedPdf.numPages; i++) {
          numPagesArr.push(i);
        }
        this.setState(
          {
            pdf: loadedPdf,
            numPages: loadedPdf.numPages,
            numPagesArr: numPagesArr,
          },
          () => {
            this.mainDiv.current?.addEventListener('wheel', (event) => {
              this.onWheelEvent(event);
            });
          }
        );
      })
      .catch((error) => {
        this.setState({ pdf: undefined });
      });
  }

  /**
   * @param {WheelEvent} event
   */
  onWheelEvent = (event: WheelEvent) => {
    if (event.ctrlKey) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.increaseScale();
      } else {
        this.reduceScale();
      }
    }
  };

  increaseScale = () => {
    this.setState((state) => {
      if (state.scale + 0.2 >= 3) {
        return { scale: state.scale };
      }
      return { scale: state.scale + 0.2 };
    });
  };

  reduceScale = () => {
    this.setState(
      (state) => {
        if (state.scale - 0.2 <= 0) {
          return { scale: state.scale };
        }
        return { scale: state.scale - 0.2 };
      },
      () => {
        this.render();
      }
    );
  };

  printDocument = async () => {
    const data = await this.state.pdf!.getData();
    const blob = new Blob([data], { type: 'application/pdf' });
    printJS(URL.createObjectURL(blob));
  };

  downloadDocument = async () => {
    if (KeycloakApp?.isTokenExpired(5)) {
      await KeycloakApp.updateToken(5);
    }

    await fetch(this.props.api, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${KeycloakApp.token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${this.props.fullName}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.log('error', error));
  };

  /**
   * @return {JSX}
   */
  render() {
    const { pdf, scale, isRender, rotate } = this.state;
    const { t } = this.props;

    if (pdf === null) {
      return (
        <div className="example">
          <Spin tip="Загрузка..." />
        </div>
      );
    } else if (pdf === undefined) {
      return (
        <Alert message={t!('Unsupported format')} type={'error'} showIcon />
      );
    } else {
      return (
        <div>
          <div className="pdfViewerTools" style={{ position: 'fixed' }}>
            <Tooltip title="Приблизить">
              <Button
                shape="circle"
                icon={<ZoomInOutlined />}
                onClick={() => this.increaseScale()}
              />
            </Tooltip>
            <Tooltip title="Отдалить">
              <Button
                shape="circle"
                icon={<ZoomOutOutlined />}
                onClick={() => this.reduceScale()}
              />
            </Tooltip>
            <Tooltip title="Повернуть">
              <Button
                shape="circle"
                icon={<RotateLeftOutlined />}
                onClick={() => {
                  this.setState({
                    rotate: rotate >= 90 ? rotate - 90 : rotate === 0 ? 270 : 0,
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Повернуть">
              <Button
                shape="circle"
                icon={<RotateRightOutlined />}
                onClick={() => {
                  this.setState({ rotate: rotate <= 270 ? rotate + 90 : 0 });
                }}
              />
            </Tooltip>
            <Tooltip title="Напечатать документ">
              <Button
                shape="circle"
                icon={<PrinterOutlined />}
                onClick={() => this.printDocument()}
              />
            </Tooltip>
            <Tooltip title="Скачать документ">
              <Button
                shape="circle"
                icon={<CloudDownloadOutlined />}
                onClick={() => this.downloadDocument()}
              />
            </Tooltip>
            <div>
              <Tag color="green">{Math.round(scale * 33)}%</Tag>/
              <Tag color="green">100%</Tag>
            </div>
          </div>
          {!isRender && (
            <div ref={this.mainDiv} style={{ minHeight: '100vh' }}>
              {this.state.numPagesArr.map((i, index) => (
                <div style={{ marginBottom: 20 }} key={index}>
                  <PdfViewer page={i} pdf={pdf} scale={scale} rotate={rotate} />
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
  }
}

export default withTranslation()(PDFTron);

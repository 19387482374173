import { ResponseData } from '../../utils/API/APITypes';
import { IsoListType } from '../../components/Workplace/AGPP/IsoList/IsoListData';

const SET_DATA_ISOLIST = 'SET_DATA_ISOLIST';
const SET_CONTENT_ISOLIST = 'SET_CONTENT_ISOLIST';

export declare type dataIsolist = {
  success: boolean;
  isoListDataList: ResponseData<IsoListType>;
};

export declare type IsoListReducer = {
  data: dataIsolist | null;
  content: Array<IsoListType> | null;
};

/**
 * @param {dataIsolist} data
 * @return {any}
 */
export function setDataIsoList(data) {
  return {
    type: SET_DATA_ISOLIST,
    payload: data,
  };
}

/**
 * @param {Array<IsoListType>} data
 * @return {any}
 */
export function setContentIsoList(data) {
  return {
    type: SET_CONTENT_ISOLIST,
    payload: data,
  };
}

const initialState: IsoListReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function isolistReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ISOLIST:
      return { ...state, data: action.payload };
    case SET_CONTENT_ISOLIST:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { PlusOutlined } from '@ant-design/icons';
import {
  AbstractClassPageHeaderExtraButtonItem,
  AbstractColumns,
} from '../../../utils/Components/AbstractClass/AbstractClassTypes';
import React from 'react';
import { SearchFieldsType } from '../../../utils/SearchPanel/types/SearchPanelTypes';

export const getInitialColumnsPwhtOperator = (t): AbstractColumns => [
  {
    title: t('Name'),
    dataIndex: 'full_name',
    key: 'stamp',
    align: 'center',
    visible: true,
  },
  {
    title: t('Stamp_1'),
    dataIndex: 'stamp',
    key: 'stamp',
    align: 'center',
    sorter: true,
    visible: true,
  },
];

export const getSearchFieldsPwhtOperator = (t): SearchFieldsType[] => [
  {
    component: 'Input',
    label: t('Name'),
    name: 'fullName',
    placeholder: '',
    required: false,
    span: 24,
  },
];

export const pageHeaderExtra: Array<AbstractClassPageHeaderExtraButtonItem> = [
  {
    type: 'primary',
    icon: <PlusOutlined />,
    onClick: {
      type: 'innerFunction',
      func: 'setIsAdd',
      extra: true,
    },
  },
];

import React from 'react';
import { Card, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeycloakApp } from '../../utils/Keycloak/Keycloak';
import AccountForm from './components/AccountForm';

const Registry = React.memo(() => {
  const { t } = useTranslation();
  const name = KeycloakApp.idTokenParsed?.name;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)',
      }}
    >
      <Card
        title={
          <Row>
            <>
              {t('Create account')} {name}
            </>
          </Row>
        }
        style={{ width: '800px' }}
      >
        <AccountForm type={'add'} />
      </Card>
    </div>
  );
});
Registry.displayName = 'Registry';
export default Registry;

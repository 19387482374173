import { NewPageData } from '../../../../../AppTypes';

export const ResponsibleData: NewPageData = {
  apiMethod: 'api/rfi-responsible',
  dataListName: 'rfiResponsibleList',
  idName: 'rfiResponsibleId',
  columnsName: 'ResponsibleRFITable',
  pageHeaderTitle: 'Responsible_1',
  tabNameField: 'fullName',
};

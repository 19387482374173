import React, { lazy } from 'react';
import {
  blockFactorsDescriptions,
  constructionDirectionData,
  elementTypesData,
  ewFolderLocation,
  ewFolderStatuses,
  ewKQ,
  ewMarks,
  inspectorsData,
  installationsData,
  jointTypesData,
  onceBlockFactorsProps,
  onceConstructionDirectionProps,
  onceElementTypeProps,
  onceEwFolderStatusesProps,
  onceEwMarksProps,
  onceFolderLocationProps,
  onceInstallationsProps,
  onceJointTypesProps,
  onceKqProps,
  oncePipelineCategoryProps,
  oncePipelineMaterialsProps,
  onceReportTypeOfWorksProps,
  onceTemperatureProps,
  onceTestPacketAccountStatusesProps,
  onceTestPacketStatusesProps,
  onceTypesOfLaboratoriesProps,
  onceTypesOFLaboratoryProps,
  onceTypesOfSupportProps,
  onceUnitOfMeasurementProps,
  onceWelderOnJointStatusProps,
  onceWeldingLocationProps,
  onceWeldingMethodsProps,
  onceWeldingPositionProps,
  pipelineCategoryData,
  pipelineMaterialsData,
  pwhtOperatorData,
  reportTypeOfWorksData,
  temperatureData,
  testPacketAccountStatuses,
  testPacketStatusesData,
  typeOfLaboratories,
  typesLaboratoryDescriptions,
  typesOfSupportData,
  unitsOfMeasureData,
  weldingLocationsData,
  weldingMethodsData,
  weldingPositionDescriptions,
} from '../../components/Admin/Dictionaries/Extra/DictionariesData';
import {
  commentRfiStatusData,
  onceCommentRfiStatusProps,
} from '../../components/Admin/Dictionaries/Extra/commentRfiStatusData';
import {
  onceStatusStatusProps,
  statusStatusFolderData,
} from '../../components/Admin/Dictionaries/Extra/StatusStatusFolder';
import {
  wdbDescription,
  wlgDescription,
} from '../Descriptions/DescriptionObjects';
import {
  factoryNumbersData,
  factoryNumbersProps,
} from '../../components/Admin/Dictionaries/Extra/FactoryData';
import {
  areaAgppData,
  onceAreaAgppProps,
} from '../../components/Workplace/AGPP/Electrican/helpers/areaAgppData';
import Profile from '../../components/Workplace/Profile/Profile';
import RegulatoryDocuments from '../../components/Workplace/AGCC/RegulatoryDocuments/RegulatoryDocuments';

const EwStatistic = React.lazy(
  () =>
    import('../../components/Workplace/AGPP/Electrican/Statistic/EwStatistic')
);
const OnceStatistic = React.lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Electrican/Statistic/OnceStatistic/OnceStatistic'
    )
);
const PassportSpecification = React.lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/PassportSpecification/PassportSpecification'
    )
);
const OncePassportSpecification = React.lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/PassportSpecification/OncePassportSpecification/OncePassportSpecification'
    )
);

const Updates = lazy(
  () => import('../../components/Super admin/Updates/Updates')
);
const Folders = lazy(
  () => import('../../components/Workplace/AGPP/Electrican/Folders/Folders')
);
const OnceUpdate = lazy(
  () => import('../../components/Super admin/Updates/OnceUpdate')
);

const TitleMark = lazy(
  () => import('../../components/Workplace/AGPP/Electrican/TitleMark/TitleMark')
);
const OnceTitleMark = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Electrican/TitleMark/OnceTitleMark/OnceTitleMark'
    )
);
const EwBasePhase = lazy(
  () =>
    import('../../components/Workplace/AGPP/Electrican/BasePhase/EwBasePhase')
);
const OnceFolder = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Electrican/Folders/OnceFolder/OnceFolder'
    )
);

const Equipments = lazy(
  () =>
    import('../../components/Workplace/AGPP/Laboratories/Equipments/Equipments')
);
const OnceEquipments = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Laboratories/Equipments/OnceEquipments'
    )
);
const Employees = lazy(
  () =>
    import('../../components/Workplace/AGPP/Laboratories/Employees/Employees')
);
const LabRequests = lazy(
  () =>
    import('../../components/Workplace/AGPP/Laboratories/Requests/LabRequests')
);
const OnceEmployees = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Laboratories/Employees/OnceEmployees'
    )
);
const OnceLabRequest = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Laboratories/Requests/OnceLabRequest'
    )
);

const Accounts = lazy(() => import('../../components/Admin/Accounts/Accounts'));
const TypeOfOrganizations = lazy(
  () => import('../../components/Admin/TypeOfOrganizations/TypeOfOrganizations')
);
const OnceOrganization = lazy(
  () =>
    import(
      '../../components/Admin/Organizations/OneOrganizationComponent/OnceOrganization'
    )
);
const AccountInfo = lazy(
  () => import('../../components/Admin/Accounts/AccountInfo/AccountInfo')
);
const Departments = lazy(
  () => import('../../components/Admin/Departments/Departments')
);
const OnceDepartment = lazy(
  () =>
    import(
      '../../components/Admin/Departments/OnceDepartmentComponent/OnceDepartment'
    )
);
const Positions = lazy(
  () => import('../../components/Admin/Positions/Positions')
);
const OncePosition = lazy(
  () =>
    import(
      '../../components/Admin/Positions/OncePositionComponent/OncePosition'
    )
);
const TitleList = lazy(
  () => import('../../components/Workplace/AGPP/TitleList/TitleList')
);
const OnceTitle = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/TitleList/OnceTitleComponent/OnceTitle'
    )
);
const TestPacks = lazy(
  () => import('../../components/Workplace/AGPP/TestPacks/TestPacks')
);
const OnceTestPackComponent = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/TestPacks/OnceTestPackComponent/OnceTestPackComponentF'
    )
);
const LineList = lazy(
  () => import('../../components/Workplace/AGPP/LineList/LineList')
);
const OnceLineListComponent = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/LineList/OnceLineListComponent/OnceLineListComponent'
    )
);
const IsoList = lazy(
  () => import('../../components/Workplace/AGPP/IsoList/IsoList')
);
const OnceIso = lazy(
  () =>
    import('../../components/Workplace/AGPP/IsoList/OnceIsoComponent/OnceIsoF')
);
const AoRPI = lazy(() => import('../../components/Workplace/AGPP/AoRPI/AoRPI'));
const Consumables = lazy(
  () => import('../../components/Workplace/AGPP/Consumables/Consumables')
);
const OnceConsumable = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Consumables/OnceConsumableComponent/OnceConsumable'
    )
);
const OnceAoRPI = lazy(
  () => import('../../components/Workplace/AGPP/AoRPI/OnceAoRPI/OnceAoRPI')
);
const ProductReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/ProductReportElement/ProductReportElement'
    )
);
const OnceReportElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/ProductReportElement/OnceProductReportElement/OnceReportElement'
    )
);
const Welders = lazy(
  () => import('../../components/Workplace/AGPP/Welders/Welders')
);
const OnceWelder = lazy(
  () => import('../../components/Workplace/AGPP/Welders/OnceWelder/OnceWelderF')
);
const OnceRecord = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceRecord')
);
const BasePhase = lazy(
  () => import('../../components/Workplace/AGPP/BasePhase/BasePhase')
);
const OnceBasePhaseElement = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/BasePhase/OnceBasePhaseElement/OnceBasePhaseElement'
    )
);
const OnceTypeOfOrganization = lazy(
  () =>
    import(
      '../../components/Admin/TypeOfOrganizations/OnceTypeOfOrganization/OnceTypeOfOrganization'
    )
);
const WDB = lazy(() => import('../../components/Workplace/AGPP/WDB/WDB'));
const OnceWDB = lazy(
  () => import('../../components/Workplace/AGPP/WDB/OnceWDB/OnceWDB')
);
const Weldlog = lazy(
  () => import('../../components/Workplace/AGPP/Weldlog/Weldlog')
);
const WeldLogGroup = lazy(
  () => import('../../components/Workplace/AGPP/WeldLogGroup/WeldLogGroup')
);
const OnceJoint = lazy(
  () => import('../../components/Workplace/AGPP/Weldlog/OnceJoint/OnceJoint')
);
const NDT = lazy(() => import('../../components/Workplace/AGPP/NDT/NDT'));
const OnceInspector = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceInspector')
);
const Specifications = lazy(
  () => import('../../components/Workplace/AGPP/Specifications/Specifications')
);
const OnceSpecification = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Specifications/OnceSpecificationComponent/OnceSpecification'
    )
);
const OnceTemperature = lazy(
  () => import('../../components/Admin/Dictionaries/Components/OnceTemperature')
);
const OncePwthOperator = lazy(
  () =>
    import('../../components/Admin/Dictionaries/Components/OncePwthOperator')
);
const DictionaryComponent = lazy(
  () => import('../../components/Admin/Dictionaries/DictionaryComponent')
);
const Organizations = lazy(
  () => import('../../components/Admin/Organizations/Organizations')
);
const Permissions = lazy(
  () => import('../../components/Super admin/Permissions/Permissions')
);
const Passports = lazy(
  () => import('../../components/Workplace/AGPP/Passports/Passports')
);
const OncePassport = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Passports/OncePassport/OncePassportF'
    )
);
const WelderOnJointStatus = lazy(
  () => import('../../components/Admin/Dictionaries/WelderOnJointStatus')
);
const OnceRfi = lazy(
  () => import('../../components/Workplace/AGPP/Electrican/RFI/OnceRFI/OnceRfi')
);
const Rfi = lazy(
  () => import('../../components/Workplace/AGPP/Electrican/RFI/RFI')
);
const OnceEwBasePhase = lazy(
  () =>
    import(
      '../../components/Workplace/AGPP/Electrican/BasePhase/OnceEwBasePhase/OnceBasePhase'
    )
);

const getCrudPermission = (name: string) => {
  const rawName = name[0].toUpperCase() + name.slice(1);
  return [
    `get${rawName}DataList`,
    `delete${rawName}`,
    `create${rawName}`,
    `update${rawName}`,
  ];
};

export const router = [
  {
    path: 'organizations',
    // @ts-ignore
    element: <Organizations />,
    elementWrapperProps: {
      name: 'Organizations',
    },
  },
  {
    path: 'organizations/:id',
    element: <OnceOrganization />,
    elementWrapperProps: {
      name: 'Organizations',
    },
  },
  {
    path: 'departments',
    element: <Departments />,
    elementWrapperProps: {
      name: 'Departments',
    },
  },
  {
    path: 'departments/:id',
    element: <OnceDepartment />,
    elementWrapperProps: {
      name: 'Departments',
    },
  },
  {
    path: 'positions',
    element: <Positions />,
    elementWrapperProps: {
      name: 'Positions',
    },
  },
  {
    path: 'positions/:id',
    element: <OncePosition />,
    elementWrapperProps: {
      name: 'Positions',
    },
  },
  {
    path: 'types',
    element: <TypeOfOrganizations />,
    elementWrapperProps: {
      name: 'Organization types',
    },
  },
  {
    path: 'types/:id',
    element: <OnceTypeOfOrganization />,
    elementWrapperProps: {
      name: 'Organization types',
    },
  },
  {
    path: 'accounts',
    element: <Accounts />,
    elementWrapperProps: {
      name: 'Accounts',
    },
  },
  {
    path: 'accounts/:id',
    element: <AccountInfo />,
    elementWrapperProps: {
      name: 'Profile',
    },
  },
  {
    path: 'titles',
    element: <TitleList />,
    elementWrapperProps: {
      name: 'Titles',
      permission: ['TitleListController'],
    },
  },
  {
    path: 'titles/:id',
    element: <OnceTitle />,
    elementWrapperProps: {
      name: 'Titles',
      permission: ['TitleListController'],
    },
  },
  {
    path: 'testpacks',
    element: <TestPacks />,
    elementWrapperProps: {
      name: 'Test packs',
      permission: ['TestPacketController'],
    },
  },
  {
    path: 'testpacks/:id',
    element: <OnceTestPackComponent />,
    elementWrapperProps: {
      name: 'Test packs',
      permission: ['TestPacketController'],
    },
  },
  {
    path: 'linelist',
    element: <LineList />,
    elementWrapperProps: {
      name: 'Lines',
      permission: ['LineListController'],
    },
  },
  {
    path: 'linelist/:id',
    element: <OnceLineListComponent />,
    elementWrapperProps: {
      name: 'Lines',
      permission: ['LineListController'],
    },
  },
  {
    path: 'isolist',
    element: <IsoList />,
    elementWrapperProps: {
      name: 'Isometries',
      permission: ['IsoListController'],
    },
  },
  {
    path: 'isolist/:id',
    element: <OnceIso />,
    elementWrapperProps: {
      name: 'Isometries',
      permission: ['IsoListController'],
    },
  },
  {
    path: 'product-report',
    element: <AoRPI />,
    elementWrapperProps: {
      name: 'Product report',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'product-report/:id',
    element: <OnceAoRPI />,
    elementWrapperProps: {
      name: 'Product report',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'consumables',
    element: <Consumables />,
    elementWrapperProps: {
      name: 'Consumables',
      permission: ['ConsumableController'],
    },
  },
  {
    path: 'consumables/:id',
    element: <OnceConsumable />,
    elementWrapperProps: {
      name: 'Consumables',
      permission: ['ConsumableController'],
    },
  },
  {
    path: 'product-report-element',
    element: <ProductReportElement />,
    elementWrapperProps: {
      name: 'Product report elements',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'product-report-element/:id',
    element: <OnceReportElement />,
    elementWrapperProps: {
      name: 'Product report elements',
      permission: ['ProductReportController'],
    },
  },
  {
    path: 'welders',
    element: <Welders />,
    elementWrapperProps: {
      name: 'Welders',
      permission: ['WelderController'],
    },
  },
  {
    path: 'welders/:id',
    element: <OnceWelder />,
    elementWrapperProps: {
      name: 'Welders',
      permission: ['WelderController'],
    },
  },
  {
    path: 'element-types',
    element: <DictionaryComponent dictionaryData={elementTypesData} />,
    elementWrapperProps: {
      name: 'Element types',
      permission: getCrudPermission('ElementType'),
    },
  },
  {
    path: 'element-types/:id',
    element: <OnceRecord {...onceElementTypeProps} />,
    elementWrapperProps: {
      name: 'Element types',
      permission: getCrudPermission('ElementType'),
    },
  },
  {
    path: 'welding-locations',
    element: <DictionaryComponent dictionaryData={weldingLocationsData} />,
    elementWrapperProps: {
      name: 'Welding locations',
    },
  },
  {
    path: 'welding-locations/:id',
    element: <OnceRecord {...onceWeldingLocationProps} />,
    elementWrapperProps: {
      name: 'Welding locations',
    },
  },
  {
    path: 'welding-methods',
    element: <DictionaryComponent dictionaryData={weldingMethodsData} />,
    elementWrapperProps: {
      name: 'Welding methods',
    },
  },
  {
    path: 'welding-methods/:id',
    element: <OnceRecord {...onceWeldingMethodsProps} />,
    elementWrapperProps: {
      name: 'Welding methods',
    },
  },
  {
    path: 'joint-types',
    element: <DictionaryComponent dictionaryData={jointTypesData} />,
    elementWrapperProps: {
      name: 'Joint types',
    },
  },
  {
    path: 'joint-types/:id',
    element: <OnceRecord {...onceJointTypesProps} />,
    elementWrapperProps: {
      name: 'Joint types',
    },
  },
  {
    path: 'factory-numbers',
    element: <DictionaryComponent dictionaryData={factoryNumbersData} />,
    elementWrapperProps: {
      name: 'Factory Numbers',
      permission: getCrudPermission('FactoryNumber'),
    },
  },
  {
    path: 'factory-numbers/:id',
    element: <OnceRecord {...factoryNumbersProps} />,
    elementWrapperProps: {
      name: 'Factory Numbers',
      permission: getCrudPermission('FactoryNumber'),
    },
  },
  {
    path: 'measurement-unit',
    element: <DictionaryComponent dictionaryData={unitsOfMeasureData} />,
    elementWrapperProps: {
      name: 'Units of measure',
      permission: getCrudPermission('MeasurementUnit'),
    },
  },
  {
    path: 'measurement-unit/:id',
    element: <OnceRecord {...onceUnitOfMeasurementProps} />,
    elementWrapperProps: {
      name: 'Units of measure',
      permission: getCrudPermission('MeasurementUnit'),
    },
  },
  {
    path: 'support-types',
    element: <DictionaryComponent dictionaryData={typesOfSupportData} />,
    elementWrapperProps: {
      name: 'Type of support',
      permission: getCrudPermission('TypesSupportJoint'),
    },
  },
  {
    path: 'support-types/:id',
    element: <OnceRecord {...onceTypesOfSupportProps} />,
    elementWrapperProps: {
      name: 'Type of support',
      permission: getCrudPermission('TypesSupportJoint'),
    },
  },
  {
    path: 'temperature',
    element: <DictionaryComponent dictionaryData={temperatureData} />,
    elementWrapperProps: {
      name: 'Temperature',
    },
  },
  {
    path: 'temperature/:id',
    element: <OnceTemperature {...onceTemperatureProps} />,
    elementWrapperProps: {
      name: 'Temperature',
    },
  },
  {
    path: 'pwhtoperator',
    element: <DictionaryComponent dictionaryData={pwhtOperatorData} />,
    elementWrapperProps: {
      name: 'PWHT operators',
    },
  },
  {
    path: 'pwhtoperator/:id',
    element: <OncePwthOperator />,
    elementWrapperProps: {
      name: 'PWHT operators',
    },
  },
  {
    path: 'basephase',
    element: <BasePhase />,
    elementWrapperProps: {
      name: 'BasePhase',
      permission: ['BasePhaseController'],
    },
  },
  {
    path: 'basephase/:id',
    element: <OnceBasePhaseElement />,
    elementWrapperProps: {
      name: 'BasePhase',
      permission: ['BasePhaseController'],
    },
  },
  {
    path: 'wdb',
    element: <WDB />,
    elementWrapperProps: {
      name: 'WDB',
      permission: ['WdbController'],
    },
  },
  {
    path: 'wdb/:id',
    element: <OnceWDB type={'WDB'} descriptions={wdbDescription} />,
    elementWrapperProps: {
      name: 'WDB',
      permission: ['WdbController'],
    },
  },
  {
    path: 'weldlog',
    element: <Weldlog />,
    elementWrapperProps: {
      name: 'Weldlog',
      permission: ['WeldlogController'],
    },
  },
  {
    path: 'weldlog/:id',
    element: <OnceJoint />,
    elementWrapperProps: {
      name: 'Weldlog',
      permission: ['WeldlogController'],
    },
  },
  {
    path: 'wlg',
    element: <WeldLogGroup />,
    elementWrapperProps: {
      name: 'WLG',
      permission: ['WdbController'],
    },
  },
  {
    path: 'wlg/:id',
    element: <OnceWDB descriptions={wlgDescription} type={'WLG'} />,
    elementWrapperProps: {
      name: 'WLG',
      permission: ['WdbController'],
    },
  },
  {
    path: 'ndt',
    element: <NDT />,
    elementWrapperProps: {
      name: 'NDT',
      permission: ['NDTController'],
    },
  },
  {
    path: 'pipeline-materials',
    element: <DictionaryComponent dictionaryData={pipelineMaterialsData} />,
    elementWrapperProps: {
      name: 'Pipeline material',
    },
  },
  {
    path: 'pipeline-materials/:id',
    element: <OnceRecord {...oncePipelineMaterialsProps} />,
    elementWrapperProps: {
      name: 'Pipeline material',
    },
  },
  {
    path: 'pipeline-category',
    element: <DictionaryComponent dictionaryData={pipelineCategoryData} />,
    elementWrapperProps: {
      name: 'Pipeline category',
      permission: getCrudPermission('PipingCategory'),
    },
  },
  {
    path: 'pipeline-category/:id',
    element: <OnceRecord {...oncePipelineCategoryProps} />,
    elementWrapperProps: {
      name: 'Pipeline category',
      permission: getCrudPermission('PipingCategory'),
    },
  },
  {
    path: 'inspectors',
    element: <DictionaryComponent dictionaryData={inspectorsData} />,
    elementWrapperProps: {
      name: 'Inspectors',
    },
  },
  {
    path: 'inspectors/:id',
    element: <OnceInspector />,
    elementWrapperProps: {
      name: 'Inspectors',
    },
  },
  {
    path: 'specifications',
    // @ts-ignore
    element: <Specifications />,
    elementWrapperProps: {
      name: 'Specifications',
      permission: ['SpecificationController'],
    },
  },
  {
    path: 'specifications/:id',
    element: <OnceSpecification />,
    elementWrapperProps: {
      name: 'Specifications',
      permission: ['SpecificationController'],
    },
  },
  {
    path: 'permissions',
    element: <Permissions />,
    elementWrapperProps: {
      name: 'Permissions',
      permission: ['superAdmin'],
    },
  },
  {
    path: 'installations/:id',
    element: <OnceRecord {...onceInstallationsProps} />,
    elementWrapperProps: {
      name: 'Installations',
    },
  },
  {
    path: 'installations',
    element: <DictionaryComponent dictionaryData={installationsData} />,
    elementWrapperProps: {
      name: 'Installations',
    },
  },
  {
    path: 'passports',
    element: <Passports />,
    elementWrapperProps: {
      name: 'Passports',
      permission: ['PassportController'],
    },
  },
  {
    path: 'passports/:id',
    element: <OncePassport />,
    elementWrapperProps: {
      name: 'Passports',
      permission: ['PassportController'],
    },
  },
  {
    path: 'welder-on-joint-status',
    element: <WelderOnJointStatus />,
    elementWrapperProps: {
      name: 'Welder joint status',
      permission: ['WelderJoinsStatusController'],
    },
  },
  {
    path: 'welder-on-joint-status/:id',
    element: <OnceRecord {...onceWelderOnJointStatusProps} />,
    elementWrapperProps: {
      name: 'Welder joint status',
      permission: ['WelderJoinsStatusController'],
    },
  },
  {
    path: 'test-packet-statuses',
    element: <DictionaryComponent dictionaryData={testPacketStatusesData} />,
    elementWrapperProps: {
      name: 'Test packet statuses',
      permission: getCrudPermission('TestPacketStatuses'),
    },
  },
  {
    path: 'test-packet-statuses/:id',
    element: <OnceRecord {...onceTestPacketStatusesProps} />,
    elementWrapperProps: {
      name: 'Test packet statuses',
      permission: getCrudPermission('TestPacketStatuses'),
    },
  },
  {
    path: 'test-packet-account-statuses',
    element: <DictionaryComponent dictionaryData={testPacketAccountStatuses} />,
    elementWrapperProps: {
      name: 'Test packet account statuses',
      permission: getCrudPermission('TestPacketAccountStatuses'),
    },
  },
  {
    path: 'test-packet-account-statuses/:id',
    element: <OnceRecord {...onceTestPacketAccountStatusesProps} />,
    elementWrapperProps: {
      name: 'Test packet account statuses',
      permission: getCrudPermission('TestPacketAccountStatuses'),
    },
  },
  {
    path: 'labs-control-types',
    element: <DictionaryComponent dictionaryData={typeOfLaboratories} />,
    elementWrapperProps: {
      name: 'Types of laboratory controls',
      permission: getCrudPermission('LabsControlTypes'),
    },
  },
  {
    path: 'labs-control-types/:id',
    element: <OnceRecord {...onceTypesOfLaboratoriesProps} />,
    elementWrapperProps: {
      name: 'Types of laboratory controls',
      permission: getCrudPermission('LabsControlTypes'),
    },
  },
  {
    path: 'lab-equipments',
    element: <Equipments />,
    elementWrapperProps: {
      name: 'Equipments',
      permission: ['LabEquipmentsController'],
    },
  },
  {
    path: 'lab-equipments/:id',
    element: <OnceEquipments />,
    elementWrapperProps: {
      name: 'Equipments',
      permission: ['LabEquipmentsController'],
    },
  },
  {
    path: 'lab-employees',
    element: <Employees />,
    elementWrapperProps: {
      name: 'Employees',
      permission: ['LabsEmployeesController'],
    },
  },
  {
    path: 'lab-employees/:id',
    element: <OnceEmployees />,
    elementWrapperProps: {
      name: 'Employees',
      permission: ['LabsEmployeesController'],
    },
  },
  {
    path: 'lab-requests',
    element: <LabRequests />,
    elementWrapperProps: {
      name: 'Requests',
      permission: ['LabRequestController'],
    },
  },
  {
    path: 'ndt-lab-requests',
    element: <LabRequests ndt />,
    elementWrapperProps: {
      name: 'Requests',
      permission: ['LabRequestController'],
    },
  },
  {
    path: 'lab-requests/:id',
    element: <OnceLabRequest />,
    elementWrapperProps: {
      name: 'Requests',
      permission: ['LabRequestController'],
    },
  },
  {
    path: 'ndt-lab-requests/:id',
    element: <OnceLabRequest />,
    elementWrapperProps: {
      name: 'Requests',
      permission: ['LabRequestController'],
    },
  },
  {
    path: 'ew-marks',
    element: <DictionaryComponent dictionaryData={ewMarks} />,
    elementWrapperProps: {
      name: 'Marks',
      permission: getCrudPermission('Marks'),
    },
  },
  {
    path: 'ew-marks/:id',
    element: <OnceRecord {...onceEwMarksProps} />,
    elementWrapperProps: {
      name: 'Marks',
      permission: getCrudPermission('Marks'),
    },
  },
  {
    path: 'ew-folder-statuses',
    element: <DictionaryComponent dictionaryData={ewFolderStatuses} />,
    elementWrapperProps: {
      name: 'Folder statuses',
      permission: getCrudPermission('FoldersStatus'),
    },
  },
  {
    path: 'ew-folder-statuses/:id',
    element: <OnceRecord {...onceEwFolderStatusesProps} />,
    elementWrapperProps: {
      name: 'Folder statuses',
      permission: getCrudPermission('FoldersStatus'),
    },
  },
  {
    path: 'folder-location',
    element: <DictionaryComponent dictionaryData={ewFolderLocation} />,
    elementWrapperProps: {
      name: 'Folder location',
      permission: getCrudPermission('FolderLocation'),
    },
  },
  {
    path: 'folder-location/:id',
    element: <OnceRecord {...onceFolderLocationProps} />,
    elementWrapperProps: {
      name: 'Folder location',
      permission: getCrudPermission('FolderLocation'),
    },
  },
  {
    path: 'ew-title-mark',
    element: <TitleMark />,
    elementWrapperProps: {
      name: 'Title-mark',
      permission: ['TitleMarksController'],
    },
  },
  {
    path: 'ew-title-mark/:id',
    element: <OnceTitleMark />,
    elementWrapperProps: {
      name: 'Title-mark',
      permission: ['TitleMarksController'],
    },
  },
  {
    path: 'ewkq',
    element: <DictionaryComponent dictionaryData={ewKQ} />,
    elementWrapperProps: {
      name: 'KQ',
      permission: getCrudPermission('EwKq'),
    },
  },
  {
    path: 'ewkq/:id',
    element: <OnceRecord {...onceKqProps} />,
    elementWrapperProps: {
      name: 'KQ',
      permission: getCrudPermission('EwKq'),
    },
  },
  {
    path: 'ew-base-phase',
    element: <EwBasePhase />,
    elementWrapperProps: {
      name: 'Database',
      permission: ['EwBasePhaseController'],
    },
  },
  {
    path: 'ew-base-phase/:id',
    element: <OnceEwBasePhase />,
    elementWrapperProps: {
      name: 'Ew BasePhase',
      permission: ['EwBasePhaseController'],
    },
  },
  {
    path: 'ew-rfi',
    element: <Rfi />,
    elementWrapperProps: {
      name: 'Inspections',
      permission: ['EwBasePhaseController'],
    },
  },
  {
    path: 'ew-rfi/:id',
    element: <OnceRfi />,
    elementWrapperProps: {
      name: 'RFI',
      permission: ['EwBasePhaseController'],
    },
  },
  {
    path: 'welding-position',
    element: (
      <DictionaryComponent dictionaryData={weldingPositionDescriptions} />
    ),
    elementWrapperProps: {
      name: 'Welding position',
    },
  },
  {
    path: 'welding-position/:id',
    element: <OnceRecord {...onceWeldingPositionProps} />,
    elementWrapperProps: {
      name: 'Welding position',
    },
  },
  {
    path: 'ew-folders/:id',
    element: <OnceFolder />,
    elementWrapperProps: {
      name: 'Folder',
      permission: ['FolderController'],
    },
  },
  {
    path: 'updates',
    element: <Updates />,
    elementWrapperProps: {
      name: 'Updates',
    },
  },
  {
    path: 'updates/:id',
    element: <OnceUpdate />,
    elementWrapperProps: {
      name: 'Updates',
    },
  },
  {
    path: 'lab-types',
    element: (
      <DictionaryComponent dictionaryData={typesLaboratoryDescriptions} />
    ),
    elementWrapperProps: {
      name: 'Types of laboratory',
      permission: getCrudPermission('LabsControlKind'),
    },
  },
  {
    path: 'lab-types/:id',
    element: <OnceRecord {...onceTypesOFLaboratoryProps} />,
    elementWrapperProps: {
      name: 'Types of laboratory',
      permission: getCrudPermission('LabsControlKind'),
    },
  },
  {
    path: 'ew-folders',
    element: <Folders />,
    elementWrapperProps: {
      name: 'Folders',
      permission: ['FolderController'],
    },
  },
  {
    path: 'ew-folders-block-factors-reference',
    element: <DictionaryComponent dictionaryData={blockFactorsDescriptions} />,
    elementWrapperProps: {
      name: 'Block factors',
      permission: getCrudPermission('EwFoldersBlockFactorsReference'),
    },
  },
  {
    path: 'ew-folders-block-factors-reference/:id',
    element: <OnceRecord {...onceBlockFactorsProps} />,
    elementWrapperProps: {
      name: 'Block factor',
      permission: getCrudPermission('EwFoldersBlockFactorsReference'),
    },
  },
  {
    path: 'reports-type-of-works',
    element: <DictionaryComponent dictionaryData={reportTypeOfWorksData} />,
    elementWrapperProps: {
      name: 'Type of works',
      permission: getCrudPermission('ReportsTypeOfWorks'),
    },
  },
  {
    path: 'reports-type-of-works/:id',
    element: <OnceRecord {...onceReportTypeOfWorksProps} />,
    elementWrapperProps: {
      name: 'Types of work',
      permission: getCrudPermission('ReportsTypeOfWorks'),
    },
  },
  {
    path: 'construction-direction',
    element: <DictionaryComponent dictionaryData={constructionDirectionData} />,
    elementWrapperProps: {
      name: 'Construction directions',
      permission: getCrudPermission('ConstructionDirection'),
    },
  },
  {
    path: 'construction-direction/:id',
    element: <OnceRecord {...onceConstructionDirectionProps} />,
    elementWrapperProps: {
      name: 'Construction directions',
      permission: getCrudPermission('ConstructionDirection'),
    },
  },
  {
    path: 'specification-for-passport',
    element: <PassportSpecification />,
    elementWrapperProps: {
      name: 'Specifications for passports',
      permission: ['SpecificationForPassportController'],
    },
  },
  {
    path: 'specification-for-passport/:id',
    element: <OncePassportSpecification />,
    elementWrapperProps: {
      name: 'Specification',
      permission: ['SpecificationForPassportController'],
    },
  },
  {
    path: 'ew-area',
    element: <DictionaryComponent dictionaryData={areaAgppData} />,
    elementWrapperProps: {
      name: 'Areas',
      permission: ['AreaController'],
    },
  },
  {
    path: 'ew-area/:id',
    element: <OnceRecord {...onceAreaAgppProps} />,
    elementWrapperProps: {
      name: 'Area',
      permission: ['AreaController'],
    },
  },
  {
    path: 'ew-summary-information',
    element: <EwStatistic />,
    elementWrapperProps: {
      name: 'Summary information',
      permission: [],
    },
  },
  {
    path: 'ew-summary-information/:id',
    element: <OnceStatistic />,
    elementWrapperProps: {
      name: 'Summary information',
      permission: [],
    },
  },
  {
    path: 'ew-rfi-comment',
    element: <DictionaryComponent dictionaryData={commentRfiStatusData} />,
    elementWrapperProps: {
      name: 'Comments on inspections',
      permission: getCrudPermission('EwRfiComment'),
    },
  },
  {
    path: 'ew-rfi-comment/:id',
    element: <OnceRecord {...onceCommentRfiStatusProps} />,
    elementWrapperProps: {
      name: 'Comment for inspection',
      permission: getCrudPermission('EwRfiComment'),
    },
  },
  {
    path: 'status-of-folder-statuses',
    element: <DictionaryComponent dictionaryData={statusStatusFolderData} />,
    elementWrapperProps: {
      name: 'Folder Status Statuses',
      permission: [
        'getAllStatusOfFolderStatusesData',
        'deleteStatusOfFolderStatuses',
        'createStatusOfFolderStatuses',
        'updateStatusOfFolderStatuses',
      ],
    },
  },
  {
    path: 'status-of-folder-statuses/:id',
    element: <OnceRecord {...onceStatusStatusProps} />,
    elementWrapperProps: {
      name: 'Comment for inspection',
      permission: [
        'getAllStatusOfFolderStatusesData',
        'deleteStatusOfFolderStatuses',
        'createStatusOfFolderStatuses',
        'updateStatusOfFolderStatuses',
      ],
    },
  },
  {
    path: 'profile',
    element: <Profile />,
    elementWrapperProps: {
      name: 'Profile',
    },
  },
  {
    path: 'regulations',
    element: <RegulatoryDocuments />,
    elementWrapperProps: {
      name: 'Regulatory documents',
    },
  },
];

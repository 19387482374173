import { NavigateFunction, useNavigate } from 'react-router-dom';
import { setActiveKey } from '../../../../store/reducers/WorkplaceTabs';
import { useDispatch } from 'react-redux';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { useAppSelector } from '../../../../store/rootReducer';

export const useChangeTab = (): NavigateFunction => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tabs } = useAppSelector((state) => state.workplaceTabsReducer);
  // path это только строка
  return (path: any, options?: NavigateOptions) => {
    dispatch(setActiveKey(path.split('?')[0]));
    const currentTab = tabs.find((tab) => tab.key === path);
    const pathWithSearch = currentTab
      ? path +
        (currentTab.searchParams
          ? `?${currentTab.searchParams}`
          : '?page=0&size=10')
      : path;

    navigate(pathWithSearch, options);
  };
};

import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import { getInitialColumns, getSearchFields } from '../DictionariesExtra';
import { getPopupList } from '../../../../utils/Popup/PopupExtra';

const idName = 'productReportStatusesId';
const api = 'api/product-report-statuses';
const name = 'title';

export const cvlProductReportStatusesData: DictionaryData = {
  apiMethod: api,
  dataListName: 'productReportStatusesDataList',
  idName: idName,
  pageHeaderTitle: 'Product report statuses',
  tabNameField: name,
  columnsName: 'CvlProductReportTable',
  searchFields: getSearchFields,
  columns: getInitialColumns,
  name: name,
  popupList: getPopupList,
};

export const onceCvlProductReportStatusesProps: OnceRecordProps = {
  idName,
  apiMethod: api,
  dataListName: 'productReportStatusesData',
  name: name,
};

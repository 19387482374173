import React from 'react';
import { EditInspectorState } from '../DictionariesTypes';
import { withTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { checkAndSetValue } from '../../../../utils/Base/helpers/checkAndSetValue';
import { EditFormProps } from '../../../../AppTypes';

/**
 * Редактирование информации об инспекторе QC
 */
class EditInspector extends React.Component<
  EditFormProps<any>,
  EditInspectorState
> {
  /**
   * @param {EditFormProps} props
   */
  constructor(props) {
    super(props);

    const row = props.currentRow;

    this.state = {
      name: row.name ? row.name : null,
      surname: row.surname ? row.surname : null,
      patronymic: row.patronymic ? row.patronymic : null,
      oldValue: {},
    };
  }

  /**
   * Установка старых данных
   */
  componentDidMount() {
    this.setState({ oldValue: this.state });
  }

  /**
   * @return {JSX}
   */
  render() {
    const { t, idName, onFinishEdit, currentRow, isFetching } = this.props;
    const { name, surname, patronymic } = this.state;

    return (
      <Form
        layout="vertical"
        onFinish={(values) => {
          values[idName!] = currentRow[idName!];
          onFinishEdit(
            checkAndSetValue(this.state.oldValue, values, ['surname', 'name'])
          );
        }}
        fields={[
          { name: 'name', value: name },
          { name: 'surname', value: surname },
          { name: 'patronymic', value: patronymic },
        ]}
      >
        <Form.Item
          key="surname"
          label={t('Surname')}
          name="surname"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            onChange={(e) => {
              this.setState({ surname: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          key="name"
          label={t('Name')}
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item key="patronymic" label={t('Patronymic')} name="patronymic">
          <Input
            onChange={(e) => {
              this.setState({ patronymic: e.target.value });
            }}
          />
        </Form.Item>

        <Form.Item key="button">
          <Button type="primary" htmlType="submit" disabled={isFetching}>
            {t('Save')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(EditInspector);

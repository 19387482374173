import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_LAB_TYPES = 'SET_DATA_LAB_TYPES';
const SET_CONTENT_LAB_TYPES = 'SET_CONTENT_LAB_TYPES';

export declare type DataLabTypes = {
  success: boolean;
  DataList: ResponseData<LabTypes>;
};

export declare type LabTypes = {
  labControlKindId: number;
  title: string;
  description: string;
};

export declare type LabTypesReducer = {
  data: DataLabTypes | null;
  content: Array<LabTypes> | null;
};

/**
 * @param {DataLabTypes} data
 * @return {any}
 */
export function setDataLabTypes(data) {
  return {
    type: SET_DATA_LAB_TYPES,
    payload: data,
  };
}

/**
 * @param {Array<DataLabTypes>} data
 * @return {any}
 */
export function setContentLabTypes(data) {
  return {
    type: SET_CONTENT_LAB_TYPES,
    payload: data,
  };
}

const initialState: LabTypesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function labTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_LAB_TYPES:
      return { ...state, data: action.payload };
    case SET_CONTENT_LAB_TYPES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { Passports } from '../../components/Workplace/AGPP/Passports/PassportsType';

const SET_DATA_PASSPORTS = 'SET_DATA_PASSPORTS';
const SET_CONTENT_PASSPORTS = 'SET_CONTENT_PASSPORTS';

export declare type dataPassports = {
  success: boolean;
  isoListDataList: ResponseData<Passports>;
};

export declare type PassportsReducer = {
  data: dataPassports | null;
  content: Array<Passports> | null;
};

/**
 * @param {dataPassports} data
 * @return {any}
 */
export function setDataPassports(data) {
  return {
    type: SET_DATA_PASSPORTS,
    payload: data,
  };
}

/**
 * @param {Array<Passports>} data
 * @return {any}
 */
export function setContentPassports(data) {
  return {
    type: SET_CONTENT_PASSPORTS,
    payload: data,
  };
}

const initialState: PassportsReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function passportsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_PASSPORTS:
      return { ...state, data: action.payload };
    case SET_CONTENT_PASSPORTS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

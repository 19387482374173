import React from 'react';
import { Dictionary, NewFormProps } from '../../../../AppTypes';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { REQUIRED_RULES } from '../../../../utils/Base/constants/requiredRule';
import { useDictionarySelectOptions } from '../../../../utils/Base/hooks/useDictionarySelectOptions';
import { disciplineOfWorkData } from '../Extra/DisciplineOfWorkData';

const DisciplineOfWorkForm = React.memo(
  ({
    onFinishAdd,
    onFinishEdit,
    type,
    isFetching,
    currentRow,
  }: NewFormProps<Dictionary & { workingCategories: Dictionary }>) => {
    const { t } = useTranslation();
    const selectProps = useDictionarySelectOptions(disciplineOfWorkData);

    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd?.(values)
        : onFinishEdit?.({ ...values, id: currentRow?.id });
    };

    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={
          currentRow
            ? {
                ...currentRow,
                workingCategoryId: currentRow.workingCategories?.id,
              }
            : undefined
        }
      >
        <Form.Item name="title" label={t('Title_1')} rules={REQUIRED_RULES}>
          <Input />
        </Form.Item>
        <Form.Item name="workingCategoryId" label={t('Types of works')}>
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item name="description" label={t('Description')}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} loading={isFetching}>
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
DisciplineOfWorkForm.displayName = 'DisciplineOfWorkForm';
export default DisciplineOfWorkForm;

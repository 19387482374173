import React, { Component } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import { withTranslation } from 'react-i18next';

/**
 * Форма для работы со статусом сварщика на стыке
 */
class FormWelderOnJointStatus extends Component<any, any> {
  /**
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isSystem: false,
      name: null,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { type, currentRow } = this.props;
    if (type === 'add') return;
    this.setState({
      isSystem: currentRow.isSystem,
      name: currentRow.name,
    });
  }

  /**
   * @return {Function}
   * @param {any} values
   */
  onFinishForm(values) {
    const { type, onFinishAdd, onFinishEdit, currentRow } = this.props;
    const { isSystem } = this.state;
    if (type === 'add') {
      values.isSystem = isSystem;
      return onFinishAdd({ welderJointStatusCreationDataList: [values] });
    } else {
      values.isSystem = isSystem;
      values.welderJointStatusId = currentRow.welderJointStatusId;
      return onFinishEdit(values);
    }
  }
  /**
   * @return {JSX}
   */
  render() {
    const { isFetching, type, t } = this.props;
    const { isSystem, name } = this.state;
    const fields = [
      {
        name: 'name',
        value: name ? name : null,
      },
      {
        name: 'isSystem',
        checked: isSystem,
      },
    ];
    return (
      <Form
        layout="vertical"
        fields={fields}
        onFinish={(values) => this.onFinishForm(values)}
      >
        <Form.Item label="Имя" name={'name'} key={'name'}>
          <Input onChange={(e) => this.setState({ name: e.target.value })} />
        </Form.Item>
        <Form.Item label="В системе" name="isSystem" key="isSystem">
          <Switch
            checked={isSystem}
            onChange={(e) => this.setState({ isSystem: e })}
          />
        </Form.Item>
        <Form.Item key="button">
          <Button htmlType="submit" type="primary" disabled={isFetching}>
            {type === 'edit' ? t('Редактировать') : t('Создать')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(FormWelderOnJointStatus);

import { ResponseData } from '../../utils/API/APITypes';
import { Responsible } from '../../components/Workplace/AGCC/RFI/RFITypes';

const SET_DATA_RESPONSIBLE_RFI = 'SET_DATA_RESPONSIBLE_RFI';
const SET_CONTENT_RESPONSIBLE_RFI = 'SET_CONTENT_RESPONSIBLE_RFI';

export declare type DataResponsibleRFI = {
  success: boolean;
  rfiResponsibleList: ResponseData<Responsible>;
};

export declare type ResponsibleRFIReducer = {
  data: DataResponsibleRFI | null;
  content: Array<Responsible> | null;
};

/**
 * @param {DataResponsibleRFI} data
 * @return {any}
 */
export function setDataResponsibleRFI(data) {
  return {
    type: SET_DATA_RESPONSIBLE_RFI,
    payload: data,
  };
}

/**
 * @param {Array<Responsible>} data
 * @return {any}
 */
export function setContentResponsibleRFI(data) {
  return {
    type: SET_CONTENT_RESPONSIBLE_RFI,
    payload: data,
  };
}

const initialState: ResponsibleRFIReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function responsibleRFIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_RESPONSIBLE_RFI:
      return { ...state, data: action.payload };
    case SET_CONTENT_RESPONSIBLE_RFI:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

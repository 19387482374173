import { commonApi } from '../commonApi';
import { notification } from 'antd';

const onQueryStarted = async ({ path, body }, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error: any) {
    if ('error' in error) {
      notification.error({
        message: error.error.data?.message ?? 'Error creating record',
      });
      throw Error(error.error);
    }
  }
};

export const abstractApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAbstractData: builder.query({
      query: (path: string) => `${path}`,
      providesTags: (result, error, arg) => {
        const key = arg.split('?')[0];
        return [{ type: 'AbstractData', id: key }];
      },
    }),
    updateAbstractData: builder.mutation<
      { [k: string]: Record<string, any> },
      { path: string; body: any; id: string }
    >({
      query: ({ path, body }) => ({
        url: `${path}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : arg.path.includes('page')
          ? [
              {
                type: 'AbstractData',
                id: `${arg.path.split('?')[0]}/${arg.id}`,
              },
              { type: 'AbstractData', id: arg.path.split('?')[0] },
            ]
          : [
              { type: 'AbstractData', id: arg.path + `/${arg.id}` },
              { type: 'AbstractData', id: arg.path },
            ];
      },
      onQueryStarted,
    }),
    deleteAbstractData: builder.mutation<
      any,
      { path: string; listKey?: string }
    >({
      query: ({ path }) => ({
        url: `${path}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => {
        return error
          ? []
          : arg.path.includes('page')
          ? [
              {
                type: 'AbstractData',
                id: arg.listKey?.split('?')[0],
              },
              { type: 'AbstractData', id: arg.path.split('?')[0] },
            ]
          : [
              { type: 'AbstractData', id: arg.path },
              { type: 'AbstractData', id: arg.listKey ?? arg.path },
            ];
      },
    }),
    createAbstractData: builder.mutation<any, { path: string; body: any }>({
      query: ({ path, body }) => ({
        url: `${path}`,
        method: 'POST',
        body,
      }),
      onQueryStarted,
      invalidatesTags: (result, error, arg) => {
        const key = arg.path.split('?')[0];
        return error ? [] : [{ type: 'AbstractData', id: key }];
      },
    }),
  }),
});

export const {
  useGetAbstractDataQuery,
  useUpdateAbstractDataMutation,
  useCreateAbstractDataMutation,
  useDeleteAbstractDataMutation,
} = abstractApi;

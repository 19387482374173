import { ResponseData } from '../../utils/API/APITypes';
import { ConsumableType } from '../../components/Workplace/AGPP/Consumables/ConsumablesData';

const SET_DATA_CONSUMABLES = 'SET_DATA_CONSUMABLES';
const SET_CONTENT_CONSUMABLES = 'SET_CONTENT_CONSUMABLES';

export declare type dataConsumables = {
  success: boolean;
  consumableDataList: ResponseData<ConsumableType>;
};

export declare type ConsumablesReducer = {
  data: dataConsumables | null;
  content: Array<ConsumableType> | null;
};

/**
 * @param {dataConsumables} data
 * @return {any}
 */
export function setDataConsumables(data) {
  return {
    type: SET_DATA_CONSUMABLES,
    payload: data,
  };
}

/**
 * @param {Array<ConsumableType>} data
 * @return {any}
 */
export function setContentConsumables(data) {
  return {
    type: SET_CONTENT_CONSUMABLES,
    payload: data,
  };
}

const initialState: ConsumablesReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function consumablesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_CONSUMABLES:
      return { ...state, data: action.payload };
    case SET_CONTENT_CONSUMABLES:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

import { ResponseData } from '../../utils/API/APITypes';
import { LineListType } from '../../components/Workplace/AGPP/LineList/LineListData';

const SET_DATA_LINELIST = 'SET_DATA_LINELIST';
const SET_CONTENT_LINELIST = 'SET_CONTENT_LINELIST';
const SET_CONTROL_PERCENTAGE = 'SET_CONTROL_PERCENTAGE';

export declare type dataLineList = {
  success: boolean;
  lineListDataList: ResponseData<LineListType>;
};

export declare type LineListReducer = {
  data: dataLineList | null;
  content: Array<LineListType> | null;
  controlPercentage: number[];
};

/**
 * @param {dataLineList}data
 * @return {any}
 */
export function setDataLinelist(data) {
  return {
    type: SET_DATA_LINELIST,
    payload: data,
  };
}

/**
 * @param {dataLineList}data
 * @return {any}
 */
export function setControlPercentage(data) {
  return {
    type: SET_CONTROL_PERCENTAGE,
    payload: data,
  };
}

/**
 * @param {Array<setContentLineList>} data
 * @return {any}
 */
export function setContentLineList(data) {
  return {
    type: SET_CONTENT_LINELIST,
    payload: data,
  };
}

const initialState: LineListReducer = {
  data: null,
  content: null,
  controlPercentage: [],
};

/**
 * @param {state}state
 * @param {any}action
 * @return {State}
 */
export function linelistReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_LINELIST:
      return { ...state, data: action.payload };
    case SET_CONTENT_LINELIST:
      return { ...state, content: action.payload };
    case SET_CONTROL_PERCENTAGE:
      return { ...state, controlPercentage: action.payload };
    default:
      return state;
  }
}

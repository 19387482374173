import { useChangeTab } from '../../../../components/Layouts/components/helpers/useChangeTab';
import {
  Params,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { routerProps } from '../../../../AppTypes';

export const useRouterProps = (): routerProps => {
  const changeTab = useChangeTab();
  const location = useLocation();
  const params: Params = useParams();
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  return {
    navigate: changeTab,
    location,
    params,
    setSearchParams: (params) => setSearchParams(params, { replace: true }),
  };
};

import {
  EwStatistic,
  EwSummary,
} from '../../components/Workplace/AGPP/Electrican/Statistic/types';

const SET_DATA_EW_STATISTIC = 'SET_DATA_EW_STATISTIC';
const SET_INVALIDATE_STATISTIC = 'SET_INVALIDATE_STATISTIC';
const SET_INVALIDATE_SUMMARY_EW_STATISTIC =
  'SET_INVALIDATE_SUMMARY_EW_STATISTIC';
const SET_SUMMARY_EW_STATISTIC = 'SET_SUMMARY_EW_STATISTIC';
const SET_SELECTED_INSPECTION = 'SET_SELECTED_INSPECTION';
const SET_SELECTED_FOLDERS = 'SET_SELECTED_FOLDERS';

export declare type EwStatisticReducer = {
  data: EwStatistic;
  invalidate: boolean;
  summary: EwSummary[];
  invalidateSummary: boolean;
  selectedInspectionList: string[];
  selectedFolderList: string[];
};

/**
 * @param {EwStatistic} data
 * @return {any}
 */
export function setDataEwStatistic(data) {
  return {
    type: SET_DATA_EW_STATISTIC,
    payload: data,
  };
}

/**
 * @param {boolean} data
 * @return {any}
 */
export function setInvalidateEwStatisctic(data) {
  return {
    type: SET_INVALIDATE_STATISTIC,
    payload: data,
  };
}

/**
 * @param {any} data
 * @return {any}
 */
export function setSummaryEwStatistic(data: any): any {
  return {
    type: SET_SUMMARY_EW_STATISTIC,
    payload: data,
  };
}

/**
 * @param {string} status
 * @param {string} type
 * @return {any}
 */
export function setSelectedInspection(
  status: string,
  type: 'add' | 'remove' | 'clear'
): any {
  return {
    type: SET_SELECTED_INSPECTION,
    payload: [status, type],
  };
}

/**
 * @param {string} status
 * @param {string} type
 * @return {any}
 */
export function setSelectedFolders(
  status: string,
  type: 'add' | 'remove' | 'clear'
): any {
  return {
    type: SET_SELECTED_FOLDERS,
    payload: [status, type],
  };
}

/**
 * @param {any} data
 * @return {any}
 */
export function setSummaryInvalidate(data) {
  return {
    type: SET_INVALIDATE_SUMMARY_EW_STATISTIC,
    payload: data,
  };
}

const initialState: EwStatisticReducer = {
  data: {
    summaryAreaKqStatistics: [],
    summaryAreaRfiStatisticList: [],
    summaryFolderList: [],
  },
  invalidate: false,
  summary: [],
  invalidateSummary: false,
  selectedInspectionList: [],
  selectedFolderList: [],
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function ewStatisticReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_EW_STATISTIC:
      return { ...state, data: action.payload };
    case SET_INVALIDATE_STATISTIC:
      return { ...state, invalidate: action.payload };
    case SET_SUMMARY_EW_STATISTIC:
      return { ...state, summary: action.payload };
    case SET_INVALIDATE_SUMMARY_EW_STATISTIC:
      return { ...state, invalidateSummary: action.payload };
    case SET_SELECTED_INSPECTION:
      const [status, type] = action.payload;
      if (type === 'clear') {
        return { ...state, selectedInspectionList: [] };
      }
      const result =
        type === 'remove'
          ? state.selectedInspectionList.filter((el) => el !== status)
          : [...state.selectedInspectionList, status];
      return { ...state, selectedInspectionList: result };
    case SET_SELECTED_FOLDERS:
      const [statusFolder, typeFolder] = action.payload;
      if (typeFolder === 'clear') {
        return { ...state, selectedFolderList: [] };
      }
      const resultFolder =
        typeFolder === 'remove'
          ? state.selectedFolderList.filter((el) => el !== statusFolder)
          : [...state.selectedFolderList, statusFolder];
      return { ...state, selectedFolderList: resultFolder };
    default:
      return state;
  }
}

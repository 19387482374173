import { DataWelderOnJointStatus } from '../../components/Admin/Dictionaries/WelderOnJointStatusTypes';

const SET_DATA_WELDER_ON_JOINT_STATUS = 'SET_DATA_WELDER_ON_JOINT_STATUS';
const SET_CONTENT_WELDER_ON_JOINT_STATUS = 'SET_CONTENT_WELDER_ON_JOINT_STATUS';

export declare type WelderOnJointStatusReducer = {
  data: DataWelderOnJointStatus | null;
  content: Array<DataWelderOnJointStatus> | null;
};

/**
 * @param {dataTemperature} data
 * @return {any}
 */
export function setDataWelderOnJointStatus(data) {
  return {
    type: SET_DATA_WELDER_ON_JOINT_STATUS,
    payload: data,
  };
}

/**
 * @param {Array<JointTypesType>} data
 * @return {any}
 */
export function setContentWelderOnJointStatus(data) {
  return {
    type: SET_CONTENT_WELDER_ON_JOINT_STATUS,
    payload: data,
  };
}

const initialState: WelderOnJointStatusReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function welderOnJointStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_WELDER_ON_JOINT_STATUS:
      return { ...state, data: action.payload };
    case SET_CONTENT_WELDER_ON_JOINT_STATUS:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

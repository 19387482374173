import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountType } from '../../components/Admin/Accounts/AccountsData';
import { abstractApi } from '../api/abstract.api';

export type AuthReducerState = {
  loggedIn: boolean;
  profile: AccountType;
  backgroundUrl: string | null;
  avatarUrl: string | null;
  permission: any;
  isFetch: boolean;
  darkTheme: boolean;
};

const initialAuth: AuthReducerState = {
  loggedIn: false,
  // @ts-ignore
  profile: null,
  permission: null,
  backgroundUrl: null,
  avatarUrl: null,
  isFetch: false,
  darkTheme: !!localStorage.getItem('darkTheme'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    updateState: (state, action: PayloadAction<AccountType>) => {
      state.loggedIn = true;
      state.profile = action.payload;
    },
    updateBackground: (state, action: PayloadAction<string>) => {
      state.backgroundUrl = action.payload;
    },
    updateAvatar: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    setPermission: (state, action: PayloadAction<any>) => {
      state.permission = action.payload;
    },
    setDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.darkTheme = action.payload;
    },
    setIsFetchAccount: (state, action: PayloadAction<boolean>) => {
      state.isFetch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      abstractApi.endpoints.getAbstractData.matchFulfilled,
      (state, { meta, payload }) => {
        if (
          meta.arg.originalArgs ===
          `account/key-cloak/${state.profile?.keyCloakId}`
        ) {
          state.profile = payload.accountData;
        }
      }
    );
  },
});

export const {
  updateState,
  updateBackground,
  updateAvatar,
  setPermission,
  setDarkTheme,
  setIsFetchAccount,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

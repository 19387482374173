import { ResponseData } from '../../utils/API/APITypes';

const SET_DATA_FOLDER_LOCATION = 'SET_DATA_FOLDER_LOCATION';
const SET_CONTENT_FOLDER_LOCATION = 'SET_CONTENT_FOLDER_LOCATION';

export declare type DataFolderLocation = {
  success: boolean;
  DataList: ResponseData<FolderLocation>;
};

export declare type FolderLocation = {
  folderLocationId: number;
  title: string;
  description: string;
  processTime: number;
};

export declare type FolderLocationReducer = {
  data: DataFolderLocation | null;
  content: Array<FolderLocation> | null;
};

/**
 * @param {DataFolderLocation} data
 * @return {any}
 */
export function setDataFolderLocation(data) {
  return {
    type: SET_DATA_FOLDER_LOCATION,
    payload: data,
  };
}

/**
 * @param {Array<DataFolderLocation>} data
 * @return {any}
 */
export function setContentFolderLocation(data) {
  return {
    type: SET_CONTENT_FOLDER_LOCATION,
    payload: data,
  };
}

const initialState: FolderLocationReducer = {
  data: null,
  content: null,
};

/**
 * @param {state}state
 * @param {any} action
 * @return {State}
 */
export function folderLocationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_FOLDER_LOCATION:
      return { ...state, data: action.payload };
    case SET_CONTENT_FOLDER_LOCATION:
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

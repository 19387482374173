import { DictionaryData, OnceRecordProps } from '../DictionariesTypes';
import {
  onceWeldingLocationProps,
  weldingLocationsData,
} from './DictionariesData';
import { getPopupListDeleteId } from '../../../../utils/Popup/PopupExtra';

const idName = 'id';
const apiMethod = 'api/joint-welding-location';

export const weldingLocationsAgccData: DictionaryData = {
  ...weldingLocationsData,
  idName,
  apiMethod,
  popupList: getPopupListDeleteId,
};

export const onceWeldingLocationsAgccProps: OnceRecordProps = {
  ...onceWeldingLocationProps,
  idName,
  apiMethod,
};

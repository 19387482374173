import React from "react";
import { Button, Form, Input, Select } from "antd";
import { REQUIRED_RULES } from "../../../../utils/Base/constants/requiredRule";
import { useTranslation } from "react-i18next";
import { NewFormProps } from "../../../../AppTypes";
import { ElementType } from "../ElementTypesData";

const FormElementType = React.memo(
  ({
    onFinishAdd,
    type,
    onFinishEdit,
    isFetching,
    currentRow,
  }: NewFormProps<ElementType>) => {
    const { t } = useTranslation();

    const onFinish = (values) => {
      type === 'add'
        ? onFinishAdd!(values)
        : onFinishEdit!({
            ...values,
            elementTypeId: currentRow?.elementTypeId,
          });
    };

    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={currentRow ? { ...currentRow } : undefined}
      >
        <Form.Item name={'title'} rules={REQUIRED_RULES} label={t('Name_1')}>
          <Input />
        </Form.Item>
        <Form.Item
          name={'specificationTableNumber'}
          rules={REQUIRED_RULES}
          label={t('Specification table number')}
        >
          <Select
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
            ]}
          />
        </Form.Item>
        <Form.Item name={'description'} label={t('Description')}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={'submit'} loading={isFetching} type={'primary'}>
            {type === 'add' ? t('Create') : t('Edit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
);
FormElementType.displayName = 'FormElementType';
export default FormElementType;

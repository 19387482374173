import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { AddCategoryProps } from '../DictionariesTypes';

/**
 * Добавление категории трубопровода
 */
export class AddPipingCategory extends React.Component<AddCategoryProps, any> {
  /**
   * @param {any} values
   */
  onFinish = (values) => {
    if (values.environmentGroupRus === 'А') {
      values.environmentGroupEng = 'A';
    }
    if (values.environmentGroupRus === 'Б') {
      values.environmentGroupEng = 'B';
    }
    if (values.environmentGroupRus === 'В') {
      values.environmentGroupEng = 'C';
    }
    if (values.environmentSubgroupRus === 'а') {
      values.environmentSubgroupEng = 'a';
    }
    if (values.environmentSubgroupRus === 'б') {
      values.environmentSubgroupEng = 'b';
    }
    if (values.environmentSubgroupRus === 'в') {
      values.environmentSubgroupEng = 'c';
    }
    values.title =
      `${values.environmentGroupRus}` +
      `(${values.environmentSubgroupRus})-${values.categoryNumber} / ` +
      `${values.environmentGroupEng}(${values.environmentSubgroupEng})` +
      `-${values.categoryNumber}`;

    this.props.onFinishAdd!(values);
  };

  /**
   * @return {JSX}
   */
  render() {
    const { t, isFetching } = this.props;
    return (
      <Form layout={'vertical'} onFinish={this.onFinish}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Группа среды"
              name="environmentGroupRus"
              key={'environmentGroupRus'}
              rules={[{ required: true, message: '' }]}
            >
              <Select allowClear={true}>
                <Select.Option key={'А123'} value={'А'}>
                  А
                </Select.Option>
                <Select.Option key={'Б123'} value={'Б'}>
                  Б
                </Select.Option>
                <Select.Option key={'В123'} value={'В'}>
                  В
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Подгруппа среды"
              name="environmentSubgroupRus"
              key={'environmentSubgroupRus'}
              rules={[{ required: true, message: '' }]}
            >
              <Select allowClear={true}>
                <Select.Option key={'а123'} value={'а'}>
                  а
                </Select.Option>
                <Select.Option key={'б123'} value={'б'}>
                  б
                </Select.Option>
                <Select.Option key={'в123'} value={'в'}>
                  в
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Категория"
              name="categoryNumber"
              key={'categoryNumber'}
              rules={[{ required: true, message: '' }]}
            >
              <Select allowClear={true}>
                <Select.Option key={'I'} value={'I'}>
                  I
                </Select.Option>
                <Select.Option key={'II'} value={'II'}>
                  II
                </Select.Option>
                <Select.Option key={'III'} value={'III'}>
                  III
                </Select.Option>
                <Select.Option key={'IV'} value={'IV'}>
                  IV
                </Select.Option>
                <Select.Option key={'V'} value={'V'}>
                  V
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Транспортируемое вещество"
          name="transportedSubstanceRus"
          key={'transportedSubstanceRus'}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Транспортируемое вещество(eng)"
          name="transportedSubstanceEng"
          key={'transportedSubstanceEng'}
        >
          <Input />
        </Form.Item>
        <Form.Item key={'button'}>
          <Button type="primary" htmlType="submit" disabled={isFetching}>
            {t('Create')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

withTranslation()(AddPipingCategory);
